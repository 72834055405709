import { faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Accordion, Form, Modal } from 'react-bootstrap';
import { IMasterStationTypeData } from '../../../../../../models/interface';
import { masterStationTypeData } from '../../../../../../models/mockData/energyManagementMockData';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
interface IProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setNewStation: React.Dispatch<React.SetStateAction<any | null>>;
  newStation: any | null;
}

const CreateNewStationModal = ({
  setShowModal,
  showModal,
  setNewStation,
  newStation,
}: IProps) => {
  const [navMenu, setNavMenu] = useState('type');
  const [stationName, setStationName] = useState('');
  const [stationCount, setStationCount] = useState(0);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [manufacturerValue, setManufacturerValue] = useState('');
  const [selectedType, setSelectedType] =
    useState<IMasterStationTypeData | null>(null);
  const [typeSelected, setTypeSelected] = useState(false);
  const [copyData, setCopyData] = useState<IMasterStationTypeData[]>(
    masterStationTypeData
  );

  const { t } = useTranslation();
  const handleClick = (value: string) => {
    setNavMenu(value);
  };

  const handleSelectType = (
    data: IMasterStationTypeData,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    let newArray = masterStationTypeData.map((type) => {
      return type.id === data.id ? { ...type, isSelected: true } : type;
    });
    setCopyData(newArray);
    setTypeSelected(true);
    setSelectedType(data);
  };

  const handleAddValue = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setStationCount(stationCount + 1);
  };
  const handleDecreaseValue = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setStationCount(stationCount - 1);
  };
  const handleCreateStation = () => {
    let station = {
      id: nanoid(),
      name: stationName,
      type: selectedType?.name,
      note: selectedType?.note,
    };

    setNewStation(station);
    setShowModal(false);
  };

  return (
    <Modal
      centered
      size='xl'
      show={showModal}
      onHide={() => setShowModal(false)}>
      <div className='m-4 p-4 h-75 overflow-scroll'>
        <div className='d-flex justify-content-between'>
          <h2 className='mb-10 fw-bold'>
            {t('createNewLocation.stepperBody.stationView.stationModal.name')}
          </h2>
          <FontAwesomeIcon
            onClick={() => setShowModal(false)}
            style={{ cursor: 'pointer' }}
            icon={faClose}
            size='2x'
            className='text-gray-600'
          />
        </div>
        <nav>
          <ul className='d-flex list-unstyled'>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'type'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('type')}>
              {t(
                'createNewLocation.stepperBody.stationView.stationModal.type.title'
              )}
            </li>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'details'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('details')}>
              {t(
                'createNewLocation.stepperBody.stationView.stationModal.details.title'
              )}
            </li>
          </ul>
        </nav>
        {navMenu === 'type' && (
          <div className=''>
            <div className='mb-4 p-6  bg-gray-300'>
              <h3 className='fw-normal mb-6'>
                {t(
                  'createNewLocation.stepperBody.stationView.stationModal.type.selectManufacturer'
                )}
              </h3>
              <Form>
                <div className='bg-light p-4'>
                  <h3 className=' text-gray-700'>
                    {t(
                      'createNewLocation.stepperBody.stationView.stationModal.type.manufacturer'
                    )}
                  </h3>
                  <Form.Select
                    onChange={(e) => setManufacturerValue(e.target.value)}
                    className='w-250px'
                    defaultValue={manufacturerValue}>
                    <option value=''>
                      {t(
                        'createNewLocation.stepperBody.stationView.stationModal.type.selectManufacturerBtn.title'
                      )}
                    </option>
                    <option value='ABB'>
                      {t(
                        'createNewLocation.stepperBody.stationView.stationModal.type.selectManufacturerBtn.option1'
                      )}
                    </option>
                    <option value='Option 2'>
                      {t(
                        'createNewLocation.stepperBody.stationView.stationModal.type.selectManufacturerBtn.option2'
                      )}
                    </option>
                  </Form.Select>
                </div>
              </Form>
            </div>
            {manufacturerValue && (
              <div className='mb-4 p-6  bg-gray-300'>
                <h3 className='fw-normal mb-6'>
                  {t(
                    'createNewLocation.stepperBody.stationView.stationModal.type.selectType'
                  )}
                </h3>
                <div className='bg-light p-4'>
                  <h3 className=' text-gray-700'>
                    {t(
                      'createNewLocation.stepperBody.stationView.stationModal.type.search'
                    )}
                  </h3>

                  <Form>
                    <Form.Control
                      onChange={(e) => setManufacturerValue(e.target.value)}
                      className='w-250px'
                      placeholder={t(
                        'createNewLocation.stepperBody.stationView.stationModal.type.search'
                      )}
                    />
                    {copyData.map((type: IMasterStationTypeData) => (
                      <div
                        key={type.id}
                        className='bg-gray-300 py-2 px-4 d-flex justify-content-between my-2'>
                        <div>
                          <h3>{type.name}</h3>
                          <p>{type.note}</p>
                        </div>
                        {type.isSelected ? (
                          <button
                            onClick={(e) => handleSelectType(type, e)}
                            className='btn btn-primary my-2 '>
                            {t(
                              'createNewLocation.stepperBody.stationView.stationModal.type.selectedBtn'
                            )}
                          </button>
                        ) : (
                          <button
                            onClick={(e) => handleSelectType(type, e)}
                            className='btn btn-outline-primary border border-1 border-primary my-2'>
                            {t(
                              'createNewLocation.stepperBody.stationView.stationModal.type.selectBtn'
                            )}
                          </button>
                        )}
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            )}
            {typeSelected && (
              <div className='d-flex justify-content-end'>
                <button
                  onClick={() => setNavMenu('details')}
                  className='btn btn-primary'>
                  {t(
                    'createNewLocation.stepperBody.stationView.stationModal.continueBtn'
                  )}
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            )}
          </div>
        )}
        {navMenu === 'details' && (
          <div className=''>
            <div className='mb-4 p-6  bg-light'>
              <h3 className='fw-normal mb-6'>
                {t(
                  'createNewLocation.stepperBody.stationView.stationModal.details.title'
                )}
              </h3>
              <p>
                {t(
                  'createNewLocation.stepperBody.stationView.stationModal.details.detailsInfo'
                )}
              </p>
              <div className='my-4'>
                <h3 className=' text-gray-700'>
                  {t(
                    'createNewLocation.stepperBody.stationView.stationModal.details.stationInfo'
                  )}
                </h3>

                <Form>
                  <div className='bg-gray-300 p-4'>
                    <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                      <h4>
                        {t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.stationName'
                        )}
                      </h4>
                      <Form.Control
                        onChange={(e) => setStationName(e.target.value)}
                        className='w-250px'
                        type='text'
                        placeholder={t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.stationName'
                        )}
                      />
                    </div>
                    <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                      <h4>
                        {t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.ocpp'
                        )}
                      </h4>
                      <Form.Control
                        className='w-250px'
                        type='text'
                        placeholder={t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.ocpp'
                        )}
                      />
                    </div>
                    <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                      <h4>
                        {t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.manufacturer'
                        )}
                      </h4>
                      <h4>{manufacturerValue}</h4>
                    </div>
                    <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                      <h4>
                        {t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.type'
                        )}
                      </h4>
                      <h4>{selectedType?.name}</h4>
                    </div>
                    <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                      <h4>
                        {t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.description'
                        )}
                      </h4>
                      <h4>{selectedType?.note}</h4>
                    </div>
                    <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                      <h4>
                        {t(
                          'createNewLocation.stepperBody.stationView.stationModal.details.form.parkingSlots'
                        )}
                      </h4>
                      <div className='d-flex'>
                        {stationCount > 0 && (
                          <button
                            onClick={(e) => handleDecreaseValue(e)}
                            className='btn btn-secondary btn-sm me-2'>
                            -
                          </button>
                        )}
                        <Form.Control
                          readOnly
                          type='text'
                          value={stationCount}
                          className='w-100px'
                        />
                        <button
                          onClick={(e) => handleAddValue(e)}
                          className='btn btn-secondary btn-sm ms-2'>
                          +
                        </button>
                      </div>
                    </div>
                    <h3
                      onClick={() => setToggleDetails(!toggleDetails)}
                      style={{ cursor: 'pointer' }}
                      className='text-center text-primary text-decoration-underline my-4'>
                      {t(
                        'createNewLocation.stepperBody.stationView.stationModal.details.moreStationDetail'
                      )}
                    </h3>
                  </div>
                </Form>
              </div>
              {toggleDetails && (
                <Accordion className='bg-gray-300' flush alwaysOpen>
                  <Accordion.Item eventKey='0' className='mb-2'>
                    <Accordion.Header>
                      <div className=' d-flex flex-column '>
                        <h3 className=' text-gray-700'>PLUG</h3>
                        <h2>A</h2>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Výrobný výkon</h4>
                        <h4> 5kW</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Max výkon</h4>
                        <h4> 20kW</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Output type</h4>
                        <h4>AC</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Connection type</h4>
                        <h4>Cable 5m, Curved</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Number of phases</h4>
                        <h4>3</h4>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='1' className='mb-2'>
                    <Accordion.Header>
                      <div className=' d-flex flex-column '>
                        <h3 className=' text-gray-700'>PLUG</h3>
                        <h2>B</h2>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Výrobný výkon</h4>
                        <h4> 5kW</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Max výkon</h4>
                        <h4> 20kW</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Output type</h4>
                        <h4>AC</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Connection type</h4>
                        <h4>Cable 5m, Curved</h4>
                      </div>
                      <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                        <h4>Number of phases</h4>
                        <h4>3</h4>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={() => setNavMenu('type')}
                className='btn btn-outline-primary text-decoration-underline me-2'>
                {t(
                  'createNewLocation.stepperBody.stationView.stationModal.backBtn'
                )}
              </button>
              <button onClick={handleCreateStation} className='btn btn-primary'>
                {t(
                  'createNewLocation.stepperBody.stationView.stationModal.createBtn'
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateNewStationModal;
