import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  IMasterGroupsData,
  IMasterPermissionsData,
} from '../../../../../models/interfaces';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import { useTranslation } from 'react-i18next';

interface IProps {
  initialValues: IMasterGroupsData;
}

const GroupPermissions = ({ initialValues }: IProps) => {
  const [copyData, setCopyData] = useState<IMasterPermissionsData[]>(
    initialValues.permission
  );
  const [searchBy, setSearchBy] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const { t } = useTranslation();

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(
    initialValues.permission.length / contactsPerPage
  );
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filteredData = initialValues.permission.filter((data: any) => {
      return (
        data.name.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.module.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.type.toString().toLowerCase().includes(searchBy.toLowerCase())
      );
    });
    if (searchBy === '') {
      setCopyData(initialValues.permission);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  return (
    <div className='bg-gray-300'>
      <div className='d-flex justify-content-between'>
        <Form>
          <Form.Control
            className='w-350px '
            type='text'
            placeholder={t(
              'contactGroups.groupView.assignedPermissions.search'
            )}
            defaultValue=''
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </Form>
      </div>
      <Table className=' table table-secondary   table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold   px-1 bg-gray-300 border-0 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t(
                'contactGroups.groupView.assignedPermissions.table.headers.header1'
              )}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t(
                'contactGroups.groupView.assignedPermissions.table.headers.header2'
              )}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t(
                'contactGroups.groupView.assignedPermissions.table.headers.header3'
              )}
            </Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: IMasterPermissionsData) => {
              return (
                <Tr
                  key={data.id}
                  style={{ borderBottomStyle: 'solid' }}
                  className=' text-start border-4 border-gray-300'>
                  <Td className='bg-gray-500 fs-9 fw-bolder align-baseline   '>
                    <div
                      className={clsx(
                        data.module === 'Wallet'
                          ? 'bg-success rounded-2 px-4 py-2 d-flex justify-content-center '
                          : 'bg-warning rounded-2 px-4 py-2 d-flex justify-content-center '
                      )}>
                      {data.module}
                    </div>
                  </Td>
                  <Td className='bg-gray-500 w-100 w-md-75 align-baseline '>
                    {data.name}
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>{data.type}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </div>
  );
};

export default GroupPermissions;
