import { useState } from 'react';
import { useEffect } from 'react';
import GroupView from './groupView/GroupView';

import { masterGroupsData } from '../models/mockData/mockData';
import { IMasterGroupsData } from '../models/interfaces';

const ContactsGroups = () => {
  const [searchBy, setSearchBy] = useState('');

  const [copyData, setCopyData] =
    useState<IMasterGroupsData[]>(masterGroupsData);

  useEffect(() => {
    const filteredData = masterGroupsData.filter((data: IMasterGroupsData) => {
      return data.name
        .toString()
        .toLowerCase()
        .includes(searchBy.toLowerCase());
    });
    if (searchBy === '') {
      setCopyData(masterGroupsData);
    } else {
      setCopyData(filteredData);
    }
  }, [searchBy]);

  return (
    <div>
      <GroupView copyData={copyData} setSearchBy={setSearchBy} />
    </div>
  );
};

export default ContactsGroups;
