/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import DashboardWelcomeView from './dashboardView/DashboardWelcomeView';
import ModuleExploreView from './dashboardView/ModuleExploreView';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useListCurrenciesQuery,
  useListLocalesQuery,
} from '../../../setup/redux/apiAuthGenerated';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useValidationFields, Yup } from '../../../util/validation';
import {
  useGetAllLocationsQuery,
  useGetAllChargeStationQuery,
} from '../../../setup/redux/apiChargePointGenerated';

const DashboardPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = useListLocalesQuery();

  // const chargeData = useGetAllChargeStationQuery(1);
  console.log(data);

  //!temporary piece of code for validation -- IGNORE
  //   const validationFields = useValidationFields();

  //   const validationSchema = Yup.object({
  //     name: validationFields.name,
  //   });

  //   const handleSubmit = (values: string) => {
  //     console.log(values, 'submited');
  //   };

  //   <Formik
  //   validationSchema={validationSchema}
  //   initialValues={{ name: '' }}
  //   onSubmit={handleSubmit}>
  //   {({
  //     values,
  //     errors,
  //     touched,
  //     handleChange,
  //     handleBlur,
  //     handleSubmit,
  //   }) => (
  //     <Form onSubmit={handleSubmit}>
  //       <Form.Control
  //         type='text'
  //         /* This name property is used to access the value of the form element via values.nameOfElement */
  //         name='name'
  //         placeholder='Full Name'
  //         /* Set onChange to handleChange */
  //         onChange={handleChange}
  //         /* Set onBlur to handleBlur */
  //         onBlur={handleBlur}
  //         /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
  //         value={values.name}
  //         className={touched.name && errors.name ? 'error' : null}
  //       />
  //       {touched.name && errors.name ? (
  //         <div className='text-danger'>{errors.name}</div>
  //       ) : null}
  //       <button type='submit'>Submit</button>
  //     </Form>
  //   )}
  // </Formik>

  return (
    <>
      <DashboardWelcomeView />
      <ModuleExploreView />
      <button
        onClick={() => navigate('/marketplace')}
        className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 fs-9  text-primary'>
        {t('dashboardWrapper.showAllBtn')}
      </button>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
