import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { modules } from '../../marketplaceModules/models/modules';

const ModuleExploreView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleNavigate = (data: any) => {
    if (data.link === true) {
      navigate('/chargingmanagement/chargepoints');
    }
  };
  return (
    <div className='mt-20'>
      <div className='d-flex flex-column '>
        <h3 className='mb-8'>{t('moduleExploreView.name')}</h3>
        <h2 className='fw-light mb-8'>{t('moduleExploreView.tips')}</h2>{' '}
        <div className='d-flex justify-content-center justify-content-md-start  flex-wrap   '>
          {modules.map((module) => (
            <div
              key={module.id}
              className='w-100 w-md-200px h-200px bg-gray-500 p-4 d-flex justify-content-around  flex-column me-md-8 mb-8'>
              <div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className=''>
                    <h1 className='fs-3'>{module.name}</h1>
                    <p className='text-start fs-8 '>{module.description}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className='d-flex  justify-content-center align-items-center'>
                  <button
                    onClick={() => handleNavigate(module)}
                    className='btn border border-primary text-primary bg-hover-primary text-hover-white text-primary bg-white  fs-9  me-2 py-2 fs-9 '>
                    {module.buttons[0]}
                  </button>
                  <button className='btn btn-primary btn-sm  py-2 fs-8'>
                    {module.buttons[1]}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModuleExploreView;
