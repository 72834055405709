import { Link, useParams } from 'react-router-dom';

import { masterUsersData } from '../../../models/mockData/mockData';
import { useState } from 'react';
import About from './lineTabs/about/About';
import AsignedPermisions from './lineTabs/asignedPermisions/AsignedPermisions';
import AsignedGroups from './lineTabs/asignedGroups/AsignedGroups';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditExistingContact = () => {
  const [navMenu, setNavMenu] = useState('about');
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const filteredData = masterUsersData.filter((data) => data.id === id);

  const initialValues = filteredData[0];

  const handleClick = (value: string) => {
    setNavMenu(value);
  };
  const handleCancelClick = () => {
    // ! temporary solution
    navigate('/managment/contactlist');
  };
  const handleDeleteContact = () => {
    // todo Logic for removing contacts
    navigate('/managment/contactlist');
  };

  return (
    <>
      <div>
        <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
          <li className='breadcrumb-item pe-3'>
            <Link to='/dashboard' className='pe-3'>
              {t('breadcrumbs.management')}
            </Link>
          </li>
          <li className='breadcrumb-item pe-3'>
            <Link to='/managment/contactlist' className='pe-3'>
              {t('breadcrumbs.contactList')}
            </Link>
          </li>
        </ol>
        <h1 className='mb-8'>{initialValues.email}</h1>
        <nav>
          <ul className='d-flex flex-wrap list-unstyled'>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'about'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('about')}>
              {t('contactList.editContactList.lineTabs.about.title')}
            </li>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'permision'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('permision')}>
              {t('contactList.editContactList.lineTabs.permissions.title')}
            </li>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'groups'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('groups')}>
              {t('contactList.editContactList.lineTabs.groups.title')}
            </li>
          </ul>
        </nav>
        {navMenu === 'about' && <About initialValues={initialValues} />}
        {navMenu === 'permision' && (
          <AsignedPermisions initialValues={initialValues} />
        )}
        {navMenu === 'groups' && (
          <AsignedGroups initialValues={initialValues} />
        )}
        <div className='d-flex justify-content-between mt-8'>
          <div>
            <button
              onClick={handleDeleteContact}
              className='btn btn-outline-primary border border-2 border-primary me-4 '>
              {t('contactList.editContactList.deleteBtn')}
            </button>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              onClick={handleCancelClick}
              className='btn text-primary text-decoration-underline me-2'>
              {t('contactList.editContactList.cancelBtn')}
            </button>
            {/** todo  */}
            <button
              onClick={handleCancelClick}
              type='submit'
              className='btn btn-primary'>
              {t('contactList.editContactList.saveBtn')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditExistingContact;
