import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { modules } from './models/modules';
import AllModules from './lineTabs/allModules/AllModules';
import PrivateModules from './lineTabs/privateModules/PrivateModules';
import PublicModules from './lineTabs/publicModules/PublicModules';
import { useTranslation } from 'react-i18next';

const MarketplaceModules = () => {
  const [navMenu, setNavMenu] = useState('about');
  const [searchBy, setSearchBy] = useState('');
  const [copyData, setCopyData] = useState(modules);
  const { t } = useTranslation();

  const handleClick = (value: string) => {
    setNavMenu(value);
  };

  useEffect(() => {
    const filteredData = modules.filter((data: any) => {
      return (
        data.description
          .toString()
          .toLowerCase()
          .includes(searchBy.toLowerCase()) ||
        data.name.toString().toLowerCase().includes(searchBy.toLowerCase())
      );
    });
    if (searchBy === '') {
      setCopyData(modules);
    } else {
      setCopyData(filteredData);
    }
  }, [searchBy]);
  return (
    <>
      <div>
        <div className='d-flex justify-content-between'>
          <div>
            <nav>
              <ul className='d-flex list-unstyled'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3  text-hover-primary mx-2 pb-3',
                    navMenu === 'about'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('about')}>
                  {t('marketplaceModules.all')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'permision'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('permision')}>
                  {t('marketplaceModules.private')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'public'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('public')}>
                  {t('marketplaceModules.public')}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='bg-gray-300 p-4'>
          <div className='d-flex justify-content-between'></div>
          {navMenu === 'about' && (
            <AllModules setSearchBy={setSearchBy} modules={copyData} />
          )}
          {navMenu === 'permision' && (
            <PrivateModules setSearchBy={setSearchBy} modules={copyData} />
          )}
          {navMenu === 'public' && (
            <PublicModules setSearchBy={setSearchBy} modules={copyData} />
          )}
        </div>
      </div>
    </>
  );
};

export default MarketplaceModules;
