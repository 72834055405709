import React from 'react';

const ChargedEnergyView = () => {
  return (
    <div className='bg-gray-500'>
      <div className='d-flex flex-column mb-4'>
        <div className=' p-4 d-flex justify-content-between'>
          <h3 className='text-gray-700'>TOTAL</h3>
          <h3 className='text-gray-700'>DATA</h3>
        </div>
        <div className='bg-light p-4 d-flex justify-content-between'>
          <h3 className='text-gray-700'>Charged energy total</h3>
          <h3 className='text-gray-700'>154987 kWh</h3>
        </div>
      </div>
      <div className='d-flex flex-column mb-4'>
        <div className=' p-4 d-flex justify-content-between'>
          <h3 className='text-gray-700'>
            AURPARK / VIP PARKING / GARAGE #2 / ARTJK6
          </h3>
          <h3 className='text-gray-700'>DATA</h3>
        </div>
        <div className='bg-light p-4 d-flex justify-content-between'>
          <h3 className='text-gray-700'>Charged energy total</h3>
          <h3 className='text-gray-700'>154987 kWh</h3>
        </div>
      </div>
    </div>
  );
};

export default ChargedEnergyView;
