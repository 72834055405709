export const testData = [
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-03-09T23:44:10',
    consumption: null,
    state: 'DISCONNECTED',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-03-05T13:40:18',
    consumption: 0,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara pravá',
    code: 'NFVGD2',
    createdAt: '2022-03-05T13:30:37',
    consumption: 0.03,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-03-05T13:06:54',
    consumption: 0.03,
    state: 'FREE',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-18T15:49:21',
    consumption: 52.76,
    state: 'DISCONNECTED',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-16T21:52:01',
    consumption: null,
    state: 'DISCONNECTED',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-15T08:43:04',
    consumption: null,
    state: 'FREE',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-13T20:00:48',
    consumption: 0.01,
    state: 'DISCONNECTED',
  },
  {
    name: 'Spillenberg pravá',
    code: 'DFUYS2',
    createdAt: '2022-02-11T15:44:54',
    consumption: null,
    state: 'DISCONNECTED',
  },
  {
    name: 'Spillenberg pravá',
    code: 'DFUYS2',
    createdAt: '2022-02-11T14:35:54',
    consumption: null,
    state: 'DISCONNECTED',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-11T14:06:15',
    consumption: null,
    state: 'DISCONNECTED',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-11T13:28:08',
    consumption: 0.09,
    state: 'DISCONNECTED',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-11T11:49:37',
    consumption: 0.79,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-11T11:46:20',
    consumption: 0.14,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-11T11:24:23',
    consumption: 0.3,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-11T10:59:57',
    consumption: 0.11,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-11T10:41:18',
    consumption: 0.41,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara ľavá',
    code: 'NFVGD1',
    createdAt: '2022-02-11T10:40:01',
    consumption: 0.06,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara pravá',
    code: 'NFVGD2',
    createdAt: '2022-02-11T10:38:04',
    consumption: 0,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara pravá',
    code: 'NFVGD2',
    createdAt: '2022-02-11T10:27:55',
    consumption: 0,
    state: 'FREE',
  },
  {
    name: 'Penzión Mara pravá',
    code: 'NFVGD2',
    createdAt: '2022-02-11T10:24:49',
    consumption: 0,
    state: 'FREE',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-10T11:43:09',
    consumption: null,
    state: 'DISCONNECTED',
  },
  {
    name: 'Spillenberg ľavá',
    code: 'DFUYS1',
    createdAt: '2022-02-09T19:38:44',
    consumption: null,
    state: 'DISCONNECTED',
  },
]
