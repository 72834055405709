/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

// import { UserModel } from '../../../../app/modules/auth/models/UserModel';

import { useDispatch } from 'react-redux';
import { removeLocalAuthToken } from '../../../../setup/redux/auth-token';
import { bearerTokenSlice } from '../../../../setup/redux/slice.bearerToken';
import { api, useGetProfileQuery } from '../../../../setup/redux/api';
import i18n from '../../../../setup/i18n/i18n';
import iconSKFlag from '../../../../app/assets/images/ico-sk-flag.png';
import iconENFlag from '../../../../app/assets/images/ico-en-flag.png';
import { useTranslation } from 'react-i18next';

const HeaderUserMenu: FC = () => {
  const { data } = useGetProfileQuery();
  const { t } = useTranslation();
  const languages: [string, string][] = [
    ['sk', iconSKFlag],
    ['en', iconENFlag],
  ];

  // const user: UserModel = useSelector<RootState>(
  //   ({ auth }) => auth.user,
  //   shallowEqual
  // ) as UserModel;
  // const user: UserModel = {
  //   email: 'test@test.test',
  //   first_name: 'test',
  //   id: 1,
  //   last_name: 'test',
  //   username: 'test',
  //   password: undefined,
  // };

  const dispatch = useDispatch();
  const logout = () => {
    // dispatch(auth.actions.logout())
    removeLocalAuthToken();
    // @ts-ignore
    dispatch(bearerTokenSlice.actions.set(null));
    dispatch(api.util.resetApiState());
    debugger;

    // dispatch(auth.actions.logout());
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown  w-150px'
      style={{ background: '#7F8DFF' }}
      data-kt-menu='true'>
      <div className='p-4'>
        {data?.email}
        <button className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-1 bg-hover-primary text-hover-white w-100'>
          {t('userMenu.myProfile')}
        </button>

        <button
          onClick={logout}
          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-1 bg-hover-primary text-hover-white w-100'>
          {t('userMenu.signOut')}
        </button>

        <div className='d-flex justify-content-center'>
          {languages &&
            languages.map(([name, icon], index) => (
              <button
                key={index}
                className='btn btn-transparent py-0 px-1 m-0'
                style={{ marginTop: '-0.25rem !important' }}
                onClick={() => i18n.changeLanguage(name)}>
                <img src={icon} height='20' alt='' />
              </button>
            ))}
        </div>
      </div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
    </div>
  );
};

export { HeaderUserMenu };
