import { useEffect, useState } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import styles from './AsignedGroups.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { masterGroupsData } from '../../../../../models/mockData/mockData';
import {
  IMasterUserData,
  IMasterUsergroupData,
} from '../../../../../models/interfaces';
import { useTranslation } from 'react-i18next';

interface IProps {
  initialValues: IMasterUserData;
}
// todo Logic for adding user to the group
// ! sort if user already have permission to avoid adding the same permission twice
const AsignedGroups = ({ initialValues }: IProps) => {
  const [show, setShow] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [filteredData, setFilteredData] = useState(initialValues.groups);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const filteredData = initialValues.groups.filter((data) => {
      return data.name
        .toString()
        .toLowerCase()
        .includes(searchBy.toLowerCase());
    });
    if (searchBy === '') {
      setFilteredData(initialValues.groups);
    } else {
      setFilteredData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  const handleViewGroup = (data: IMasterUsergroupData) => {
    const activeGroup = masterGroupsData.filter(
      (group) => group.id === data.id
    );
    navigate(`/managment/contactgroups/view/${activeGroup[0].id}`);
  };

  return (
    <div className='bg-gray-300 h-600px p-8'>
      <div className='d-flex flex-wrap justify-content-between'>
        <Form>
          <Form.Control
            onChange={(e) => setSearchBy(e.target.value)}
            className='w-250px mb-4 mb-md-0'
            type='text'
            placeholder='Search'
          />
        </Form>
        <button onClick={() => setShow(!show)} className='btn btn-primary'>
          {t('contactList.editContactList.lineTabs.groups.addBtn')}
        </button>
      </div>
      <div className='mt-8'>
        {filteredData.map((data) => {
          return (
            <div
              key={data.id}
              style={{ background: '#CDDEF0' }}
              className='d-flex justify-content-between p-4  my-2'>
              <div>
                <h3 className='text-gray-700 fs-6 '>
                  {t('contactList.editContactList.lineTabs.groups.userGroups')}
                </h3>
                <h2 className='text-black fs-5 '>{data.name}</h2>
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  variant='outlined-primary'
                  className=' bg-white p-4 '>
                  {t(
                    'contactList.editContactList.lineTabs.groups.dropDown.title'
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className='bg-secondary p-1'>
                  <Dropdown.Item
                    onClick={() => handleViewGroup(data)}
                    className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                    {t(
                      'contactList.editContactList.lineTabs.groups.dropDown.action1'
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                    {t(
                      'contactList.editContactList.lineTabs.groups.dropDown.action2'
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        })}
      </div>
      <Modal
        className={styles.rightSideModal}
        show={show}
        size='xl'
        onHide={() => setShow(false)}>
        <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100 '>
          <h2 className=''>
            {t('contactList.editContactList.lineTabs.groups.groupModal.title')}
          </h2>
          <button
            onClick={() => setShow(false)}
            className='btn btn-outline-primary border border-2 border-primary me-4'>
            {t(
              'contactList.editContactList.lineTabs.groups.groupModal.closeBtn'
            )}
            <FontAwesomeIcon className='ms-2' icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h3 className='text-gray-700 fs-5 mb-3'>
            {t(
              'contactList.editContactList.lineTabs.groups.groupModal.selectedGroups'
            )}
          </h3>
          <div>
            <h4 className='text-center bg-gray-300 p-8 fs-7'>
              {t(
                'contactList.editContactList.lineTabs.groups.groupModal.selectedGroupsText'
              )}
            </h4>
            <div style={{ background: '#FFF4CF' }}>
              <h4 className=' d-flex flex-column p-8 fs-7'>
                {t(
                  'contactList.editContactList.lineTabs.groups.groupModal.tips'
                )}
                <Link
                  className='mt-2 text-decoration-underline fs-9'
                  to='/managment/contactgroups'>
                  {t(
                    'contactList.editContactList.lineTabs.groups.groupModal.linkOut'
                  )}
                  <FontAwesomeIcon className='ms-1' icon={faShareFromSquare} />
                </Link>
              </h4>
            </div>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('contactList.editContactList.lineTabs.groups.groupModal.search')}
          </h3>
          <Form>
            <Form.Control
              type='text'
              placeholder='Search'
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </Form>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t(
              'contactList.editContactList.lineTabs.groups.groupModal.avalaibleGroups'
            )}
          </h3>
          {masterGroupsData.map((group) => {
            return (
              <div
                key={group.id}
                style={{ background: '#CDDEF0' }}
                className='d-flex  justify-content-between mb-2'>
                <div className='mb-4 d-flex  p-4 just'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    defaultChecked={false}
                    id='flexCheckDefault'
                  />
                  <div className='d-flex flex-column ms-4'>
                    <h3 className='text-gray-700 fs-7 '>
                      {t(
                        'contactList.editContactList.lineTabs.groups.groupModal.contactGroup'
                      )}
                    </h3>
                    <h2 className='text-black fs-6'>{group.name}</h2>
                  </div>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className='border-0 '>
          <div>
            {/*todo Save selection */}
            <button
              onClick={() => setShow(false)}
              className='d-flex justify-content-end btn btn-primary '>
              {t(
                'contactList.editContactList.lineTabs.groups.groupModal.saveBtn'
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AsignedGroups;
