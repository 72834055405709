import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

import {
  mockData1,
  mockData2,
  mockData3,
} from '../../../../../../managment/reports/lineTabs/charts/mockDataCharts/chartsMock';

const Overview = () => {
  const state = {
    labels: mockData1.map((data) => data.month),
    datasets: [
      {
        label: 'Consumption Year 2022',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#009EF7',
        borderColor: '#81a4b9',
        borderWidth: 3,
        data: mockData1.map((data) => data.consumption),
      },
      {
        label: 'Consumption Year 2021',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#E4E6EF',
        borderColor: '#a9a9a9da',
        borderWidth: 3,
        data: mockData2.map((data) => data.consumption),
      },
      {
        label: 'Consumption Year 2020',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#F8F5FF',
        borderColor: '#191950',
        borderWidth: 3,
        data: mockData3.map((data) => data.consumption),
      },
    ],
  };
  const options = {
    responsive: true,

    animation: {
      duration: 1500,
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Consumption chart',
      },
    },
  };
  return (
    <div className=''>
      <div className=' '>
        <Line
          data={state}
          options={options}
          className='my-2 p-4 bg-light rounded rounded-3 '
        />
      </div>
      <div className=''>
        <Bar
          data={state}
          options={options}
          className='my-2 p-4 bg-light rounded rounded-3 '
        />
      </div>
    </div>
  );
};

export default Overview;
