import { Form } from 'react-bootstrap';
import { useState, useMemo } from 'react';
import './FinanceView.scss';
import clsx from 'clsx';
import CsvExport from '../../../../components/csv/csvExport/CsvExport';

interface IProps {
  testData: any[];
  setSearchByName: React.Dispatch<React.SetStateAction<string>>;
  setGetYear: React.Dispatch<React.SetStateAction<string>>;
  setSearchByOption: React.Dispatch<React.SetStateAction<string>>;
  setGetMonth: React.Dispatch<React.SetStateAction<string>>;
}

const FinanceView = ({
  testData,
  setSearchByName,
  setGetYear,
  setGetMonth,
  setSearchByOption,
}: IProps) => {
  const [sortConfig, setSortConfig] = useState<any>();

  const months = [
    'Január',
    'Február',
    'Marec',
    'Apríl',
    'Máj',
    'Jún',
    'Júl',
    'August',
    'September',
    'Október',
    'November',
    'December',
  ];

  const headers = [
    { label: 'Odberné miesto (názov)', key: 'name' },
    { label: 'Odberné miesto (kód)', key: 'code' },
    { label: 'Spotrebovaná energia (kWh)', key: 'consumption' },
    { label: 'Začiatok transakcie', key: 'createdAt' },
    { label: 'Status', key: 'state' },
  ];

  //Sorting function
  const sortedData = useMemo(() => {
    let sortedData = [...testData];
    sortedData.sort((a, b) => {
      if (a[sortConfig?.key] < b[sortConfig?.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig?.key] > b[sortConfig?.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [testData, sortConfig]);

  //toggle function for sorting
  const requestSort = (key: any) => {
    let direction = 'ascending';
    if (sortConfig?.key === key && sortConfig?.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  //toggle function for classname
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  //get actual month/year
  const currentDate = new Date();
  const currentMonthId = currentDate.getMonth();
  const currentYearId = currentDate.getFullYear();
  const currentMonth = () => {
    return months.filter((month, index) => index === currentMonthId);
  };

  return (
    <div className='table-responsive'>
      <h1 className='mb-8 '>Zobrazené: {testData.length} záznamov </h1>
      <div className='d-flex  align-content-center mb-4 flex-md-row flex-column justify-content-md-between align-items-center  '>
        <Form className='w-300px mb-2 '>
          <Form.Select
            className='mb-1'
            onChange={(e) => setSearchByOption(e.target.value)}>
            {headers.map((option, i) => (
              <option value={option.key} key={i}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            type='text'
            placeholder={`Vyhladaj podla zvoleného parametra`}
            defaultValue=''
            onChange={(e) => setSearchByName(e.target.value)}
          />
        </Form>

        <div className='d-flex mb-2 '>
          <Form>
            <Form.Select onChange={(e) => setGetMonth(e.target.value)}>
              <option defaultValue={currentMonthId + 1}>
                {currentMonth()[0]}
              </option>
              {months.map((month, i) => (
                <option key={i} value={i + 1}>
                  {month}
                </option>
              ))}
            </Form.Select>
          </Form>
          <Form>
            <Form.Select onChange={(e) => setGetYear(e.target.value)}>
              <option defaultValue={currentYearId}>{currentYearId}</option>
              <option value='2021'>2021</option>
              <option value='2022'>2022</option>
            </Form.Select>
          </Form>
        </div>

        <CsvExport
          data={sortedData}
          filename='AgeVolt Finance Export'
          title='Exportuj do CSV'
          className='btn btn-primary mb-4'
        />
      </div>

      <table className=' table table-secondary table-striped table-responsive  table-hover gy-4 gs-7'>
        <thead>
          <tr className='fw-bold  text-gray-800 border px-1 border-gray-200 text-center'>
            {headers.map((header, i) => (
              <td key={i * 50.3}>
                <div className='d-flex flex-column  align-items-center'>
                  <h5>{header.label}</h5>
                  <button
                    className={clsx(
                      'btn h-60px  mb-1',
                      getClassNamesFor(header.key)
                    )}
                    type='button'
                    onClick={() => requestSort(header.key)}>
                    Zotrieď
                  </button>
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((data: any, i: number) => {
            return (
              <tr key={i} className='text-center'>
                <td>{data.name}</td>
                <td>{data.code}</td>
                <td>{data.consumption}</td>
                <td>{new Date(data.createdAt).toLocaleString()}</td>
                <td>{data.state}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FinanceView;
