import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import sk_translations from '../../extractedTranslations/sk/translation.json';
import en_translations from '../../extractedTranslations/en/translation.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en_translations,
  },
  sk: {
    translation: sk_translations,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // contextSeparator: '_',
    // keySeparator: false, // we do not use keys in form messages.welcome

    // saveMissing: true,
    // missingKeyHandler: (...args) => {
    //   console.log(args);
    // },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;
