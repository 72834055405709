export const masterStationTypeData = [
  {
    id: '1',
    name: 'TERA  50 2xDC 1xAC',
    note: '1 x LCD display, 3 Curved cable 3m, ISO 04558',
    isSelected: false,
  },
  {
    id: '2',
    name: 'TERA  50 2xDC 1xAC',
    note: '1 x LCD display, 3 Curved cable 3m, ISO 04558',
    isSelected: false,
  },
  {
    id: '3',
    name: 'TERA  50 2xDC 1xAC',
    note: '1 x LCD display, 3 Curved cable 3m, ISO 04558',
    isSelected: false,
  },
];

export const masterChargePointsData = [
  {
    id: '1',
    name: 'Predajňa Bratislava',
    pId: 'IA4W96',
    address: 'Einsteinova 18, Bratislava',
    tags: [
      {
        id: '1',
        avalaibility: true,
      },
    ],
    sections: [
      {
        id: '1',
        name: 'VIP parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Bratislava',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: true,
          },
        ],
      },
      {
        id: '2',
        name: 'Public Parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Bratislava',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: true,
          },
        ],
      },
      {
        id: '3',
        name: 'Staff Parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Praha',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: false,
          },
        ],
      },
    ],
    stations: [
      {
        id: '1',
        accesibility: 'Public',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: true,
          },
        ],
        partOfSection: [
          {
            id: '1',
            name: 'VIP parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '2',
    name: 'Predajňa Praha',
    pId: 'IA4W96',
    address: 'Einsteinova 18, Bratislava',
    tags: [
      {
        id: '1',
        avalaibility: false,
      },
    ],
    sections: [
      {
        id: '1',
        name: 'VIP parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Bratislava',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: true,
          },
        ],
      },
      {
        id: '2',
        name: 'Public Parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Bratislava',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: true,
          },
        ],
      },
      {
        id: '3',
        name: 'Staff Parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Praha',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: false,
          },
        ],
      },
    ],
    stations: [
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '3',
    name: 'Predajňa Košice',
    pId: 'IA4W96',
    address: 'Vajnorská 10645/100, 831 04 Bratislava',
    tags: [
      {
        id: '1',
        avalaibility: true,
      },
    ],
    sections: [
      {
        id: '1',
        name: 'VIP parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Bratislava',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: true,
          },
        ],
      },

      {
        id: '3',
        name: 'Staff Parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Praha',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: false,
          },
        ],
      },
    ],
    stations: [
      {
        id: '1',
        accesibility: 'Public',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: true,
          },
        ],
        partOfSection: [
          {
            id: '1',
            name: 'VIP parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '4',
    name: 'Predajňa Ostrava',
    pId: 'IA4W96',
    address: 'Dunajská 2321/40811, 08 Bratislava',
    tags: [
      {
        id: '1',
        avalaibility: true,
      },
    ],
    sections: [
      {
        id: '3',
        name: 'Staff Parking',
        pId: 'IA4W96',
        stations: [
          {
            id: '1',
            accesibility: 'Public',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: true,
              },
            ],
            partOfSection: [
              {
                id: '1',
                name: 'VIP parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            accesibility: 'Private',
            name: 'GARAGE #2',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            accesibility: 'Permissioned',
            name: 'GARAGE #3',
            plugs: [
              {
                id: '1',
                avalaibility: false,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '3',
                name: 'Staff Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '2',
                    name: 'Predajňa Praha',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
          {
            id: '4',
            accesibility: 'Permissioned',
            name: 'GARAGE #1',
            plugs: [
              {
                id: '1',
                avalaibility: true,
              },
              {
                id: '2',
                avalaibility: false,
              },
            ],
            partOfSection: [
              {
                id: '2',
                name: 'Public Parking',
                pId: 'IA4W96',
                location: [
                  {
                    id: '1',
                    name: 'Predajňa Bratislava',
                    pId: 'IA4W96',
                    address: 'Einsteinova 18, Bratislava',
                  },
                ],
              },
            ],
          },
        ],
        location: [
          {
            id: '1',
            name: 'Predajňa Praha',
            pId: 'IA4W96',
            address: 'Einsteinova 18, Bratislava',
          },
        ],
        tags: [
          {
            id: '1',
            avalaibility: false,
          },
        ],
      },
    ],
    stations: [
      {
        id: '1',
        accesibility: 'Public',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: true,
          },
        ],
        partOfSection: [
          {
            id: '1',
            name: 'VIP parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const masterSectionsData = [
  {
    id: '1',
    name: 'VIP parking',
    pId: 'IA4W96',
    stations: [
      {
        id: '1',
        accesibility: 'Public',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: true,
          },
        ],
        partOfSection: [
          {
            id: '1',
            name: 'VIP parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
    location: [
      {
        id: '1',
        name: 'Predajňa Bratislava',
        pId: 'IA4W96',
        address: 'Einsteinova 18, Bratislava',
      },
    ],
    tags: [
      {
        id: '1',
        avalaibility: true,
      },
    ],
  },
  {
    id: '2',
    name: 'Public Parking',
    pId: 'IA4W96',
    stations: [
      {
        id: '1',
        accesibility: 'Public',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: true,
          },
        ],
        partOfSection: [
          {
            id: '1',
            name: 'VIP parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
    location: [
      {
        id: '1',
        name: 'Predajňa Bratislava',
        pId: 'IA4W96',
        address: 'Einsteinova 18, Bratislava',
      },
    ],
    tags: [
      {
        id: '1',
        avalaibility: true,
      },
    ],
  },
  {
    id: '3',
    name: 'Staff Parking',
    pId: 'IA4W96',
    stations: [
      {
        id: '1',
        accesibility: 'Public',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: true,
          },
        ],
        partOfSection: [
          {
            id: '1',
            name: 'VIP parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        accesibility: 'Private',
        name: 'GARAGE #2',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        accesibility: 'Permissioned',
        name: 'GARAGE #3',
        plugs: [
          {
            id: '1',
            avalaibility: false,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '3',
            name: 'Staff Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '2',
                name: 'Predajňa Praha',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        accesibility: 'Permissioned',
        name: 'GARAGE #1',
        plugs: [
          {
            id: '1',
            avalaibility: true,
          },
          {
            id: '2',
            avalaibility: false,
          },
        ],
        partOfSection: [
          {
            id: '2',
            name: 'Public Parking',
            pId: 'IA4W96',
            location: [
              {
                id: '1',
                name: 'Predajňa Bratislava',
                pId: 'IA4W96',
                address: 'Einsteinova 18, Bratislava',
              },
            ],
          },
        ],
      },
    ],
    location: [
      {
        id: '1',
        name: 'Predajňa Praha',
        pId: 'IA4W96',
        address: 'Einsteinova 18, Bratislava',
      },
    ],
    tags: [
      {
        id: '1',
        avalaibility: false,
      },
    ],
  },
];

export const masterStationsData = [
  {
    id: '1',
    accesibility: 'Public',
    name: 'GARAGE #1',
    plugs: [
      {
        id: '1',
        avalaibility: true,
      },
      {
        id: '2',
        avalaibility: false,
      },
    ],
    partOfSection: [
      {
        id: '1',
        name: 'VIP parking',
        pId: 'IA4W96',
        location: [
          {
            id: '1',
            name: 'Predajňa Bratislava',
            pId: 'IA4W96',
            address: 'Devínska Nová Ves 7386 841 07, 49 Bratislava',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    accesibility: 'Private',
    name: 'GARAGE #2',
    plugs: [
      {
        id: '1',
        avalaibility: true,
      },
      {
        id: '2',
        avalaibility: false,
      },
    ],
    partOfSection: [
      {
        id: '2',
        name: 'Public Parking',
        pId: 'IA4W96',
        location: [
          {
            id: '2',
            name: 'Predajňa Praha',
            pId: 'IA4W96',
            address: '2, Přívozní 1054, Holešovice, 170 00 Praha, Czechia',
          },
        ],
      },
    ],
  },
  {
    id: '3',
    accesibility: 'Permissioned',
    name: 'GARAGE #3',
    plugs: [
      {
        id: '1',
        avalaibility: false,
      },
      {
        id: '2',
        avalaibility: false,
      },
    ],
    partOfSection: [
      {
        id: '3',
        name: 'Staff Parking',
        pId: 'IA4W96',
        location: [
          {
            id: '2',
            name: 'Predajňa Košice',
            pId: 'IA4W96',
            address: 'Prešovská cesta 1740/4, 040 01 Košice',
          },
        ],
      },
    ],
  },
  {
    id: '4',
    accesibility: 'Permissioned',
    name: 'GARAGE #1',
    plugs: [
      {
        id: '1',
        avalaibility: true,
      },
      {
        id: '2',
        avalaibility: false,
      },
    ],
    partOfSection: [
      {
        id: '2',
        name: 'Public Parking',
        pId: 'IA4W96',
        location: [
          {
            id: '1',
            name: 'Predajňa Ostrava',
            pId: 'IA4W96',
            address: 'Nad Porubkou 2278/31a, 708 00 Ostrava-Poruba, Czechia',
          },
        ],
      },
    ],
  },
];
