import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMasterStationTypeData } from '../../../../../models/interface';
import { masterStationTypeData } from '../../../../../models/mockData/energyManagementMockData';

interface IProps {
  setSelectedType: React.Dispatch<
    React.SetStateAction<IMasterStationTypeData | null>
  >;
  setManufacturerValue: React.Dispatch<React.SetStateAction<string>>;
  manufacturerValue: string;
}

const HardwareView = ({
  setSelectedType,
  setManufacturerValue,
  manufacturerValue,
}: IProps) => {
  const [copyData, setCopyData] = useState<IMasterStationTypeData[]>(
    masterStationTypeData
  );
  const { t } = useTranslation();

  const handleSelectType = (
    data: IMasterStationTypeData,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    let newArray = masterStationTypeData.map((type) => {
      return type.id === data.id ? { ...type, isSelected: true } : type;
    });
    setCopyData(newArray);
    setSelectedType(data);
  };

  return (
    <div className='p-4 w-100  bg-gray-300'>
      <div className='d-flex justify-content-between'>
        <h2 className='mb-10 fw-bold'>
          {t('createNewStation.stepperBody.hardwareView.name')}
        </h2>
      </div>

      <div className=''>
        <div className='mb-4 p-6 '>
          <h3 className='fw-normal mb-6'>
            {t('createNewStation.stepperBody.hardwareView.selectManufacturer')}
          </h3>
          <Form>
            <div className='bg-light p-4'>
              <h3 className=' text-gray-700'>
                {t('createNewStation.stepperBody.hardwareView.manufacturer')}
              </h3>
              <Form.Select
                onChange={(e) => setManufacturerValue(e.target.value)}
                className='w-250px'
                defaultValue={manufacturerValue}>
                <option value=''>
                  {t(
                    'createNewStation.stepperBody.hardwareView.selectManufacturerBtn.title'
                  )}
                </option>
                <option value='ABB'>
                  {t(
                    'createNewStation.stepperBody.hardwareView.selectManufacturerBtn.option1'
                  )}
                </option>
                <option value='Option 2'>
                  {t(
                    'createNewStation.stepperBody.hardwareView.selectManufacturerBtn.option2'
                  )}
                </option>
              </Form.Select>
            </div>
          </Form>
        </div>
        {manufacturerValue && (
          <div className='mb-4 p-6  bg-gray-300'>
            <h3 className='fw-normal mb-6'>
              {t('createNewStation.stepperBody.hardwareView.selectType')}
            </h3>
            <div className='bg-light p-4'>
              <h3 className=' text-gray-700'>
                {' '}
                {t('createNewStation.stepperBody.hardwareView.search')}
              </h3>

              <Form>
                <Form.Control
                  onChange={(e) => setManufacturerValue(e.target.value)}
                  className='w-250px'
                  placeholder='Search'
                />
                {copyData.map((type: IMasterStationTypeData) => (
                  <div
                    key={type.id}
                    className='bg-gray-300 py-2 px-4 d-flex justify-content-between my-2'>
                    <div>
                      <h3>{type.name}</h3>
                      <p>{type.note}</p>
                    </div>
                    {type.isSelected ? (
                      <button
                        onClick={(e) => handleSelectType(type, e)}
                        className='btn btn-primary my-2 '>
                        {t(
                          'createNewStation.stepperBody.hardwareView.selectedBtn'
                        )}
                      </button>
                    ) : (
                      <button
                        onClick={(e) => handleSelectType(type, e)}
                        className='btn btn-outline-primary border border-1 border-primary my-2'>
                        {t(
                          'createNewStation.stepperBody.hardwareView.selectBtn'
                        )}
                      </button>
                    )}
                  </div>
                ))}
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HardwareView;
