const AUTH_TOKEN_KEY = 'AV.BearerToken';

export function loadLocalAuthToken(): string | undefined {
    return window.localStorage.getItem(AUTH_TOKEN_KEY) || undefined;
}

export function saveLocalAuthToken(bearerToken: string) {
    return window.localStorage.setItem(AUTH_TOKEN_KEY, bearerToken);
}

export function removeLocalAuthToken() {
    return window.localStorage.removeItem(AUTH_TOKEN_KEY);
}
