import { useEffect, useState } from 'react';

import FinanceView from './components/FinanceView';
import { testData } from './data/testData';
// import { api } from '../../../../setup/redux/api';

const AllFinances = () => {
  const [searchByName, setSearchByName] = useState('');
  const [getMonth, setGetMonth] = useState('');
  const [getYear, setGetYear] = useState('');
  const [searchByOption, setSearchByOption] = useState('name');
  const [copyData, setCopyData] = useState<any[]>([]);

  // const stationsQuery = api.useFind1Query({});

  useEffect(() => {
    const filteredData = testData.filter((data) => {
      switch (searchByOption) {
        case 'name':
          return data.name.toLowerCase().includes(searchByName.toLowerCase());
        case 'code':
          return data.code.toLowerCase().includes(searchByName.toLowerCase());
        case 'createdAt':
          return data.createdAt
            .toLowerCase()
            .includes(searchByName.toLowerCase());
        case 'consumption':
          return data?.consumption?.toString().includes(searchByName);
        case 'state':
          return data.state.toLowerCase().includes(searchByName.toLowerCase());
        default:
          return data;
      }
    });
    if (searchByName === '') {
      setCopyData(testData);
    } else {
      setCopyData(filteredData);
    }
  }, [searchByName, getMonth, getYear, searchByOption]);

  return (
    <>
      <FinanceView
        testData={copyData}
        setGetYear={setGetYear}
        setSearchByName={setSearchByName}
        setGetMonth={setGetMonth}
        setSearchByOption={setSearchByOption}
      />
    </>
  );
};

export default AllFinances;
