import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './SearchPermissions.module.scss';

import { useEffect } from 'react';
import { masterPermissionsData } from '../../../../../../models/mockData/mockData';
import { useTranslation } from 'react-i18next';

const SearchPermissions = ({ setSearchBy }: any) => {
  const [show, setShow] = useState(false);
  const [rightModalSearch, setRightModalSearch] = useState('');
  const [filteredPermissions, setFilteredPermissions] = useState(
    masterPermissionsData
  );
  const { t } = useTranslation();
  useEffect(() => {
    const filteredData = masterPermissionsData.filter((data: any) => {
      return (
        data.name
          .toString()
          .toLowerCase()
          .includes(rightModalSearch.toLowerCase()) ||
        data.type
          .toString()
          .toLowerCase()
          .includes(rightModalSearch.toLowerCase()) ||
        data.module
          .toString()
          .toLowerCase()
          .includes(rightModalSearch.toLowerCase())
      );
    });
    if (rightModalSearch === '') {
      setFilteredPermissions(masterPermissionsData);
    } else {
      setFilteredPermissions(filteredData);
    }
  }, [rightModalSearch]);

  return (
    <div className={clsx('d-flex flex-wrap justify-content-between m-2  ')}>
      <Form className='w-250px mb-4 mb-md-0'>
        <Form.Control
          type='text'
          placeholder='Search'
          onChange={(e) => setSearchBy(e.target.value)}
        />
      </Form>
      <button
        onClick={() => setShow(!show)}
        className='btn btn-primary justify-self-end'>
        {t('contactList.editContactList.lineTabs.permissions.assignBtn')}
      </button>
      <Modal
        className={styles.rightSideModal}
        show={show}
        size='xl'
        onHide={() => setShow(false)}>
        <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100  '>
          <h2 className=''>
            {t(
              'contactList.editContactList.lineTabs.permissions.permissionModal.title'
            )}
          </h2>
          <button
            onClick={() => setShow(false)}
            className='btn btn-outline-primary border border-2 border-primary me-4'>
            {t(
              'contactList.editContactList.lineTabs.permissions.permissionModal.closeBtn'
            )}{' '}
            <FontAwesomeIcon className='ms-2' icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ background: '#FFF4CF' }}>
            <h4 className=' d-flex flex-column p-8 fs-7'>
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionModal.tips'
              )}
              <Link
                className='mt-2 text-decoration-underline fs-9'
                to='/managment/permissions'>
                {t(
                  'contactList.editContactList.lineTabs.permissions.permissionModal.linkOut'
                )}
                <FontAwesomeIcon className='ms-1' icon={faShareFromSquare} />
              </Link>
            </h4>
          </div>
          <h3 className='text-gray-700 fs-5 mb-3'>
            {t(
              'contactList.editContactList.lineTabs.permissions.permissionModal.selectedPermissions'
            )}
          </h3>
          <div>
            <h4 className='text-center bg-gray-300 p-8 fs-7'>
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionModal.selectedPermissionsText'
              )}
            </h4>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t(
              'contactList.editContactList.lineTabs.permissions.permissionModal.search'
            )}
          </h3>
          <Form>
            <Form.Control
              type='text'
              placeholder='Search'
              onChange={(e) => setRightModalSearch(e.target.value)}
            />
          </Form>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t(
              'contactList.editContactList.lineTabs.permissions.permissionModal.avalaiblePermissions'
            )}
          </h3>
          {filteredPermissions.map((permission) => {
            return (
              <div
                key={permission.id}
                className='d-flex bg-gray-300 justify-content-between mb-2'>
                <div className='mb-4 d-flex  p-4 just'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    defaultChecked={false}
                    id='flexCheckDefault'
                  />
                  <div className='d-flex flex-column ms-4'>
                    <h3 className='text-gray-700 fs-7 '>
                      {t(
                        'contactList.editContactList.lineTabs.permissions.permissionModal.permission'
                      )}
                    </h3>
                    <h2 className='text-black fs-6'>{permission.name}</h2>
                    <h2 className='text-black fw-light fs-7'>
                      {permission.type}
                    </h2>
                  </div>
                </div>
                <div>
                  <h2
                    className={clsx(
                      permission.module === 'Wallet'
                        ? 'bg-success rounded-2 px-4 py-2 d-flex justify-content-center fs-7 me-4 mt-4 '
                        : 'bg-warning rounded-2 px-4 py-2 d-flex justify-content-center fs-7 me-4 mt-4'
                    )}>
                    {permission.module}
                  </h2>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className='border-0 '>
          <div>
            {/*todo Save selection */}
            <button
              onClick={() => setShow(false)}
              className='d-flex justify-content-end btn btn-primary '>
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionModal.saveBtn'
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SearchPermissions;
