import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { masterUsersData } from '../../../../../models/mockData/mockData';
import { useParams } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import { useTranslation } from 'react-i18next';

const ViewMembersPermission = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [searchInMembers, setSearchInMembers] = useState('');

  const { id } = useParams();
  const { t } = useTranslation();
  const result = masterUsersData.filter((obj) => {
    const permission = obj.permission;
    const filteredPermissions = permission.filter((permission) => {
      return permission.id === id;
    });
    return filteredPermissions.length !== 0;
  });
  const [copyData, setCopyData] = useState(result);

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(result.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filteredData = result.filter((data: any) => {
      return data.email
        .toString()
        .toLowerCase()
        .includes(searchInMembers.toLowerCase());
    });

    if (searchInMembers === '') {
      setCopyData(result);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInMembers]);

  return (
    <div>
      <Form>
        <Form.Control
          className='w-250px'
          type='text'
          placeholder='Search'
          defaultValue=''
          onChange={(e) => setSearchInMembers(e.target.value)}
        />
      </Form>
      <Table className=' table table-secondary  table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold  border-0 px-1 bg-gray-300 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t('permission.viewPermission.members.table.headers.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('permission.viewPermission.members.table.headers.header2')}
            </Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: any) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  key={data.id}
                  className=' text-start border-4 border-gray-300'>
                  <Td className='bg-gray-500 border-0  '>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center'>
                        <p> {data.email}</p>
                        <p
                          style={{ background: '#FFA79A' }}
                          className={clsx(
                            data.susspended
                              ? 'ms-2 rounded-3 px-2 fs-10 text-white fw-lighter '
                              : ''
                          )}>
                          {data.susspended ? 'SUSPENDED' : ''}
                        </p>
                      </div>
                      <p
                        className={clsx(
                          data.inherited
                            ? 'text-primary fw-lighter text-decoration-underline fs-9 '
                            : ''
                        )}>
                        {data.inherited ? 'inherited from group' : ''}
                      </p>
                    </div>
                  </Td>
                  <Td className='bg-gray-500'>{data.status}</Td>

                  <Td className='bg-gray-500'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t(
                          'permission.viewPermission.members.table.body.dropDown.title'
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item
                          //todo Logic to display contact
                          // onClick={() => handlePermissionChange(data.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t(
                            'permission.viewPermission.members.table.body.dropDown.action1'
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'
                          //todo Logic to suspend/unsuspend contact
                        >
                          {t(
                            'permission.viewPermission.members.table.body.dropDown.action2'
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </div>
  );
};

export default ViewMembersPermission;
