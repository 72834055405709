export const stationUsageData = [
  {
    id: '1',
    plug: 'A',
    startAt: '2021-04-09 03:38:08',
    endAt: '2021-08-15 14:25:37',
    chargedEnergy: 2.33,
    cost: 19.72,
    fee: 2,
    parkingFee: 1,
    otherFee: 0,
  },
  {
    id: '2',
    plug: 'B',
    startAt: '2021-11-27 07:48:22',
    endAt: '2021-07-08 20:54:12',
    chargedEnergy: 1.98,
    cost: 12.85,
    fee: 0,
    parkingFee: 0,
    otherFee: 2,
  },
  {
    id: '3',
    plug: 'A',
    startAt: '2022-03-09 22:21:49',
    endAt: '2021-04-20 19:24:34',
    chargedEnergy: 1.49,
    cost: 3.66,
    fee: 2,
    parkingFee: 0,
    otherFee: 0,
  },
  {
    id: '4',
    plug: 'A',
    startAt: '2021-05-17 08:28:20',
    endAt: '2021-09-21 19:45:49',
    chargedEnergy: 0.88,
    cost: 11.43,
    fee: 2,
    parkingFee: 0,
    otherFee: 1,
  },
  {
    id: '5',
    plug: 'B',
    startAt: '2021-04-11 01:31:52',
    endAt: '2021-04-28 13:25:19',
    chargedEnergy: 0.46,
    cost: 2.87,
    fee: 1,
    parkingFee: 1,
    otherFee: 2,
  },
  {
    id: '6',
    plug: 'A',
    startAt: '2021-05-16 02:26:48',
    endAt: '2021-04-06 01:40:23',
    chargedEnergy: 9.64,
    cost: 7.29,
    fee: 1,
    parkingFee: 1,
    otherFee: 2,
  },
  {
    id: '7',
    plug: 'A',
    startAt: '2021-06-14 11:13:24',
    endAt: '2021-11-07 07:19:51',
    chargedEnergy: 7.61,
    cost: 9.11,
    fee: 2,
    parkingFee: 2,
    otherFee: 0,
  },
  {
    id: '8',
    plug: 'B',
    startAt: '2021-06-20 13:36:38',
    endAt: '2021-09-06 22:04:39',
    chargedEnergy: 0.85,
    cost: 10.86,
    fee: 0,
    parkingFee: 1,
    otherFee: 0,
  },
  {
    id: '9',
    plug: 'B',
    startAt: '2022-02-28 13:23:09',
    endAt: '2021-11-30 19:20:05',
    chargedEnergy: 1.76,
    cost: 16.65,
    fee: 1,
    parkingFee: 1,
    otherFee: 1,
  },
  {
    id: '10',
    plug: 'B',
    startAt: '2021-06-13 05:46:20',
    endAt: '2022-01-10 13:58:46',
    chargedEnergy: 2.95,
    cost: 14.28,
    fee: 1,
    parkingFee: 1,
    otherFee: 1,
  },
  {
    id: '11',
    plug: 'B',
    startAt: '2021-06-13 05:46:20',
    endAt: '2022-01-10 13:58:46',
    chargedEnergy: 2.95,
    cost: 4.28,
    fee: 1,
    parkingFee: 1,
    otherFee: 1,
  },
];

export const stationLogsData = [
  {
    id: '1',
    plug: 'PLUG A',
    startAt: '2021-04-09 03:38:08',
    name: 'Charging started',
    type: 'TRANSACTION',
  },
  {
    id: '2',
    plug: 'PLUG A',
    startAt: '2021-04-09 07:38:08',
    name: 'Charging ended',
    type: 'TRANSACTION',
  },
  {
    id: '3',
    plug: 'PLUG A',
    startAt: '2021-04-09 03:38:08',
    name: 'Cable connected',
    type: 'TRANSACTION',
  },
  {
    id: '4',
    plug: 'PLUG B',
    startAt: '2021-10-11 09:38:08',
    name: 'Plug reserved',
    type: 'TRANSACTION',
  },
  {
    id: '5',
    plug: 'CHARGER',
    startAt: '2021-10-11 09:38:08',
    name: 'Plug reserved',
    type: 'SYSTEM',
  },
  {
    id: '6',
    plug: 'STATION',
    startAt: '2021-09-09 09:58:18',
    name: 'Plug reserved',
    type: 'EDIT',
  },
];
