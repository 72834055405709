import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

export const chargehBaseUrl = process.env.REACT_APP_CHARGE_API;

export const chargeBaseQuery = fetchBaseQuery({
  baseUrl: chargehBaseUrl,
  prepareHeaders(headers: Headers) {
    headers.set('Accept', 'application/json');

    return headers;
  },
}) as unknown as BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>;

export const chargeApi = createApi({
  baseQuery: chargeBaseQuery,
  reducerPath: 'chargeApi',
  tagTypes: [],
  endpoints: (build) => ({
    getAllLocations: build.query<
      GetAllLocationsApiResponse,
      GetAllLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locations`,
        params: { pageable: queryArg.pageable },
      }),
    }),
    saveLocation: build.mutation<SaveLocationApiResponse, SaveLocationApiArg>({
      query: (queryArg) => ({
        url: `/api/locations`,
        method: 'POST',
        body: queryArg.location,
      }),
    }),
    deleteLocation: build.mutation<
      DeleteLocationApiResponse,
      DeleteLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locations`,
        method: 'DELETE',
        body: queryArg.location,
      }),
    }),
    getAllChargeStation: build.query<
      GetAllChargeStationApiResponse,
      GetAllChargeStationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargeStations`,
        params: { pageable: queryArg.pageable },
      }),
    }),
    saveChargeStation: build.mutation<
      SaveChargeStationApiResponse,
      SaveChargeStationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargeStations`,
        method: 'POST',
        body: queryArg.chargeStation,
      }),
    }),
    deleteChargeStation: build.mutation<
      DeleteChargeStationApiResponse,
      DeleteChargeStationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargeStations`,
        method: 'DELETE',
        body: queryArg.chargeStation,
      }),
    }),
    getLocationById: build.query<
      GetLocationByIdApiResponse,
      GetLocationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/locations/${queryArg.id}` }),
    }),
    getChargeStationById: build.query<
      GetChargeStationByIdApiResponse,
      GetChargeStationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/chargeStations/${queryArg.id}` }),
    }),
  }),
});
export type GetAllLocationsApiResponse = /** status 200 OK */ PageLocation;
export type GetAllLocationsApiArg = {
  pageable: Pageable;
};
export type SaveLocationApiResponse = /** status 200 OK */ Location;
export type SaveLocationApiArg = {
  location: Location;
};
export type DeleteLocationApiResponse =
  /** status 200 OK */
  | '100 CONTINUE'
  | '101 SWITCHING_PROTOCOLS'
  | '102 PROCESSING'
  | '103 CHECKPOINT'
  | '200 OK'
  | '201 CREATED'
  | '202 ACCEPTED'
  | '203 NON_AUTHORITATIVE_INFORMATION'
  | '204 NO_CONTENT'
  | '205 RESET_CONTENT'
  | '206 PARTIAL_CONTENT'
  | '207 MULTI_STATUS'
  | '208 ALREADY_REPORTED'
  | '226 IM_USED'
  | '300 MULTIPLE_CHOICES'
  | '301 MOVED_PERMANENTLY'
  | '302 FOUND'
  | '302 MOVED_TEMPORARILY'
  | '303 SEE_OTHER'
  | '304 NOT_MODIFIED'
  | '305 USE_PROXY'
  | '307 TEMPORARY_REDIRECT'
  | '308 PERMANENT_REDIRECT'
  | '400 BAD_REQUEST'
  | '401 UNAUTHORIZED'
  | '402 PAYMENT_REQUIRED'
  | '403 FORBIDDEN'
  | '404 NOT_FOUND'
  | '405 METHOD_NOT_ALLOWED'
  | '406 NOT_ACCEPTABLE'
  | '407 PROXY_AUTHENTICATION_REQUIRED'
  | '408 REQUEST_TIMEOUT'
  | '409 CONFLICT'
  | '410 GONE'
  | '411 LENGTH_REQUIRED'
  | '412 PRECONDITION_FAILED'
  | '413 PAYLOAD_TOO_LARGE'
  | '413 REQUEST_ENTITY_TOO_LARGE'
  | '414 URI_TOO_LONG'
  | '414 REQUEST_URI_TOO_LONG'
  | '415 UNSUPPORTED_MEDIA_TYPE'
  | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
  | '417 EXPECTATION_FAILED'
  | '418 I_AM_A_TEAPOT'
  | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
  | '420 METHOD_FAILURE'
  | '421 DESTINATION_LOCKED'
  | '422 UNPROCESSABLE_ENTITY'
  | '423 LOCKED'
  | '424 FAILED_DEPENDENCY'
  | '425 TOO_EARLY'
  | '426 UPGRADE_REQUIRED'
  | '428 PRECONDITION_REQUIRED'
  | '429 TOO_MANY_REQUESTS'
  | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
  | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
  | '500 INTERNAL_SERVER_ERROR'
  | '501 NOT_IMPLEMENTED'
  | '502 BAD_GATEWAY'
  | '503 SERVICE_UNAVAILABLE'
  | '504 GATEWAY_TIMEOUT'
  | '505 HTTP_VERSION_NOT_SUPPORTED'
  | '506 VARIANT_ALSO_NEGOTIATES'
  | '507 INSUFFICIENT_STORAGE'
  | '508 LOOP_DETECTED'
  | '509 BANDWIDTH_LIMIT_EXCEEDED'
  | '510 NOT_EXTENDED'
  | '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteLocationApiArg = {
  location: Location;
};
export type GetAllChargeStationApiResponse =
  /** status 200 OK */ PageChargeStation;
export type GetAllChargeStationApiArg = {
  pageable: Pageable;
};
export type SaveChargeStationApiResponse = /** status 200 OK */ ChargeStation;
export type SaveChargeStationApiArg = {
  chargeStation: ChargeStation;
};
export type DeleteChargeStationApiResponse =
  /** status 200 OK */
  | '100 CONTINUE'
  | '101 SWITCHING_PROTOCOLS'
  | '102 PROCESSING'
  | '103 CHECKPOINT'
  | '200 OK'
  | '201 CREATED'
  | '202 ACCEPTED'
  | '203 NON_AUTHORITATIVE_INFORMATION'
  | '204 NO_CONTENT'
  | '205 RESET_CONTENT'
  | '206 PARTIAL_CONTENT'
  | '207 MULTI_STATUS'
  | '208 ALREADY_REPORTED'
  | '226 IM_USED'
  | '300 MULTIPLE_CHOICES'
  | '301 MOVED_PERMANENTLY'
  | '302 FOUND'
  | '302 MOVED_TEMPORARILY'
  | '303 SEE_OTHER'
  | '304 NOT_MODIFIED'
  | '305 USE_PROXY'
  | '307 TEMPORARY_REDIRECT'
  | '308 PERMANENT_REDIRECT'
  | '400 BAD_REQUEST'
  | '401 UNAUTHORIZED'
  | '402 PAYMENT_REQUIRED'
  | '403 FORBIDDEN'
  | '404 NOT_FOUND'
  | '405 METHOD_NOT_ALLOWED'
  | '406 NOT_ACCEPTABLE'
  | '407 PROXY_AUTHENTICATION_REQUIRED'
  | '408 REQUEST_TIMEOUT'
  | '409 CONFLICT'
  | '410 GONE'
  | '411 LENGTH_REQUIRED'
  | '412 PRECONDITION_FAILED'
  | '413 PAYLOAD_TOO_LARGE'
  | '413 REQUEST_ENTITY_TOO_LARGE'
  | '414 URI_TOO_LONG'
  | '414 REQUEST_URI_TOO_LONG'
  | '415 UNSUPPORTED_MEDIA_TYPE'
  | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
  | '417 EXPECTATION_FAILED'
  | '418 I_AM_A_TEAPOT'
  | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
  | '420 METHOD_FAILURE'
  | '421 DESTINATION_LOCKED'
  | '422 UNPROCESSABLE_ENTITY'
  | '423 LOCKED'
  | '424 FAILED_DEPENDENCY'
  | '425 TOO_EARLY'
  | '426 UPGRADE_REQUIRED'
  | '428 PRECONDITION_REQUIRED'
  | '429 TOO_MANY_REQUESTS'
  | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
  | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
  | '500 INTERNAL_SERVER_ERROR'
  | '501 NOT_IMPLEMENTED'
  | '502 BAD_GATEWAY'
  | '503 SERVICE_UNAVAILABLE'
  | '504 GATEWAY_TIMEOUT'
  | '505 HTTP_VERSION_NOT_SUPPORTED'
  | '506 VARIANT_ALSO_NEGOTIATES'
  | '507 INSUFFICIENT_STORAGE'
  | '508 LOOP_DETECTED'
  | '509 BANDWIDTH_LIMIT_EXCEEDED'
  | '510 NOT_EXTENDED'
  | '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteChargeStationApiArg = {
  chargeStation: ChargeStation;
};
export type GetLocationByIdApiResponse = /** status 200 OK */ Location;
export type GetLocationByIdApiArg = {
  id: string;
};
export type GetChargeStationByIdApiResponse =
  /** status 200 OK */ ChargeStation;
export type GetChargeStationByIdApiArg = {
  id: string;
};
export type Section = {
  id?: string;
  created?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  version?: number;
  tempName?: string;
  borderPoints?: string;
};
export type LocalizedName = {
  language?: string;
  name?: string;
};
export type Coordinate = {
  lon?: number;
  lat?: number;
};
export type Location = {
  id?: string;
  created?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  version?: number;
  sections?: Section[];
  locationNames?: LocalizedName[];
  tempName?: string;
  address?: string;
  city?: string;
  zip?: string;
  locationCategory?: string;
  entryPoint?: Coordinate;
};
export type Sort = {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
};
export type PageableObject = {
  offset?: number;
  sort?: Sort;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
};
export type PageLocation = {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Location[];
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type Pageable = {
  page?: number;
  size?: number;
  sort?: string[];
};
export type PriceModel = {
  id?: string;
  created?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  version?: number;
};
export type Connector = {
  id?: string;
  created?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  version?: number;
  priceModel?: PriceModel;
  number?: number;
  stickerCode?: string;
  hubjectCode?: string;
  powerType?: 'AC_1_PHASE' | 'AC_3_PHASE' | 'DC';
  voltage?: number;
  amperage?: number;
  power?: number;
  hubjectEnableType?: 'DISABLED' | 'DATA' | 'USAGE';
  plugTypes?: ('Type2_Plug' | 'Type2_cable' | 'CCS')[];
  internalState?: number;
  state?:
    | 'DISCONNECTED'
    | 'OUT_OF_SERVICE'
    | 'FREE'
    | 'RESERVED'
    | 'CABLE_PLUGGED'
    | 'CONNECTED_RESERVED'
    | 'READY_FOR_CHARGE'
    | 'PREPARE_CHARGING'
    | 'CHARGING'
    | 'FINISHING'
    | 'CHARGING_COMPLETED'
    | 'CHARGING_ERROR';
};
export type Image = {
  id?: string;
  created?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  version?: number;
  content?: string[];
};
export type ChargeStation = {
  id?: string;
  created?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  version?: number;
  connectors?: Connector[];
  images?: Image[];
  serialNumber?: string;
  hardwareManufacturer?: string;
  hotlinePhoneNumber?: string;
  lat?: number;
  lon?: number;
  floor?: number;
  parkingSpot?: string;
  accessibilityType?: string;
  accessibilityLocationType?: string;
  renewableEnergy?: boolean;
};
export type PageChargeStation = {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: ChargeStation[];
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export const {
  useGetAllLocationsQuery,
  useSaveLocationMutation,
  useDeleteLocationMutation,
  useGetAllChargeStationQuery,
  useSaveChargeStationMutation,
  useDeleteChargeStationMutation,
  useGetLocationByIdQuery,
  useGetChargeStationByIdQuery,
} = chargeApi;
