import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './CreateContactGroup.module.scss';
import { masterUsersData } from '../../../../models/mockData/mockData';
import PaginateComponent from '../../../../../../components/pagination/PaginateComponent';
import { useTranslation } from 'react-i18next';

const CreateContactGroup = () => {
  const [show, setShow] = useState(false);
  const [searchInModal, setSearchInModal] = useState('');
  const [modalData, setModalData] = useState(masterUsersData);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedCheck, setSelectedCheck] = useState<any[] | null>([]);
  const [submitedContacts, setSubmitedContacts] = useState<any[] | null>([]);
  const [showImport, setShowImport] = useState(false);
  const [file, setFile] = useState();
  const [contacts, setContacts] = useState([]);

  const { t } = useTranslation();

  const fileReader = new FileReader();

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCountModal = Math.ceil(modalData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const handleImportSubmit = (e: any) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event: any | null) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
      setShowImport(false);
    }
  };
  const csvFileToArray = (string: any) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i: any) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setContacts(array);
  };
  const handleOnChange = (e: any | null) => {
    setFile(e.target.files[0]);
  };

  const handleCheck = (object: any, checked: boolean) => {
    if (checked === true) {
      selectedCheck
        ? setSelectedCheck([...selectedCheck, object])
        : setSelectedCheck([object]);
    } else if (checked === false) {
      selectedCheck
        ? setSelectedCheck(selectedCheck?.filter((obj) => obj.id !== object.id))
        : setSelectedCheck(selectedCheck);
    }
  };

  const handleRemoveItem = (id: string) => {
    selectedCheck
      ? setSelectedCheck(selectedCheck.filter((obj) => obj.id !== id))
      : setSelectedCheck(null);
  };
  const handleSubmitData = (e: any) => {
    setSubmitedContacts(selectedCheck);
    setShow(false);
  };

  useEffect(() => {
    const filteredData = masterUsersData.filter((data: any) => {
      return (
        data.email
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase()) ||
        data.name
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase()) ||
        data.phoneNumber
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase())
      );
    });
    if (searchInModal === '') {
      setModalData(masterUsersData);
    } else {
      setModalData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInModal]);

  return (
    <>
      <div>
        <div className='mb-8'>
          <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
            <li className='breadcrumb-item pe-3'>
              <Link to='/dashboard' className='pe-3'>
                {t('breadcrumbs.management')}
              </Link>
            </li>
            <li className='breadcrumb-item pe-3'>
              <Link to='/managment/contactgroups' className='pe-3'>
                {t('breadcrumbs.contactGroups')}
              </Link>
            </li>
          </ol>
          <h2 className='fw-light'> {t('contactGroups.newGroup.title')}</h2>
        </div>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <div className='bg-gray-300 mb-4 mb-md-0 d-flex flex-column h-100px w-md-25 p-4 justify-content-center'>
            <h2 className='fw-light'>
              {t('contactGroups.newGroup.groupName')}
            </h2>
            <Form>
              <Form.Control
                type='text'
                className='fw-lighter'
                placeholder='Name of new group'
                // onChange={(e) => setNewGroupName(e.target.value)}
              />
            </Form>
          </div>
          <div className='w-100 w-md-75'>
            <div className='bg-gray-300 p-6 pb-10'>
              <div className='d-flex flex-column justify-content-between'>
                <h3> {t('contactGroups.newGroup.addContacts')}</h3>
                <p>{t('contactGroups.newGroup.infoText')}</p>
                <div className='d-flex flex-column flex-md-row'>
                  <button
                    onClick={() => setShow(true)}
                    type='submit'
                    className='btn btn-primary  border border-2 me-4 border-primary mb-4 mb-md-0 w-75 w-md-50'>
                    {t('contactGroups.newGroup.addContacts')}
                  </button>
                  <button
                    onClick={() => setShowImport(true)}
                    className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white text-primary w-75  w-md-50'>
                    {t('contactGroups.newGroup.importFromFile')}
                  </button>
                  {/** todo  */}
                </div>
                <h3 className='text-gray-700 fs-5 my-10'>
                  {t('contactGroups.newGroup.selectedContacts')}
                </h3>
                <div className='bg-white p-6 d-flex flex-column  '>
                  {submitedContacts &&
                    submitedContacts.map((data) => {
                      return (
                        <div
                          key={data.id}
                          className={clsx(
                            data.name ? 'bg-gray-200' : 'bg-gray-500',
                            'my-2  p-4     justify-content-start w-100 '
                          )}>
                          <p className='text-gray-700 fw-bold'>
                            {data.name ? 'USER GROUP' : data.label}
                          </p>
                          <h4 className='fs-6 '>{data.email || data.name}</h4>
                          {data.name && (
                            <p className='text-gray-700 fw-bold'>
                              {data.name.length} users in this group
                            </p>
                          )}
                          {data.phoneNumber && (
                            <p className='text-black fw-light'>
                              {data.phoneNumber}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  {submitedContacts?.length === 0 && contacts?.length === 0 && (
                    <p className='my-auto'>
                      {t('contactGroups.newGroup.selectFromList')}
                    </p>
                  )}
                  {contacts.map((obj) => (
                    <div className='bg-gray-300'>
                      <p className='bg-danger'>Stringify import visual</p>
                      <p>{JSON.stringify(obj)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end mt-8'>
              <div className='d-flex justify-content-end'>
                <button
                  // onClick={() => handleReturnClick()}
                  className='btn text-primary text-decoration-underline me-2'>
                  {t('contactGroups.newGroup.cancelBtn')}
                </button>
                {/** todo  */}
                <button
                  // onClick={() => handleReturnClick()}
                  type='submit'
                  className='btn btn-primary'>
                  {t('contactGroups.newGroup.saveBtn')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={styles.rightSideModal}
        show={show}
        size='xl'
        onHide={() => setShow(false)}>
        <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100 '>
          <h2>{t('contactGroups.newGroup.addContacts')}</h2>
          <button
            onClick={() => setShow(false)}
            className='btn btn-outline-primary border border-2 border-primary me-4 d-flex align-items-center'>
            {t('contactGroups.newGroup.closeBtn')}{' '}
            <FontAwesomeIcon className='ms-2' icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h3 className='text-gray-700 fs-5 mb-3'>
            {t('contactGroups.newGroup.selected')}
          </h3>
          <div className='text-center bg-gray-300 p-8 fs-7 '>
            {selectedCheck &&
              selectedCheck.map((objekt) => (
                <div
                  key={objekt.id}
                  className={clsx(
                    objekt.name ? 'bg-gray-200' : 'bg-gray-500',
                    'my-2  p-4 d-flex justify-content-between '
                  )}>
                  <div className='d-flex flex-column text-start'>
                    <p className='text-gray-700 fw-bold'>
                      {objekt.name ? 'USER GROUP' : objekt.label}
                    </p>
                    <h4 className='fs-6 '>{objekt.email || objekt.name}</h4>
                    {objekt.name && (
                      <p className='text-gray-700 fw-bold'>
                        {objekt.name.length} users in this group
                      </p>
                    )}
                    {objekt.phoneNumber && (
                      <p className='text-black fw-light'>
                        {objekt.phoneNumber}
                      </p>
                    )}
                  </div>
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveItem(objekt.id)}
                    icon={faTrash}
                  />
                </div>
              ))}
            {selectedCheck?.length === 0 && (
              <p>{t('contactGroups.newGroup.selectFromList')}</p>
            )}
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('contactGroups.newGroup.searchCapital')}
          </h3>
          <div>
            <Form>
              <Form.Control
                onChange={(e) => setSearchInModal(e.target.value)}
                type='text'
                placeholder={t('contactGroups.newGroup.search')}
              />
            </Form>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('contactGroups.newGroup.yourContactsAndGroups')}
          </h3>
          <div className=' bg-gray-300 p-8 fs-7'>
            {modalData
              .slice(pagesVisited, pagesVisited + contactsPerPage)
              .map((data) => {
                return (
                  <div
                    key={data.id}
                    className={clsx(
                      data.email ? 'bg-gray-500' : 'bg-gray-200',
                      'my-2  p-4 d-flex justify-content-start '
                    )}>
                    <Form>
                      <Form.Check
                        onChange={(e) => handleCheck(data, e.target.checked)}
                        className='me-2'
                      />
                    </Form>

                    <div>
                      <p className='text-gray-700 fw-bold'>
                        {data.email ? 'CONTACT' : 'USER GROUP'}
                      </p>
                      <h4 className='fs-6 '>{data.email}</h4>

                      {data.phoneNumber && (
                        <p className='text-black fw-light'>
                          {data.phoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            <div className='d-flex justify-content-end'>
              <PaginateComponent
                pageCount={pageCountModal}
                pageChange={pageChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0 '>
          <div>
            {/*todo Save selection */}
            <button
              onClick={handleSubmitData}
              className='d-flex justify-content-end btn btn-primary '>
              {t('contactGroups.newGroup.saveSelection')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showImport} onHide={() => setShowImport(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('contactGroups.newGroup.importCsv')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('contactGroups.newGroup.selectCsv')}
          <input
            className='form-control mt-2'
            accept='.csv'
            type='file'
            id='csvFileInput'
            onChange={handleOnChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn ' onClick={() => setShowImport(false)}>
            {t('contactGroups.newGroup.closeBtn')}
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              handleImportSubmit(e);
            }}>
            {t('contactGroups.newGroup.import')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateContactGroup;
