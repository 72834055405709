import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IMasterStationData } from '../../../../../models/interface';

interface IProps {
  selectedStation: IMasterStationData[];
}

const StationSettings = ({ selectedStation }: IProps) => {
  return (
    <div className='w-100 bg-gray-300'>
      <div className=' p-6  '>
        <div className='my-4'>
          <Form>
            <h3 className=' text-gray-700 px-4'>GENERAL</h3>

            <div className='p-4'>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>Name of station</h4>
                <Form.Control
                  // onChange={(e) => setStationName(e.target.value)}
                  defaultValue='Hotel u Císařské cesty'
                  className='w-250px'
                  type='text'
                  placeholder='Station name'
                />
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>Assigned into section</h4>
                <Form.Select className='w-250px'>
                  <option value=''>VIP parking</option>
                </Form.Select>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>Location</h4>
                {selectedStation[0]?.partOfSection?.map((section) => {
                  return (
                    <Link
                      to={`/chargingmanagement/chargepoints/viewlocation/${section.location[0].id}`}
                      className='text-primary text-decoration-underline'
                      style={{ cursor: 'pointer' }}
                      key={section.id}>
                      {section?.location[0]?.name}
                      <FontAwesomeIcon
                        icon={faShareFromSquare}
                        className='ms-2'
                      />
                    </Link>
                  );
                })}
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>OCCP Code</h4>
                <div className='d-flex'>
                  <Form.Control
                    // onChange={(e) => setStationName(e.target.value)}
                    defaultValue='55D8T4H2WW98Q'
                    className='w-250px'
                    type='text'
                    placeholder='Station name'
                  />
                  <button className='btn btn-outline-primary border border-1 border-primary ms-2'>
                    Check
                  </button>
                </div>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>Manufacturer</h4>
                <Form.Select className='w-250px'>
                  <option value='ABB'>ABB</option>
                </Form.Select>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>Type</h4>
                <Form.Select className='w-250px'>
                  <option value='ABB'>TERA 50 2xDC 1xAC</option>
                </Form.Select>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <div>
                  <h4 className='mb-2'>Title image</h4>
                  <button className='btn btn-outline-primary border border-1 border-primary ms-2'>
                    Remove image
                  </button>
                </div>
                <div>
                  <Form.Control type='file' />
                </div>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  Station GPS(Longtitude, Latitude){' '}
                  <span className='text-success'>optional</span>
                </h4>
                <div className='d-flex'>
                  <Form.Control
                    defaultValue='91.193219'
                    type='text'
                    className='me-2'
                  />
                  <Form.Control defaultValue='91.193219' type='text' />
                </div>
              </div>
            </div>
            <div>
              <h3 className=' text-gray-700 px-4'>PLUG A</h3>
              <div className='m-4 bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>Name of Plug</h4>
                <Form.Control
                  // onChange={(e) => setStationName(e.target.value)}
                  defaultValue={selectedStation[0].name}
                  className='w-250px'
                  type='text'
                  placeholder='Station name'
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default StationSettings;
