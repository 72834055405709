export const masterPermissionsData = [
  {
    module: 'Wallet',
    name: 'Admin finančné',
    status: 'Accepted',
    type: 'Admin',
    id: '6',
    susspended: false,
    inherited: false,
    isSelected: false,
  },
  {
    module: 'Wallet',
    name: 'Do 200€',
    status: 'Accepted',
    type: 'User',
    id: '1',
    susspended: false,
    inherited: true,
    isSelected: true,
  },
  {
    module: 'Wallet',
    name: 'Do 100€',
    status: 'Pending',
    type: 'User',
    id: '2',
    susspended: false,
    inherited: false,
    isSelected: false,
  },
  {
    module: 'Charge points',
    name: 'Predajňa Košice',
    status: 'Declined',
    type: 'User',
    id: '3',
    susspended: true,
    inherited: false,
    isSelected: false,
  },
  {
    module: 'Charge points',
    name: 'Predajňa Praha',
    status: 'Accepted',
    type: 'User',
    id: '4',
    susspended: false,
    inherited: false,
    isSelected: false,
  },
];
export const masterGroupsData = [
  {
    name: 'Admin finančné ',
    permission: [
      {
        module: 'Wallet',
        name: 'Admin finančné',
        status: 'Accepted',
        type: 'Admin',
        id: '6',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    subGroups: [],

    users: [
      {
        email: 'Elsa.Cremin24@hotmail.com',
        phoneNumber: '536-847-8259 x1632',
        note: 'facere ullam ratione',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },

          {
            name: 'Admin finančné',
            id: '6',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Admin finančné',
            status: 'Accepted',
            type: 'Admin',
            id: '6',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '1',
      },
    ],
    id: '6',
  },
  {
    name: 'Predajňa Bratislava',
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'Admin',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    users: [
      {
        email: 'Elsa.Cremin24@hotmail.com',
        phoneNumber: '536-847-8259 x1632',
        note: 'facere ullam ratione',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Admin finančné',
            id: '6',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Admin finančné',
            status: 'Accepted',
            type: 'Admin',
            id: '6',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '1',
      },
      {
        email: 'Reilly48@yahoo.com',
        phoneNumber: '370.691.0591 x061',
        note: 'tempora ipsa voluptas',
        firstName: 'Maci',
        lastName: 'Kling',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '2',
      },
      {
        email: 'Lilliana.King@hotmail.com',
        phoneNumber: '(888) 283-6154 x217',
        note: 'atque qui rerum',
        firstName: 'Elvis',
        lastName: 'Rippin',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '3',
      },
      {
        email: 'Mark.Murazik@gmail.com',
        phoneNumber: '607-900-9684',
        note: 'est cupiditate debitis',
        firstName: 'Clair',
        lastName: 'Monahan',
        groups: [
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '4',
      },
      {
        email: 'Pink36@yahoo.com',
        phoneNumber: '914.673.8856',
        note: 'voluptates voluptas dolorem',
        firstName: 'Johann',
        lastName: 'Langosh',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '5',
      },
      {
        email: 'Fletcher70@yahoo.com',
        phoneNumber: '465.476.3385 x68021',
        note: 'dolorem consequuntur assumenda',
        firstName: 'Johann',
        lastName: 'Adams',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '6',
      },

      {
        email: 'Micah44@hotmail.com',
        phoneNumber: '759-803-8036 x90057',
        note: 'doloribus ex debitis',
        firstName: 'Rossie',
        lastName: 'Bartoletti',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },

          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '19',
      },
      {
        email: 'Clara.Runte@hotmail.com',
        phoneNumber: '(264) 847-0377',
        note: 'earum aperiam id',
        firstName: 'Miller',
        lastName: 'Hammes',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '20',
      },
      {
        id: '21',
        email: 'Isom.Waters@hotmail.com',
        phoneNumber: '1-998-398-4839 x0858',
        note: 'maiores porro ipsa',
        firstName: 'Geovanny',
        lastName: 'Hermann',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
      {
        id: '22',
        email: 'testing@hotmail.com',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        phoneNumber: '1-77859 x0858',
        note: 'maioreds saaa sd',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Pending',
            type: 'Admin',
            id: '1',
            susspended: false,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Do 100€',
            status: 'Declined',
            type: 'User',
            id: '2',
            susspended: true,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
    ],
    subGroups: [
      {
        name: 'Predajňa Košice',
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        subGroups: [],

        users: [
          {
            email: 'Elsa.Cremin24@hotmail.com',
            phoneNumber: '536-847-8259 x1632',
            note: 'facere ullam ratione',
            firstName: 'Gayle',
            lastName: 'Balistreri',
            groups: [
              {
                name: 'Regular clients',
                id: '3',
              },
              {
                name: 'Clients',
                id: '5',
              },
            ],
            permission: [
              {
                module: 'Charge points',
                name: 'Predajňa Praha',
                status: 'Accepted',
                type: 'User',
                id: '4',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
              {
                module: 'Wallet',
                name: 'Do 100€',
                status: 'Accepted',
                type: 'Admin',
                id: '5',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
            ],
            id: '1',
          },
          {
            email: 'Reilly48@yahoo.com',
            phoneNumber: '370.691.0591 x061',
            note: 'tempora ipsa voluptas',
            firstName: 'Maci',
            lastName: 'Kling',
            groups: [
              {
                name: 'Clients',
                id: '5',
              },
            ],
            permission: [
              {
                module: 'Wallet',
                name: 'Do 200€',
                status: 'Accepted',
                type: 'Admin',
                id: '1',
                susspended: true,
                inherited: true,
                isSelected: true,
              },
              {
                module: 'Wallet',
                name: 'Do 100€',
                status: 'Pending',
                type: 'User',
                id: '2',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
              {
                module: 'Charge points',
                name: 'Predajňa Košice',
                status: 'Declined',
                type: 'User',
                id: '3',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
            ],
            id: '2',
          },
          {
            email: 'Lilliana.King@hotmail.com',
            phoneNumber: '(888) 283-6154 x217',
            note: 'atque qui rerum',
            firstName: 'Elvis',
            lastName: 'Rippin',
            groups: [
              {
                name: 'Clients',
                id: '5',
              },
            ],
            permission: [
              {
                module: 'Charge points',
                name: 'Predajňa Praha',
                status: 'Accepted',
                type: 'User',
                id: '4',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
              {
                module: 'Wallet',
                name: 'Do 100€',
                status: 'Accepted',
                type: 'Admin',
                id: '5',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
            ],
            id: '3',
          },
          {
            email: 'Mark.Murazik@gmail.com',
            phoneNumber: '607-900-9684',
            note: 'est cupiditate debitis',
            firstName: 'Clair',
            lastName: 'Monahan',
            groups: [
              {
                name: 'Clients',
                id: '5',
              },
            ],
            permission: [
              {
                module: 'Wallet',
                name: 'Do 100€',
                status: 'Accepted',
                type: 'Admin',
                id: '5',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
            ],
            id: '4',
          },
          {
            email: 'Pink36@yahoo.com',
            phoneNumber: '914.673.8856',
            note: 'voluptates voluptas dolorem',
            firstName: 'Johann',
            lastName: 'Langosh',
            groups: [
              {
                name: 'Regular clients',
                id: '3',
              },
              {
                name: 'Clients',
                id: '5',
              },
            ],
            permission: [
              {
                module: 'Wallet',
                name: 'Do 100€',
                status: 'Accepted',
                type: 'Admin',
                id: '5',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
            ],
            id: '5',
          },
          {
            email: 'Fletcher70@yahoo.com',
            phoneNumber: '465.476.3385 x68021',
            note: 'dolorem consequuntur assumenda',
            firstName: 'Johann',
            lastName: 'Adams',
            groups: [
              {
                name: 'Clients',
                id: '5',
              },
            ],
            permission: [
              {
                module: 'Wallet',
                name: 'Do 200€',
                status: 'Accepted',
                type: 'Admin',
                id: '1',
                susspended: true,
                inherited: true,
                isSelected: true,
              },
              {
                module: 'Wallet',
                name: 'Do 100€',
                status: 'Pending',
                type: 'User',
                id: '2',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
              {
                module: 'Charge points',
                name: 'Predajňa Košice',
                status: 'Declined',
                type: 'User',
                id: '3',
                susspended: false,
                inherited: false,
                isSelected: false,
              },
            ],
            id: '6',
          },
        ],
        id: '3',
      },
    ],
    id: '1',
  },
  {
    name: 'Predajňa Praha',
    permission: [
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'Admin',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    subGroups: [],

    users: [
      {
        email: 'Elsa.Cremin24@hotmail.com',
        phoneNumber: '536-847-8259 x1632',
        note: 'facere ullam ratione',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '1',
      },
      {
        email: 'Reilly48@yahoo.com',
        phoneNumber: '370.691.0591 x061',
        note: 'tempora ipsa voluptas',
        firstName: 'Maci',
        lastName: 'Kling',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '2',
      },
      {
        email: 'Lilliana.King@hotmail.com',
        phoneNumber: '(888) 283-6154 x217',
        note: 'atque qui rerum',
        firstName: 'Elvis',
        lastName: 'Rippin',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '3',
      },

      {
        email: 'Amiya68@gmail.com',
        phoneNumber: '(591) 242-6017 x5687',
        note: 'sunt adipisci qui',
        firstName: "D'angelo",
        lastName: 'Ledner',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [],
        id: '15',
      },
      {
        email: 'Demetrius_Barton@hotmail.com',
        phoneNumber: '(443) 699-3638',
        note: 'aliquam officia non',
        firstName: 'Geovany',
        lastName: 'Graham',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '16',
      },
      {
        email: 'Easter.Jast77@gmail.com',
        phoneNumber: '1-245-617-5461 x64768',
        note: 'veritatis eligendi molestiae',
        firstName: 'Amara',
        lastName: 'Von',
        groups: [
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '17',
      },
      {
        email: 'Korey_Schmitt@yahoo.com',
        phoneNumber: '(291) 645-8009 x393',
        note: 'inventore sed voluptatem',
        firstName: 'Elfrieda',
        lastName: 'Gerhold',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '18',
      },
      {
        email: 'Micah44@hotmail.com',
        phoneNumber: '759-803-8036 x90057',
        note: 'doloribus ex debitis',
        firstName: 'Rossie',
        lastName: 'Bartoletti',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },

          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '19',
      },
      {
        email: 'Clara.Runte@hotmail.com',
        phoneNumber: '(264) 847-0377',
        note: 'earum aperiam id',
        firstName: 'Miller',
        lastName: 'Hammes',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '20',
      },
      {
        id: '21',
        email: 'Isom.Waters@hotmail.com',
        phoneNumber: '1-998-398-4839 x0858',
        note: 'maiores porro ipsa',
        firstName: 'Geovanny',
        lastName: 'Hermann',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
      {
        id: '22',
        email: 'testing@hotmail.com',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        phoneNumber: '1-77859 x0858',
        note: 'maioreds saaa sd',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Pending',
            type: 'Admin',
            id: '1',
            susspended: false,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Do 100€',
            status: 'Declined',
            type: 'User',
            id: '2',
            susspended: true,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
    ],
    id: '2',
  },
  {
    name: 'Predajňa Košice',
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    subGroups: [],

    users: [
      {
        email: 'Elsa.Cremin24@hotmail.com',
        phoneNumber: '536-847-8259 x1632',
        note: 'facere ullam ratione',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '1',
      },
      {
        email: 'Reilly48@yahoo.com',
        phoneNumber: '370.691.0591 x061',
        note: 'tempora ipsa voluptas',
        firstName: 'Maci',
        lastName: 'Kling',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '2',
      },
      {
        email: 'Lilliana.King@hotmail.com',
        phoneNumber: '(888) 283-6154 x217',
        note: 'atque qui rerum',
        firstName: 'Elvis',
        lastName: 'Rippin',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '3',
      },
      {
        email: 'Mark.Murazik@gmail.com',
        phoneNumber: '607-900-9684',
        note: 'est cupiditate debitis',
        firstName: 'Clair',
        lastName: 'Monahan',
        groups: [
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '4',
      },
      {
        email: 'Pink36@yahoo.com',
        phoneNumber: '914.673.8856',
        note: 'voluptates voluptas dolorem',
        firstName: 'Johann',
        lastName: 'Langosh',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '5',
      },
      {
        email: 'Fletcher70@yahoo.com',
        phoneNumber: '465.476.3385 x68021',
        note: 'dolorem consequuntur assumenda',
        firstName: 'Johann',
        lastName: 'Adams',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '6',
      },
      {
        email: 'Elwyn26@yahoo.com',
        phoneNumber: '466-256-4943',
        note: 'dolorem sunt ipsam',
        firstName: 'Unique',
        lastName: "O'Kon",
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
        ],
        permission: [],
        id: '7',
      },
      {
        email: 'Keara46@yahoo.com',
        phoneNumber: '756.280.9397',
        note: 'quaerat necessitatibus assumenda',
        firstName: 'Ayana',
        lastName: 'McDermott',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '8',
      },
      {
        id: '21',
        email: 'Isom.Waters@hotmail.com',
        phoneNumber: '1-998-398-4839 x0858',
        note: 'maiores porro ipsa',
        firstName: 'Geovanny',
        lastName: 'Hermann',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
      {
        id: '22',
        email: 'testing@hotmail.com',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        phoneNumber: '1-77859 x0858',
        note: 'maioreds saaa sd',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Pending',
            type: 'Admin',
            id: '1',
            susspended: false,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Do 100€',
            status: 'Declined',
            type: 'User',
            id: '2',
            susspended: true,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
    ],
    id: '3',
  },
  {
    name: 'Delivery fleet',
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'Admin',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    subGroups: [],

    users: [
      {
        email: 'Elsa.Cremin24@hotmail.com',
        phoneNumber: '536-847-8259 x1632',
        note: 'facere ullam ratione',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '1',
      },
      {
        email: 'Reilly48@yahoo.com',
        phoneNumber: '370.691.0591 x061',
        note: 'tempora ipsa voluptas',
        firstName: 'Maci',
        lastName: 'Kling',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '2',
      },
      {
        email: 'Lilliana.King@hotmail.com',
        phoneNumber: '(888) 283-6154 x217',
        note: 'atque qui rerum',
        firstName: 'Elvis',
        lastName: 'Rippin',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '3',
      },
      {
        email: 'Mark.Murazik@gmail.com',
        phoneNumber: '607-900-9684',
        note: 'est cupiditate debitis',
        firstName: 'Clair',
        lastName: 'Monahan',
        groups: [
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '4',
      },
      {
        email: 'Pink36@yahoo.com',
        phoneNumber: '914.673.8856',
        note: 'voluptates voluptas dolorem',
        firstName: 'Johann',
        lastName: 'Langosh',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '5',
      },
      {
        email: 'Fletcher70@yahoo.com',
        phoneNumber: '465.476.3385 x68021',
        note: 'dolorem consequuntur assumenda',
        firstName: 'Johann',
        lastName: 'Adams',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '6',
      },
      {
        email: 'Elwyn26@yahoo.com',
        phoneNumber: '466-256-4943',
        note: 'dolorem sunt ipsam',
        firstName: 'Unique',
        lastName: "O'Kon",
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
        ],
        permission: [],
        id: '7',
      },
      {
        email: 'Keara46@yahoo.com',
        phoneNumber: '756.280.9397',
        note: 'quaerat necessitatibus assumenda',
        firstName: 'Ayana',
        lastName: 'McDermott',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '8',
      },
      {
        email: 'Joseph46@yahoo.com',
        phoneNumber: '807-955-0214 x4710',
        note: 'assumenda ipsa qui',
        firstName: 'Eloy',
        lastName: 'Conroy',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '9',
      },
      {
        email: 'Beatrice.Mraz@hotmail.com',
        phoneNumber: '(671) 502-4220',
        note: 'ab cumque magnam',
        firstName: 'Natalia',
        lastName: 'Konopelski',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [],
        id: '6',
      },
      {
        email: 'Reese_Durgan@hotmail.com',
        phoneNumber: '922-767-8403',
        note: 'doloremque quis iure',
        firstName: 'Joanne',
        lastName: 'Jast',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '11',
      },
      {
        email: 'Demetrius_Barton@hotmail.com',
        phoneNumber: '(443) 699-3638',
        note: 'aliquam officia non',
        firstName: 'Geovany',
        lastName: 'Graham',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '16',
      },
      {
        email: 'Easter.Jast77@gmail.com',
        phoneNumber: '1-245-617-5461 x64768',
        note: 'veritatis eligendi molestiae',
        firstName: 'Amara',
        lastName: 'Von',
        groups: [
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '17',
      },
      {
        email: 'Korey_Schmitt@yahoo.com',
        phoneNumber: '(291) 645-8009 x393',
        note: 'inventore sed voluptatem',
        firstName: 'Elfrieda',
        lastName: 'Gerhold',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '18',
      },
    ],
    id: '4',
  },
  {
    name: 'Zamestnanci',
    permission: [
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'Admin',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    subGroups: [],

    users: [
      {
        email: 'Elsa.Cremin24@hotmail.com',
        phoneNumber: '536-847-8259 x1632',
        note: 'facere ullam ratione',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '1',
      },
      {
        email: 'Reilly48@yahoo.com',
        phoneNumber: '370.691.0591 x061',
        note: 'tempora ipsa voluptas',
        firstName: 'Maci',
        lastName: 'Kling',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '2',
      },
      {
        email: 'Demetrius_Barton@hotmail.com',
        phoneNumber: '(443) 699-3638',
        note: 'aliquam officia non',
        firstName: 'Geovany',
        lastName: 'Graham',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '16',
      },
      {
        email: 'Easter.Jast77@gmail.com',
        phoneNumber: '1-245-617-5461 x64768',
        note: 'veritatis eligendi molestiae',
        firstName: 'Amara',
        lastName: 'Von',
        groups: [
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '17',
      },
      {
        email: 'Korey_Schmitt@yahoo.com',
        phoneNumber: '(291) 645-8009 x393',
        note: 'inventore sed voluptatem',
        firstName: 'Elfrieda',
        lastName: 'Gerhold',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '18',
      },
      {
        email: 'Micah44@hotmail.com',
        phoneNumber: '759-803-8036 x90057',
        note: 'doloribus ex debitis',
        firstName: 'Rossie',
        lastName: 'Bartoletti',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },

          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '19',
      },
      {
        email: 'Clara.Runte@hotmail.com',
        phoneNumber: '(264) 847-0377',
        note: 'earum aperiam id',
        firstName: 'Miller',
        lastName: 'Hammes',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '20',
      },
      {
        id: '21',
        email: 'Isom.Waters@hotmail.com',
        phoneNumber: '1-998-398-4839 x0858',
        note: 'maiores porro ipsa',
        firstName: 'Geovanny',
        lastName: 'Hermann',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
      {
        id: '22',
        email: 'testing@hotmail.com',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        phoneNumber: '1-77859 x0858',
        note: 'maioreds saaa sd',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Pending',
            type: 'Admin',
            id: '1',
            susspended: false,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Do 100€',
            status: 'Declined',
            type: 'User',
            id: '2',
            susspended: true,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
    ],
    id: '5',
  },
  {
    name: 'Konzultanti - Hostia',
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'Admin',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    subGroups: [],

    users: [
      {
        email: 'Reilly48@yahoo.com',
        phoneNumber: '370.691.0591 x061',
        note: 'tempora ipsa voluptas',
        firstName: 'Maci',
        lastName: 'Kling',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '2',
      },
      {
        email: 'Lilliana.King@hotmail.com',
        phoneNumber: '(888) 283-6154 x217',
        note: 'atque qui rerum',
        firstName: 'Elvis',
        lastName: 'Rippin',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '3',
      },
      {
        email: 'Mark.Murazik@gmail.com',
        phoneNumber: '607-900-9684',
        note: 'est cupiditate debitis',
        firstName: 'Clair',
        lastName: 'Monahan',
        groups: [
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '4',
      },
      {
        email: 'Pink36@yahoo.com',
        phoneNumber: '914.673.8856',
        note: 'voluptates voluptas dolorem',
        firstName: 'Johann',
        lastName: 'Langosh',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '5',
      },
      {
        email: 'Fletcher70@yahoo.com',
        phoneNumber: '465.476.3385 x68021',
        note: 'dolorem consequuntur assumenda',
        firstName: 'Johann',
        lastName: 'Adams',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '6',
      },
      {
        email: 'Elwyn26@yahoo.com',
        phoneNumber: '466-256-4943',
        note: 'dolorem sunt ipsam',
        firstName: 'Unique',
        lastName: "O'Kon",
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
        ],
        permission: [],
        id: '7',
      },
      {
        email: 'Keara46@yahoo.com',
        phoneNumber: '756.280.9397',
        note: 'quaerat necessitatibus assumenda',
        firstName: 'Ayana',
        lastName: 'McDermott',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '8',
      },
      {
        email: 'Joseph46@yahoo.com',
        phoneNumber: '807-955-0214 x4710',
        note: 'assumenda ipsa qui',
        firstName: 'Eloy',
        lastName: 'Conroy',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Accepted',
            type: 'Admin',
            id: '5',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '9',
      },
      {
        email: 'Beatrice.Mraz@hotmail.com',
        phoneNumber: '(671) 502-4220',
        note: 'ab cumque magnam',
        firstName: 'Natalia',
        lastName: 'Konopelski',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [],
        id: '10',
      },
      {
        email: 'Reese_Durgan@hotmail.com',
        phoneNumber: '922-767-8403',
        note: 'doloremque quis iure',
        firstName: 'Joanne',
        lastName: 'Jast',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '11',
      },
      {
        email: 'Chadd_Bauch99@gmail.com',
        phoneNumber: '(920) 700-6264 x51934',
        note: 'nostrum blanditiis aut',
        firstName: 'Gaetano',
        lastName: 'Borer',
        groups: [
          {
            name: 'Predajňa Košice',
            id: '3',
          },

          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '12',
      },
      {
        email: 'Ivy.Schumm0@yahoo.com',
        phoneNumber: '576-243-7774 x07934',
        note: 'quas accusantium et',
        firstName: 'Michael',
        lastName: 'Byaers',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [],
        id: '13',
      },
      {
        email: 'Kyle_Kreiger@gmail.com',
        phoneNumber: '405-356-4365',
        note: 'quisquam praesentium unde',
        firstName: 'Shana',
        lastName: 'Funk',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '14',
      },
      {
        email: 'Amiya68@gmail.com',
        phoneNumber: '(591) 242-6017 x5687',
        note: 'sunt adipisci qui',
        firstName: "D'angelo",
        lastName: 'Ledner',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [],
        id: '15',
      },
      {
        email: 'Demetrius_Barton@hotmail.com',
        phoneNumber: '(443) 699-3638',
        note: 'aliquam officia non',
        firstName: 'Geovany',
        lastName: 'Graham',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '16',
      },
      {
        email: 'Easter.Jast77@gmail.com',
        phoneNumber: '1-245-617-5461 x64768',
        note: 'veritatis eligendi molestiae',
        firstName: 'Amara',
        lastName: 'Von',
        groups: [
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '17',
      },
      {
        email: 'Korey_Schmitt@yahoo.com',
        phoneNumber: '(291) 645-8009 x393',
        note: 'inventore sed voluptatem',
        firstName: 'Elfrieda',
        lastName: 'Gerhold',
        groups: [
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Charge points',
            name: 'Predajňa Praha',
            status: 'Accepted',
            type: 'User',
            id: '4',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '18',
      },
      {
        email: 'Micah44@hotmail.com',
        phoneNumber: '759-803-8036 x90057',
        note: 'doloribus ex debitis',
        firstName: 'Rossie',
        lastName: 'Bartoletti',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },

          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '19',
      },
      {
        email: 'Clara.Runte@hotmail.com',
        phoneNumber: '(264) 847-0377',
        note: 'earum aperiam id',
        firstName: 'Miller',
        lastName: 'Hammes',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Accepted',
            type: 'Admin',
            id: '1',
            susspended: true,
            inherited: true,
            isSelected: true,
          },
          {
            module: 'Wallet',
            name: 'Do 100€',
            status: 'Pending',
            type: 'User',
            id: '2',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Predajňa Košice',
            status: 'Declined',
            type: 'User',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
        id: '20',
      },
      {
        id: '21',
        email: 'Isom.Waters@hotmail.com',
        phoneNumber: '1-998-398-4839 x0858',
        note: 'maiores porro ipsa',
        firstName: 'Geovanny',
        lastName: 'Hermann',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },
          {
            name: 'Predajňa Praha',
            id: '2',
          },
          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
          {
            name: 'Konzultanti - Hostia',
            id: '8',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
      {
        id: '22',
        email: 'testing@hotmail.com',
        firstName: 'Gayle',
        lastName: 'Balistreri',
        phoneNumber: '1-77859 x0858',
        note: 'maioreds saaa sd',
        groups: [
          {
            name: 'Predajňa Bratislava',
            id: '1',
          },

          {
            name: 'Predajňa Košice',
            id: '3',
          },
          {
            name: 'Delivery fleet',
            id: '4',
          },
          {
            name: 'Zamestnanci',
            id: '5',
          },
        ],
        permission: [
          {
            module: 'Wallet',
            name: 'Do 200€',
            status: 'Pending',
            type: 'Admin',
            id: '1',
            susspended: false,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Charge points',
            name: 'Do 100€',
            status: 'Declined',
            type: 'User',
            id: '2',
            susspended: true,
            inherited: true,
            isSelected: false,
          },
          {
            module: 'Wallet',
            name: 'Predajňa Košice',
            status: 'Accepted',
            type: 'Admin',
            id: '3',
            susspended: false,
            inherited: false,
            isSelected: false,
          },
        ],
      },
    ],
    id: '8',
  },
];
export const masterUsersData = [
  {
    email: 'Elsa.Cremin24@hotmail.com',
    phoneNumber: '536-847-8259 x1632',
    note: 'facere ullam ratione',
    firstName: 'Gayle',
    lastName: 'Balistreri',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },
      {
        name: 'Predajňa Praha',
        id: '2',
      },
      {
        name: 'Predajňa Košice',
        id: '3',
      },

      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Admin finančné',
        id: '6',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Admin finančné',
        status: 'Accepted',
        type: 'Admin',
        id: '6',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '1',
  },
  {
    email: 'Reilly48@yahoo.com',
    phoneNumber: '370.691.0591 x061',
    note: 'tempora ipsa voluptas',
    firstName: 'Maci',
    lastName: 'Kling',
    groups: [
      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '2',
  },
  {
    email: 'Lilliana.King@hotmail.com',
    phoneNumber: '(888) 283-6154 x217',
    note: 'atque qui rerum',
    firstName: 'Elvis',
    lastName: 'Rippin',
    groups: [
      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '3',
  },
  {
    email: 'Mark.Murazik@gmail.com',
    phoneNumber: '607-900-9684',
    note: 'est cupiditate debitis',
    firstName: 'Clair',
    lastName: 'Monahan',
    groups: [
      {
        name: 'Predajňa Praha',
        id: '2',
      },
      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '4',
  },
  {
    email: 'Pink36@yahoo.com',
    phoneNumber: '914.673.8856',
    note: 'voluptates voluptas dolorem',
    firstName: 'Johann',
    lastName: 'Langosh',
    groups: [
      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '5',
  },
  {
    email: 'Fletcher70@yahoo.com',
    phoneNumber: '465.476.3385 x68021',
    note: 'dolorem consequuntur assumenda',
    firstName: 'Johann',
    lastName: 'Adams',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },
      {
        name: 'Predajňa Praha',
        id: '2',
      },
      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '6',
  },
  {
    email: 'Elwyn26@yahoo.com',
    phoneNumber: '466-256-4943',
    note: 'dolorem sunt ipsam',
    firstName: 'Unique',
    lastName: "O'Kon",
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },
    ],
    permission: [],
    id: '7',
  },
  {
    email: 'Keara46@yahoo.com',
    phoneNumber: '756.280.9397',
    note: 'quaerat necessitatibus assumenda',
    firstName: 'Ayana',
    lastName: 'McDermott',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },

      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '8',
  },
  {
    email: 'Joseph46@yahoo.com',
    phoneNumber: '807-955-0214 x4710',
    note: 'assumenda ipsa qui',
    firstName: 'Eloy',
    lastName: 'Conroy',
    groups: [
      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Accepted',
        type: 'Admin',
        id: '5',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '9',
  },
  {
    email: 'Beatrice.Mraz@hotmail.com',
    phoneNumber: '(671) 502-4220',
    note: 'ab cumque magnam',
    firstName: 'Natalia',
    lastName: 'Konopelski',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },
      {
        name: 'Predajňa Praha',
        id: '2',
      },
      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [],
    id: '10',
  },
  {
    email: 'Reese_Durgan@hotmail.com',
    phoneNumber: '922-767-8403',
    note: 'doloremque quis iure',
    firstName: 'Joanne',
    lastName: 'Jast',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },

      {
        name: 'Predajňa Košice',
        id: '3',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '11',
  },
  {
    email: 'Chadd_Bauch99@gmail.com',
    phoneNumber: '(920) 700-6264 x51934',
    note: 'nostrum blanditiis aut',
    firstName: 'Gaetano',
    lastName: 'Borer',
    groups: [
      {
        name: 'Predajňa Košice',
        id: '3',
      },

      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '12',
  },
  {
    email: 'Ivy.Schumm0@yahoo.com',
    phoneNumber: '576-243-7774 x07934',
    note: 'quas accusantium et',
    firstName: 'Michael',
    lastName: 'Byaers',
    groups: [
      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [],
    id: '13',
  },
  {
    email: 'Kyle_Kreiger@gmail.com',
    phoneNumber: '405-356-4365',
    note: 'quisquam praesentium unde',
    firstName: 'Shana',
    lastName: 'Funk',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },

      {
        name: 'Delivery fleet',
        id: '4',
      },
      {
        name: 'Zamestnanci',
        id: '5',
      },
    ],
    permission: [
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '14',
  },
  {
    email: 'Amiya68@gmail.com',
    phoneNumber: '(591) 242-6017 x5687',
    note: 'sunt adipisci qui',
    firstName: "D'angelo",
    lastName: 'Ledner',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },

      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [],
    id: '15',
  },
  {
    email: 'Demetrius_Barton@hotmail.com',
    phoneNumber: '(443) 699-3638',
    note: 'aliquam officia non',
    firstName: 'Geovany',
    lastName: 'Graham',
    groups: [
      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '16',
  },
  {
    email: 'Easter.Jast77@gmail.com',
    phoneNumber: '1-245-617-5461 x64768',
    note: 'veritatis eligendi molestiae',
    firstName: 'Amara',
    lastName: 'Von',
    groups: [
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '17',
  },
  {
    email: 'Korey_Schmitt@yahoo.com',
    phoneNumber: '(291) 645-8009 x393',
    note: 'inventore sed voluptatem',
    firstName: 'Elfrieda',
    lastName: 'Gerhold',
    groups: [
      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Charge points',
        name: 'Predajňa Praha',
        status: 'Accepted',
        type: 'User',
        id: '4',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '18',
  },
  {
    email: 'Micah44@hotmail.com',
    phoneNumber: '759-803-8036 x90057',
    note: 'doloribus ex debitis',
    firstName: 'Rossie',
    lastName: 'Bartoletti',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },
      {
        name: 'Predajňa Praha',
        id: '2',
      },

      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '19',
  },
  {
    email: 'Clara.Runte@hotmail.com',
    phoneNumber: '(264) 847-0377',
    note: 'earum aperiam id',
    firstName: 'Miller',
    lastName: 'Hammes',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },

      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Accepted',
        type: 'Admin',
        id: '1',
        susspended: true,
        inherited: true,
        isSelected: true,
      },
      {
        module: 'Wallet',
        name: 'Do 100€',
        status: 'Pending',
        type: 'User',
        id: '2',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Predajňa Košice',
        status: 'Declined',
        type: 'User',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
    id: '20',
  },
  {
    id: '21',
    email: 'Isom.Waters@hotmail.com',
    phoneNumber: '1-998-398-4839 x0858',
    note: 'maiores porro ipsa',
    firstName: 'Geovanny',
    lastName: 'Hermann',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },
      {
        name: 'Predajňa Praha',
        id: '2',
      },
      {
        name: 'Predajňa Košice',
        id: '3',
      },
      {
        name: 'Delivery fleet',
        id: '4',
      },
      {
        name: 'Zamestnanci',
        id: '5',
      },
      {
        name: 'Konzultanti - Hostia',
        id: '8',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Predajňa Košice',
        status: 'Accepted',
        type: 'Admin',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
  },
  {
    id: '22',
    email: 'testing@hotmail.com',
    firstName: 'Gayle',
    lastName: 'Balistreri',
    phoneNumber: '1-77859 x0858',
    note: 'maioreds saaa sd',
    groups: [
      {
        name: 'Predajňa Bratislava',
        id: '1',
      },

      {
        name: 'Predajňa Košice',
        id: '3',
      },
      {
        name: 'Delivery fleet',
        id: '4',
      },
      {
        name: 'Zamestnanci',
        id: '5',
      },
    ],
    permission: [
      {
        module: 'Wallet',
        name: 'Do 200€',
        status: 'Pending',
        type: 'Admin',
        id: '1',
        susspended: false,
        inherited: true,
        isSelected: false,
      },
      {
        module: 'Charge points',
        name: 'Do 100€',
        status: 'Declined',
        type: 'User',
        id: '2',
        susspended: true,
        inherited: true,
        isSelected: false,
      },
      {
        module: 'Wallet',
        name: 'Predajňa Košice',
        status: 'Accepted',
        type: 'Admin',
        id: '3',
        susspended: false,
        inherited: false,
        isSelected: false,
      },
    ],
  },
];
