export const mockData1 = [
  {
    month: 'Januray',
    consumption: 1.8814,
    id: '1',
  },
  {
    consumption: 5.5829,
    id: '2',
    month: 'February',
  },
  {
    consumption: 3.1808,
    id: '3',
    month: 'March',
  },
  {
    consumption: 7.9648,
    id: '4',
    month: 'April',
  },
  {
    consumption: 6.4007,
    id: '5',
    month: 'May',
  },
  {
    consumption: 8.6578,
    id: '6',
    month: 'June',
  },
  {
    consumption: 7.8162,
    id: '7',
    month: 'July',
  },
  {
    consumption: 9.3937,
    id: '8',
    month: 'August',
  },
  {
    consumption: 8.6233,
    id: '9',
    month: 'September',
  },
  {
    consumption: 5.1405,
    id: '10',
    month: 'October',
  },
  {
    consumption: 5.1405,
    id: '11',
    month: 'November',
  },
  {
    consumption: 5.1405,
    id: '12',
    month: 'December',
  },
];
export const mockData2 = [
  {
    month: 'Januray',
    consumption: 3.8814,
    id: '1',
  },
  {
    consumption: 6.5829,
    id: '2',
    month: 'February',
  },
  {
    consumption: 8.1808,
    id: '3',
    month: 'March',
  },
  {
    consumption: 6.9648,
    id: '4',
    month: 'April',
  },
  {
    consumption: 6.2007,
    id: '5',
    month: 'May',
  },
  {
    consumption: 10.6578,
    id: '6',
    month: 'June',
  },
  {
    consumption: 11.8162,
    id: '7',
    month: 'July',
  },
  {
    consumption: 9.0937,
    id: '8',
    month: 'August',
  },
  {
    consumption: 2.6233,
    id: '9',
    month: 'September',
  },
  {
    consumption: 1.1405,
    id: '10',
    month: 'October',
  },
  {
    consumption: 9.1405,
    id: '11',
    month: 'November',
  },
  {
    consumption: 13.1405,
    id: '12',
    month: 'December',
  },
];
export const mockData3 = [
  {
    month: 'Januray',
    consumption: 1.8814,
    id: '1',
  },
  {
    consumption: 2.5829,
    id: '2',
    month: 'February',
  },
  {
    consumption: 1.1808,
    id: '3',
    month: 'March',
  },
  {
    consumption: 1.9648,
    id: '4',
    month: 'April',
  },
  {
    consumption: 1.2007,
    id: '5',
    month: 'May',
  },
  {
    consumption: 1.6578,
    id: '6',
    month: 'June',
  },
  {
    consumption: 2.8162,
    id: '7',
    month: 'July',
  },
  {
    consumption: 3.0937,
    id: '8',
    month: 'August',
  },
  {
    consumption: 2.6233,
    id: '9',
    month: 'September',
  },
  {
    consumption: 1.1405,
    id: '10',
    month: 'October',
  },
  {
    consumption: 1.1405,
    id: '11',
    month: 'November',
  },
  {
    consumption: 2.1405,
    id: '12',
    month: 'December',
  },
];
