import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

export const authBaseUrl = process.env.REACT_APP_AUTH_API;

export const authBaseQuery = fetchBaseQuery({
  baseUrl: authBaseUrl,
  prepareHeaders(headers: Headers) {
    headers.set('Accept', 'application/json');

    return headers;
  },
}) as unknown as BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>;

export const authApi = createApi({
  baseQuery: authBaseQuery,
  reducerPath: 'authApi',
  tagTypes: [],
  endpoints: (build) => ({
    validate: build.mutation<ValidateApiResponse, ValidateApiArg>({
      query: (queryArg) => ({
        url: `/auth/validate`,
        method: 'POST',
        body: queryArg.validationRequest,
      }),
    }),
    register: build.mutation<RegisterApiResponse, RegisterApiArg>({
      query: (queryArg) => ({
        url: `/auth/register`,
        method: 'POST',
        body: queryArg.registrationRequest,
      }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/auth/phone-login`,
        method: 'POST',
        body: queryArg.phoneLoginRequest,
      }),
    }),
    logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
      query: (queryArg) => ({
        url: `/auth/logout`,
        method: 'POST',
        body: queryArg.logoutRequest,
      }),
    }),
    generateCode: build.mutation<GenerateCodeApiResponse, GenerateCodeApiArg>({
      query: (queryArg) => ({
        url: `/auth/generate-code`,
        method: 'POST',
        body: queryArg.codeRequest,
      }),
    }),
    login1: build.mutation<Login1ApiResponse, Login1ApiArg>({
      query: (queryArg) => ({
        url: `/auth/email-login`,
        method: 'POST',
        body: queryArg.emailLoginRequest,
      }),
    }),
    listModules: build.query<ListModulesApiResponse, ListModulesApiArg>({
      query: () => ({ url: `/common/module` }),
    }),
    listLocales: build.query<ListLocalesApiResponse, ListLocalesApiArg>({
      query: () => ({ url: `/common/locale` }),
    }),
    listCurrencies: build.query<
      ListCurrenciesApiResponse,
      ListCurrenciesApiArg
    >({
      query: () => ({ url: `/common/currency` }),
    }),
    listCountries: build.query<ListCountriesApiResponse, ListCountriesApiArg>({
      query: () => ({ url: `/common/country` }),
    }),
  }),
});
export type ValidateApiResponse =
  /** status 200 successful operation */ ValidationResponse;
export type ValidateApiArg = {
  validationRequest: ValidationRequest;
};
export type RegisterApiResponse = unknown;
export type RegisterApiArg = {
  registrationRequest: RegistrationRequest;
};
export type LoginApiResponse =
  /** status 200 successful operation */ LoginResponse;
export type LoginApiArg = {
  phoneLoginRequest: PhoneLoginRequest;
};
export type LogoutApiResponse =
  /** status 204 successful operation */ LogoutResponse;
export type LogoutApiArg = {
  logoutRequest: LogoutRequest;
};
export type GenerateCodeApiResponse =
  /** status 200 successful operation */ CodeResponse;
export type GenerateCodeApiArg = {
  codeRequest: CodeRequest;
};
export type Login1ApiResponse =
  /** status 200 successful operation */ LoginResponse;
export type Login1ApiArg = {
  emailLoginRequest: EmailLoginRequest;
};
export type ListModulesApiResponse = /** status 200 OK */ string[];
export type ListModulesApiArg = void;
export type ListLocalesApiResponse = /** status 200 OK */ string[];
export type ListLocalesApiArg = void;
export type ListCurrenciesApiResponse = /** status 200 OK */ string[];
export type ListCurrenciesApiArg = void;
export type ListCountriesApiResponse = /** status 200 OK */ Country[];
export type ListCountriesApiArg = void;
export type ValidationResponse = {
  isValid?: boolean;
  validationErrorReason?: string;
  appToken?: string;
  deviceId?: string;
  userId?: string;
};
export type ValidationRequest = {
  token: string;
  module?: 'wallet' | 'charge' | 'basic';
};
export type RegistrationRequest = {
  email: string;
  password: string;
  locale: string;
  currencyCode: string;
};
export type LoginResponse = {
  token?: string;
};
export type PhoneLoginRequest = {
  appToken: string;
  deviceId: string;
  region: string;
  number: string;
  code: string;
};
export type LogoutResponse = {
  valid?: boolean;
  invalidReason?: string;
};
export type LogoutRequest = {
  token: string;
};
export type CodeResponse = {
  valid?: boolean;
  invalidReason?: string;
};
export type CodeRequest = {
  number?: string;
};
export type EmailLoginRequest = {
  appToken: string;
  deviceId: string;
  region: string;
  email: string;
  password: string;
};
export type Country = {
  id?: number;
  name: string;
  code: string;
  isoCode: string;
  spfLangCode: string;
};
export const {
  useValidateMutation,
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGenerateCodeMutation,
  useLogin1Mutation,
  useListModulesQuery,
  useListLocalesQuery,
  useListCurrenciesQuery,
  useListCountriesQuery,
} = authApi;
