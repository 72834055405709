import { faShareFromSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import styles from './EditGroupPermissions.module.scss';
import { masterPermissionsData } from '../../../../../models/mockData/mockData';
import { IMasterPermissionsData } from '../../../../../models/interfaces';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import { useTranslation } from 'react-i18next';
interface IProps {
  initialValues: {
    name: string;
    permission: {
      module: string;
      name: string;
      status: string;
      type: string;
      id: string;
      susspended: boolean;
      inherited: boolean;
      isSelected: boolean;
    }[];
    users: {
      id: string;
      email: string;
      phoneNumber: string;
      note: string;
      permission: {}[];
    }[];
    id: string;
  };
}

const GroupPermissions = ({ initialValues }: IProps) => {
  const [copyData, setCopyData] = useState<IMasterPermissionsData[]>(
    initialValues.permission
  );
  const [searchBy, setSearchBy] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [show, setShow] = useState(false);
  const [showHint, setShowHint] = useState(true);

  const { t } = useTranslation();

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filteredData = initialValues.permission.filter(
      (data: IMasterPermissionsData) => {
        return (
          data.name.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
          data.module
            .toString()
            .toLowerCase()
            .includes(searchBy.toLowerCase()) ||
          data.type.toString().toLowerCase().includes(searchBy.toLowerCase())
        );
      }
    );
    if (searchBy === '') {
      setCopyData(initialValues.permission);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  return (
    <div className='bg-gray-300'>
      <div className='d-flex justify-content-between py-4'>
        <Form>
          <Form.Control
            className='w-350px '
            type='text'
            placeholder={t('contactGroups.assignedPermissions.search')}
            defaultValue=''
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </Form>
        <div className='d-flex flex-column align-items-end'>
          <button onClick={() => setShow(true)} className='btn btn-primary'>
            {t('contactGroups.assignedPermissions.addBtn')}
          </button>
        </div>
      </div>
      <Table className=' table table-secondary   table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold   px-1 bg-gray-300 border-0 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactGroups.assignedPermissions.table.headers.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactGroups.assignedPermissions.table.headers.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactGroups.assignedPermissions.table.headers.header3')}
            </Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: any) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  key={data.id}
                  className=' text-start border-4 border-gray-300'>
                  <Td className='bg-gray-500 fs-9 fw-bolder align-baseline   '>
                    <div
                      className={clsx(
                        data.module === 'Wallet'
                          ? 'bg-success rounded-2 px-4 py-2 d-flex justify-content-center '
                          : 'bg-warning rounded-2 px-4 py-2 d-flex justify-content-center '
                      )}>
                      {data.module}
                    </div>
                  </Td>
                  <Td className='bg-gray-500 w-100 w-md-75 align-baseline '>
                    {data.name}
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>{data.type}</Td>
                  <Td className='bg-gray-500 align-baseline '>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t(
                          'contactGroups.assignedPermissions.table.body.dropDown.title'
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t(
                            'contactGroups.assignedPermissions.table.body.dropDown.action1'
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t(
                            'contactGroups.assignedPermissions.table.body.dropDown.action2'
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />

      <Modal
        className={styles.rightSideModal}
        show={show}
        size='xl'
        onHide={() => setShow(false)}>
        <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100 '>
          <h2>{t('contactGroups.assignedPermissions.modal.name')}</h2>
          <button
            onClick={() => setShow(false)}
            className='btn btn-outline-primary border border-2 border-primary me-4 d-flex align-items-center'>
            {t('contactGroups.assignedPermissions.modal.closeBtn')}{' '}
            <FontAwesomeIcon className='ms-2' icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          {showHint && (
            <div
              style={{ background: '#FFF4CF' }}
              className=' d-flex flex-column p-8 fs-7 mb-8'>
              <h4>{t('contactGroups.assignedPermissions.modal.hint')}</h4>
              <p>{t('contactGroups.assignedPermissions.modal.hint2')}</p>
              <div className='d-flex justify-content-between'>
                <Link
                  className='mt-2 text-primary text-decoration-underline fs-9'
                  to='/managment/permissions'>
                  {t(
                    'contactGroups.assignedPermissions.modal.editPermissionBtn'
                  )}
                  <FontAwesomeIcon className='ms-1' icon={faShareFromSquare} />
                </Link>

                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowHint(false)}
                  className='mt-2 text-primary text-decoration-underline fs-9'>
                  {t('contactGroups.assignedPermissions.modal.dontShowBtn')}
                </p>
              </div>
            </div>
          )}
          <h3 className='text-gray-700 fs-5 mb-3'>
            {t('contactGroups.assignedPermissions.modal.moduleSelection')}
          </h3>
          <div className='text-center bg-gray-300 p-8 fs-7'>
            <Form>
              <Form.Select>
                {masterPermissionsData.map((option) => (
                  <option value={option.module}>{option.module}</option>
                ))}
              </Form.Select>
            </Form>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('contactGroups.assignedPermissions.modal.pemrissionType')}
          </h3>
          <div className='text-center bg-gray-300 p-8 fs-7'>
            <Form>
              <Form.Select>
                {masterPermissionsData.map((option) => (
                  <option value={option.type}>{option.type}</option>
                ))}
              </Form.Select>
            </Form>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('contactGroups.assignedPermissions.modal.pemrissionDetail')}
          </h3>
          <div className=' bg-gray-300 p-8 fs-7'>
            <Form>
              <div className='d-flex align-items-center justify-content-around '>
                <Form.Check className='my-4 w-50' type='switch' />
                <h3 className='fw-light'>Permission detail </h3>
              </div>
              <div className='d-flex align-items-center justify-content-around '>
                <Form.Check className='my-4 w-50' type='switch' />
                <h3 className='fw-light'>Permission detail </h3>
              </div>
              <div className='d-flex align-items-center justify-content-around '>
                <Form.Check className='my-4 w-50' />
                <h3 className='fw-light'>Permission detail </h3>
              </div>
              <div className='d-flex align-items-center justify-content-around '>
                <Form.Check className='my-4 w-50' />
                <h3 className='fw-light'>Permission detail </h3>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0 '>
          <div>
            {/*todo Save selection */}
            <button
              onClick={() => setShow(false)}
              className='d-flex justify-content-end btn btn-primary '>
              {t('contactGroups.assignedPermissions.modal.saveSelectionBtn')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GroupPermissions;
