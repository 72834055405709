import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { masterStationsData } from '../../../../models/mockData/energyManagementMockData';
import StationCharge from './charge/StationCharge';
import StationLiveData from './liveData/StationLiveData';
import StationLogs from './logs/StationLogs';
import StationOverview from './overview/StationOverview';
import StationSettings from './settings/StationSettings';
import StationUsage from './usage/StationUsage';

const StationDetailView = () => {
  const [navMenu, setNavMenu] = useState('overview');
  const { id } = useParams();
  const { t } = useTranslation();
  const selectedStation = masterStationsData.filter(
    (station) => station.id === id
  );
  const handleClick = (value: string) => {
    setNavMenu(value);
  };
  return (
    <>
      <div>
        <div className='d-flex justify-content-between'>
          <div>
            <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
              <li className='breadcrumb-item pe-3'>
                <Link to='/dashboard' className='pe-3'>
                  {t('breadcrumbs.chargingManagement')}
                </Link>
              </li>

              <li className='breadcrumb-item pe-3'>
                <Link to='/chargingmanagement/chargepoints' className='pe-3'>
                  {t('breadcrumbs.chargePoints')}
                </Link>
              </li>
              <li className='breadcrumb-item pe-3'>
                {selectedStation[0].partOfSection.map((section) => {
                  return (
                    <Link
                      key={section.id}
                      to={`/chargingmanagement/chargepoints/viewlocation/${section.location[0].id}`}
                      className='pe-3'>
                      {section.location[0].name}
                    </Link>
                  );
                })}
              </li>
              <li className='breadcrumb-item pe-3'>
                {selectedStation[0].partOfSection.map((section) => {
                  return (
                    <Link
                      key={section.id}
                      to={`/chargingmanagement/chargepoints`}
                      className='pe-3'>
                      {section.name}
                    </Link>
                  );
                })}
              </li>
            </ol>
            <h2 className='my-8'>{selectedStation[0].name}</h2>
            <nav>
              <ul className='d-flex list-unstyled'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'overview'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('overview')}>
                  {t('stationDetail.overview.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'charge'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('charge')}>
                  {t('stationDetail.charge.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'liveData'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('liveData')}>
                  {t('stationDetail.liveData.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'logs'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('logs')}>
                  {t('stationDetail.logs.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'usage'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('usage')}>
                  {t('stationDetail.usage.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'settings'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('settings')}>
                  {t('stationDetail.settings.name')}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='bg-gray-300 p-4'>
          {navMenu === 'overview' && <StationOverview />}
          {navMenu === 'charge' && (
            <StationCharge selectedStation={selectedStation[0]} />
          )}
          {navMenu === 'liveData' && <StationLiveData />}
          {navMenu === 'logs' && <StationLogs />}
          {navMenu === 'usage' && <StationUsage />}
          {navMenu === 'settings' && (
            <StationSettings selectedStation={selectedStation} />
          )}
        </div>
      </div>
    </>
  );
};

export default StationDetailView;
