import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IMasterUserData } from '../../../../../models/interfaces';
import { INewContact } from '../../../newContact/NewContact';

interface IProps {
  initialValues: IMasterUserData;
}

const About = ({ initialValues }: IProps) => {
  const { t } = useTranslation();
  const initValues = initialValues;

  const handleSubmit = (value: INewContact) => {
    // todo Logic to update contact
  };

  return (
    <div>
      <Formik<INewContact> initialValues={initValues} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <div className='bg-gray-300 p-8'>
              <h2>{t('contactList.editContactList.lineTabs.about.header')}</h2>
              <div>
                <div className='d-flex flex-wrap justify-content-between my-6'>
                  <div>
                    <h3>
                      {t('contactList.editContactList.lineTabs.about.email')}
                    </h3>
                    <Field
                      name='email'
                      className='p-2 w-250px   w-lg-300px w-xl-500px'
                      type='email'
                      placeholder='email adress'
                    />
                  </div>
                  <div>
                    <h3>
                      {' '}
                      {t('contactList.editContactList.lineTabs.about.phone')}
                    </h3>
                    <Field
                      name='phoneNumber'
                      className='p-2  w-250px w-lg-300px w-xl-500px'
                      type='text'
                      placeholder='Telephone number'
                    />
                  </div>
                </div>
                <div>
                  <h2>
                    {t(
                      'contactList.editContactList.lineTabs.about.personalNote'
                    )}
                  </h2>
                  <div className='my-6'>
                    <h3>
                      {t('contactList.editContactList.lineTabs.about.note')}
                    </h3>
                    <Field
                      className='w-100 p-4'
                      as='textarea'
                      name='note'
                      rows={3}
                      placeholder='Add note...'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default About;
