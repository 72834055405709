import React from 'react';
import { Link } from 'react-router-dom';

const FinancePage = () => {
  return (
    <div>
      <div className='card shadow-sm my-4'>
        <div className='card-header'>
          <h3 className='card-title'>Prehľad všetkých transakcií</h3>
          <div className='card-toolbar'>
            <Link to='/finance/all'>
              <button type='button' className='btn btn-sm btn-primary'>
                Zobraz
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='card shadow-sm my-4'>
        <div className='card-header'>
          <h3 className='card-title'>Prehľad transakcií klientov</h3>
          <div className='card-toolbar'>
            <Link to='/finance/clients'>
              {' '}
              <button type='button' className='btn btn-sm btn-primary'>
                Zobraz
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='card shadow-sm my-4'>
        <div className='card-header'>
          <h3 className='card-title'>Prehľad transakcií partnerov</h3>
          <div className='card-toolbar'>
            <Link to='/finance/partners'>
              <button type='button' className='btn btn-sm btn-primary'>
                Zobraz
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancePage;
