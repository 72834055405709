import React, { useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMasterStationTypeData } from '../../../../../models/interface';

interface IProps {
  selectedType: IMasterStationTypeData | null;
  manufacturerValue: string;
}
//todo translation for accordions

const DetailsView = ({ selectedType, manufacturerValue }: IProps) => {
  const [stationCount, setStationCount] = useState(0);
  const [toggleDetails, setToggleDetails] = useState(false);
  const { t } = useTranslation();

  const handleAddValue = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setStationCount(stationCount + 1);
  };
  const handleDecreaseValue = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setStationCount(stationCount - 1);
  };
  return (
    <div className='w-100 bg-gray-300'>
      <div className=' p-6  '>
        <h3 className='fw-normal mb-6'>
          {t('createNewStation.stepperBody.detailsView.name')}
        </h3>
        <p>{t('createNewStation.stepperBody.detailsView.infoText')}</p>
        <div className='my-4'>
          <h3 className=' text-gray-700'>
            {t('createNewStation.stepperBody.detailsView.stationInfo')}
          </h3>

          <Form>
            <div className='bg-gray-300 p-4'>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  {t(
                    'createNewStation.stepperBody.detailsView.form.stationName'
                  )}
                </h4>
                <Form.Control
                  // onChange={(e) => setStationName(e.target.value)}
                  className='w-250px'
                  type='text'
                  placeholder={t(
                    'createNewStation.stepperBody.detailsView.form.stationName'
                  )}
                />
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  {t('createNewStation.stepperBody.detailsView.form.ocpp')}
                </h4>
                <Form.Control
                  className='w-250px'
                  type='text'
                  placeholder={t(
                    'createNewStation.stepperBody.detailsView.form.ocpp'
                  )}
                />
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  {t(
                    'createNewStation.stepperBody.detailsView.form.manufacturer'
                  )}
                </h4>
                <h4>{manufacturerValue}</h4>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  {t('createNewStation.stepperBody.detailsView.form.type')}
                </h4>
                <h4>{selectedType?.name}</h4>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  {t(
                    'createNewStation.stepperBody.detailsView.form.description'
                  )}
                </h4>
                <h4>{selectedType?.note}</h4>
              </div>
              <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                <h4>
                  {t(
                    'createNewStation.stepperBody.detailsView.form.parkingSlots'
                  )}
                </h4>
                <div className='d-flex'>
                  {stationCount > 0 && (
                    <button
                      onClick={(e) => handleDecreaseValue(e)}
                      className='btn btn-secondary btn-sm me-2'>
                      -
                    </button>
                  )}
                  <Form.Control
                    readOnly
                    type='text'
                    value={stationCount}
                    className='w-100px'
                  />
                  <button
                    onClick={(e) => handleAddValue(e)}
                    className='btn btn-secondary btn-sm ms-2'>
                    +
                  </button>
                </div>
              </div>
              <h3
                onClick={() => setToggleDetails(!toggleDetails)}
                style={{ cursor: 'pointer' }}
                className='text-center text-primary text-decoration-underline my-4'>
                {t(
                  'createNewStation.stepperBody.detailsView.moreStationDetail'
                )}
              </h3>
            </div>
          </Form>
        </div>
        {toggleDetails && (
          <Accordion className='bg-gray-300' flush alwaysOpen>
            <Accordion.Item eventKey='0' className='mb-2'>
              <Accordion.Header>
                <div className=' d-flex flex-column '>
                  <h3 className=' text-gray-700'>PLUG</h3>
                  <h2>A</h2>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Výrobný výkon</h4>
                  <h4> 5kW</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Max výkon</h4>
                  <h4> 20kW</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Output type</h4>
                  <h4>AC</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Connection type</h4>
                  <h4>Cable 5m, Curved</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Number of phases</h4>
                  <h4>3</h4>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1' className='mb-2'>
              <Accordion.Header>
                <div className=' d-flex flex-column '>
                  <h3 className=' text-gray-700'>PLUG</h3>
                  <h2>B</h2>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Výrobný výkon</h4>
                  <h4> 5kW</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Max výkon</h4>
                  <h4> 20kW</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Output type</h4>
                  <h4>AC</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Connection type</h4>
                  <h4>Cable 5m, Curved</h4>
                </div>
                <div className='bg-light p-4 my-1 d-flex justify-content-between align-items-center'>
                  <h4>Number of phases</h4>
                  <h4>3</h4>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default DetailsView;
