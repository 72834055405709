import React from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMasterChargePointsData } from '../../../../../models/interface';
import { masterChargePointsData } from '../../../../../models/mockData/energyManagementMockData';

const PlacementView = () => {
  const { t } = useTranslation();
  return (
    <div className='w-100 bg-gray-300'>
      <div className=' p-6  '>
        <h3 className='fw-normal mb-6'>
          {t('createNewStation.stepperBody.placementView.name')}
        </h3>
        <p>{t('createNewStation.stepperBody.placementView.infoText')}</p>
        <div className='my-4'>
          <h3 className=' text-gray-700'>
            {t('createNewStation.stepperBody.placementView.avalaibleLocation')}
          </h3>
        </div>
        <Accordion className='bg-light' flush alwaysOpen>
          {masterChargePointsData.map((location: IMasterChargePointsData) => (
            <Accordion.Item
              key={location.id}
              eventKey={location.id}
              className='mb-2'>
              <Accordion.Header>
                <div className='d-flex w-100 justify-content-between'>
                  <h2>{location.name}</h2>
                  <p className='me-8'>
                    {location.sections.length}{' '}
                    {t('createNewStation.stepperBody.placementView.sections')},{' '}
                    {location.stations.length}{' '}
                    {t('createNewStation.stepperBody.placementView.stations')}
                  </p>
                </div>
              </Accordion.Header>
              {location.sections.map((section) => (
                <Accordion.Body
                  key={section.id}
                  className='d-flex align-items-center mx-2 my-1 bg-gray-300'>
                  <Form>
                    <Form.Check type='radio' />
                  </Form>
                  <div className='ms-4 d-flex flex-column'>
                    <h3 className='text-gray-700'>
                      {t(
                        'createNewStation.placementView.stepperBody.sectionCapital'
                      )}
                    </h3>
                    <h2>{section.name}</h2>
                  </div>
                </Accordion.Body>
              ))}
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default PlacementView;
