import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { Table, Tbody, Td, Thead, Tr } from 'react-super-responsive-table';
import { IMasterStationUsageData } from '../mockData/interfaces';
import { stationUsageData } from '../mockData/stationViewMockData';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import CsvExport from '../../../../../../../components/csv/csvExport/CsvExport';
import { useTranslation } from 'react-i18next';

const StationUsage = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [plugSelect, setPlugSelect] = useState('');
  const [searchBy, setSearchBy] = useState(plugSelect);

  const [copyData, setCopyData] =
    useState<IMasterStationUsageData[]>(stationUsageData);

  const contactsPerPage = 10;
  const { t } = useTranslation();

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filteredData = stationUsageData.filter(
      (data: IMasterStationUsageData) => {
        return (
          data.plug
            .toString()
            .toLowerCase()
            .includes(plugSelect.toLowerCase()) &&
          (data.plug
            .toString()
            .toLowerCase()
            .includes(searchBy.toLowerCase()) ||
            data.chargedEnergy
              .toString()
              .toLowerCase()
              .includes(searchBy.toLowerCase()) ||
            data.cost
              .toString()
              .toLowerCase()
              .includes(searchBy.toLowerCase()) ||
            data.fee
              .toString()
              .toLowerCase()
              .includes(searchBy.toLowerCase()) ||
            data.parkingFee
              .toString()
              .toLowerCase()
              .includes(searchBy.toLowerCase()) ||
            data.otherFee
              .toString()
              .toLowerCase()
              .includes(searchBy.toLowerCase()))
        );
      }
    );

    setCopyData(filteredData);
  }, [searchBy, plugSelect]);

  return (
    <div>
      <Form className='d-flex justify-content-between mb-10'>
        <div>
          <h3 className='text-gray-700'> {t('stationDetail.usage.type')}</h3>
          <Form.Control
            onChange={(e) => setSearchBy(e.target.value)}
            placeholder='Search'
          />
        </div>
        <div className='d-flex'>
          <div className='d-flex'>
            <div className='me-1'>
              <h3 className='text-gray-700'>
                {t('stationDetail.usage.plug.title')}
              </h3>

              <Form.Select onChange={(e) => setPlugSelect(e.target.value)}>
                <option value=''>
                  {t('stationDetail.usage.plug.option1')}
                </option>
                <option value='A'>
                  {t('stationDetail.usage.plug.option2')}
                </option>
                <option value='B'>
                  {t('stationDetail.usage.plug.option3')}
                </option>
              </Form.Select>
            </div>
            <div className='me-1'>
              <h3 className='text-gray-700'>
                {' '}
                {t('stationDetail.usage.dateFrom')}
              </h3>
              <Form.Control type='date' />
            </div>
            <div className='me-1'>
              <h3 className='text-gray-700'>
                {' '}
                {t('stationDetail.usage.dateTo')}
              </h3>
              <Form.Control type='date' />
            </div>
          </div>
          <div className='d-flex align-items-end ms-4'>
            <CsvExport
              data={copyData}
              filename='Station usage'
              className='btn btn-primary'
              title='Export'
              icon={faAngleDown}
            />
          </div>
        </div>
      </Form>
      <Table className=' table table-secondary   table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold px-1 bg-gray-300 border-0 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header3')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header4')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header5')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header6')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header7')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.usage.tableHeaders.header8')}
            </Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: IMasterStationUsageData) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  key={data.id}
                  className=' text-start border-4 border-gray-300'>
                  <Td className='bg-gray-500  fw-bolder align-baseline   '>
                    {data.plug}
                  </Td>
                  <Td className='bg-gray-500 align-baseline   '>
                    {data.startAt}
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>{data.endAt}</Td>
                  <Td className='bg-gray-500 align-baseline '>
                    {data.chargedEnergy}
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>{data.cost}€</Td>
                  <Td className='bg-gray-500 align-baseline '>{data.fee}€</Td>
                  <Td className='bg-gray-500 align-baseline '>
                    {data.parkingFee}€
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>
                    {data.otherFee}€
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t('stationDetail.usage.tableBody.dropdownBtn.title')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t(
                            'stationDetail.usage.tableBody.dropdownBtn.action1'
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t(
                            'stationDetail.usage.tableBody.dropdownBtn.action2'
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </div>
  );
};

export default StationUsage;
