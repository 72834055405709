import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import {
  IMasterPermissionsData,
  IMasterUserData,
} from '../../../../../models/interfaces';
import styles from './EditMembersPermission.module.scss';
import { masterUsersData } from '../../../../../models/mockData/mockData';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';

// todo rearange mockdata to be cooperative and possible display
interface IProps {
  initialValues: IMasterPermissionsData;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

const EditMembersPermission = ({ initialValues, setShow, show }: IProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageModalNumber, setPageModalNumber] = useState(0);

  const [showHint, setShowHint] = useState(true);

  const [searchInMembers, setSearchInMembers] = useState('');
  // ! use for the next state  empty array/membersPermission if you want test assigning contacts

  const [selectedCheck, setSelectedCheck] = useState<any[] | null>([]);
  const [modalData, setModalData] = useState(masterUsersData);
  const [searchInModal, setSearchInModal] = useState('');
  const result = masterUsersData.filter((obj) => {
    const permissions = obj.permission;
    const filteredPermissions = permissions.filter((permission) => {
      return permission.id === initialValues.id;
    });
    return filteredPermissions.length !== 0;
  });

  const [copyData, setCopyData] = useState(result);

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;
  const pagesModalVisited = pageModalNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageCountModal = Math.ceil(modalData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const pageModalChange = ({ selected }: any) => {
    setPageModalNumber(selected);
  };
  const handleCheck = (object: any, checked: boolean) => {
    if (checked === true) {
      selectedCheck
        ? setSelectedCheck([...selectedCheck, object])
        : setSelectedCheck([object]);
    } else if (checked === false) {
      selectedCheck
        ? setSelectedCheck(selectedCheck?.filter((obj) => obj.id !== object.id))
        : setSelectedCheck(selectedCheck);
    }
  };
  const handleRemoveItem = (id: string) => {
    selectedCheck
      ? setSelectedCheck(selectedCheck.filter((obj) => obj.id !== id))
      : setSelectedCheck(null);
  };
  useEffect(() => {
    const filteredData = masterUsersData.filter((data: any) => {
      return (
        data.email
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase()) ||
        data.phoneNumber
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase())
      );
    });
    if (searchInModal === '') {
      setModalData(masterUsersData);
    } else {
      setModalData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInModal]);

  useEffect(() => {
    const filteredData = result.filter((data: any) => {
      return data.email
        .toString()
        .toLowerCase()
        .includes(searchInMembers.toLowerCase());
    });
    if (searchInMembers === '') {
      setCopyData(result);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInMembers]);

  return (
    <div>
      <Form>
        <Form.Control
          className='w-250px'
          type='text'
          placeholder='Search'
          defaultValue=''
          onChange={(e) => setSearchInMembers(e.target.value)}
        />
      </Form>
      {copyData.length === 0 && (
        <div className='bg-gray-500 border-0 p-10 d-flex mx-auto flex-column align-items-center justify-content-center my-2 w-100 h-350px'>
          <h3 className='bg-gray-500'>
            No user or group assigned to this permission
          </h3>
          <button onClick={() => setShow(true)} className='btn btn-primary'>
            Assign
          </button>
        </div>
      )}
      {copyData.length !== 0 && (
        <>
          <Table className=' table table-secondary  table-responsive table-row-dashed  table-hover gy-4 gs-7'>
            <Thead>
              <Tr className='fw-bold  border-0 px-1 bg-gray-300 '>
                <Td className='bg-gray-300 text-gray-700'>CONTACTS</Td>
                <Td className='bg-gray-300 text-gray-700'>INVITATION STATUS</Td>
              </Tr>
            </Thead>

            <Tbody className='fw-bold'>
              {copyData
                .slice(pagesVisited, pagesVisited + contactsPerPage)
                .map((data: IMasterUserData) => {
                  return (
                    <Tr
                      key={data.id}
                      style={{ borderBottomStyle: 'solid' }}
                      className='y text-start border-4 border-gray-300'>
                      <Td className='bg-gray-500 border-0 my-auto '>
                        <div className='d-flex flex-column'>
                          <div className='d-flex '>
                            <Form>
                              <Form.Check className='me-2' />
                            </Form>
                            <div className='d-flex flex-column   '>
                              <div className='d-flex flex-column align-items-start  flex-md-row'>
                                <p className='w-75'> {data.email}</p>
                                {data.permission.map((obj) => {
                                  const suspend = obj.id === initialValues.id;
                                  return (
                                    <p
                                      style={{ background: '#FFA79A' }}
                                      className={clsx(
                                        suspend
                                          ? 'ms-md-2 rounded-3 px-2 fs-10 text-white fw-lighter '
                                          : ''
                                      )}>
                                      {suspend ? 'SUSPENDED' : ''}
                                    </p>
                                  );
                                })}
                              </div>
                              {data.permission.map((obj) => {
                                const inherit = obj.id === initialValues.id;
                                return (
                                  <p
                                    className={clsx(
                                      inherit
                                        ? 'text-primary fw-lighter text-decoration-underline fs-9 '
                                        : ''
                                    )}>
                                    {inherit ? 'inherited from group' : ''}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Td>
                      {data.permission.map((obj) =>
                        obj.id === initialValues.id ? (
                          <Td className='bg-gray-500 border-0 my-auto'>
                            {obj.status}
                          </Td>
                        ) : null
                      )}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
        </>
      )}
      <Modal
        className={styles.rightSideModal}
        show={show}
        size='xl'
        onHide={() => setShow(false)}>
        <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100 '>
          <h2>Select contacts</h2>
          <button
            onClick={() => setShow(false)}
            className='btn btn-outline-primary border border-2 border-primary me-4 d-flex align-items-center'>
            Close <FontAwesomeIcon className='ms-2' icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          {showHint && (
            <div
              style={{ background: '#FFF4CF' }}
              className=' d-flex flex-column p-8 fs-7 mb-8'>
              <h4 className='fw-bolder fs-7'>
                Selected contacts will be notified when permission is created.
              </h4>
              <h6 className='fw-normal fs-7'>
                Please be aware of how may or to who are you sending
                notification.
                <span className='fw-bolder'>Contact Group</span> first.
              </h6>

              <div className='d-flex justify-content-end'>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowHint(false)}
                  className='mt-2 text-primary text-decoration-underline fs-9'>
                  Don't show
                </p>
              </div>
            </div>
          )}
          <h3 className='text-gray-700 fs-5 mb-3'>
            SELECTED CONTACTS OR GROUP
          </h3>
          <div className='text-center bg-gray-300 p-8 fs-7 '>
            {selectedCheck &&
              selectedCheck.map((objekt) => (
                <div
                  key={objekt.id}
                  className={clsx(
                    objekt.name ? 'bg-gray-200' : 'bg-gray-500',
                    'my-2  p-4 d-flex text-start justify-content-between '
                  )}>
                  <div>
                    <p className='text-gray-700 fw-bold'>
                      {objekt.email ? 'CONTACTS' : 'USER GROUP'}
                    </p>
                    <h4 className='fs-6 '>{objekt.email}</h4>

                    {/* {data.name && (
                          <p className='text-gray-700 fw-bold'>
                            {data.name.length} users in this group
                          </p>
                        )} */}
                    {objekt.phoneNumber && (
                      <p className='text-black fw-light'>
                        {objekt.phoneNumber}
                      </p>
                    )}
                  </div>
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveItem(objekt.id)}
                    icon={faTrash}
                  />
                </div>
              ))}
            {selectedCheck?.length === 0 && (
              <p>Select one or more products from the list bellow</p>
            )}
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>SEARCH</h3>
          <div>
            <Form>
              <Form.Control
                onChange={(e) => setSearchInModal(e.target.value)}
                type='text'
                placeholder='Search'
              />
            </Form>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>YOUR CONTACTS AND GROUPS</h3>
          <div className=' bg-gray-300 p-8 fs-7'>
            {modalData
              .slice(pagesModalVisited, pagesModalVisited + contactsPerPage)
              .map((data) => {
                return (
                  <div
                    key={data.id}
                    className={clsx(
                      data.email ? 'bg-gray-500' : 'bg-gray-200',
                      'my-2  p-4 d-flex justify-content-start '
                    )}>
                    <Form>
                      <Form.Check
                        onChange={(e) => handleCheck(data, e.target.checked)}
                        className='me-2'
                      />
                    </Form>

                    <div>
                      <p className='text-gray-700 fw-bold'>
                        {data.email ? 'CONTACTS' : 'USER GROUP'}
                      </p>
                      <h4 className='fs-6 '>{data.email}</h4>

                      {/* {data.name && (
                          <p className='text-gray-700 fw-bold'>
                            {data.name.length} users in this group
                          </p>
                        )} */}
                      {data.phoneNumber && (
                        <p className='text-black fw-light'>
                          {data.phoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            <div className='d-flex justify-content-end'>
              <PaginateComponent
                pageCount={pageCountModal}
                pageChange={pageModalChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0 '>
          <div>
            {/*todo Save selection */}
            <button
              onClick={() => setShow(false)}
              className='d-flex justify-content-end btn btn-primary '>
              Save selection
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditMembersPermission;
