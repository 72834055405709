import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FilterTop from './filterTop/FilterTop';
import ChartsView from './lineTabs/charts/ChartsView';
import SummaryView from './lineTabs/summary/SummaryView';
import TableView from './lineTabs/table/TableView';

const ReportsView = () => {
  const [navMenu, setNavMenu] = useState('summary');
  const { t } = useTranslation();

  const handleClick = (value: string) => {
    setNavMenu(value);
  };
  return (
    <>
      <div>
        <div>
          <div>
            <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
              <li className='breadcrumb-item pe-3'>
                <Link to='/dashboard' className='pe-3'>
                  {t('breadcrumbs.management')}
                </Link>
              </li>
            </ol>
            <h3 className='fw-bold mb-8'>{t('reports.title')}</h3>
            <FilterTop />
            <nav>
              <ul className='d-flex list-unstyled'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'summary'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('summary')}>
                  {t('reports.summary.title')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'charts'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('charts')}>
                  {t('reports.charts.title')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'table'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('table')}>
                  {t('reports.table.title')}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='bg-gray-300 p-4 mb-8'>
          {navMenu === 'summary' && <SummaryView />}
          {navMenu === 'charts' && <ChartsView />}
          {navMenu === 'table' && <TableView />}
        </div>
      </div>
    </>
  );
};

export default ReportsView;
