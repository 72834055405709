import { Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import DisplayContacts from './displayContacts/DisplayContacts';
import { IMasterUserData } from '../../../models/interfaces';
import PaginateComponent from '../../../../../components/pagination/PaginateComponent';
import CsvExport from '../../../../../components/csv/csvExport/CsvExport';

import { useTranslation } from 'react-i18next';

interface IProps {
  copyData: IMasterUserData[];
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
  setNewUserOption: React.Dispatch<React.SetStateAction<string>>;
}

const ListView = ({ copyData, setSearchBy, setNewUserOption }: IProps) => {
  //* state for modal display
  const [show, setShow] = useState(false);
  //* state for uploaded file
  const [file, setFile] = useState();
  //* state for array of objects from imported file
  const [contacts, setContacts] = useState([]);
  //* state for paginnation
  const [pageNumber, setPageNumber] = useState(0);
  //*functions to close/open modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation();
  const fileReader = new FileReader();
  //* Pagination Logic start
  const contactsPerPage = 10;
  const pagesVisited = pageNumber * contactsPerPage;
  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  //* Pagination Logic ends

  //*Logic for importing files starts
  const handleOnChange = (e: any | null) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string: any) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');
    const array = csvRows.map((i: any) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    setContacts(array);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event: any | null) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(file);
      handleClose();
    }
  };
  //*Logic for importing files ends

  return (
    <>
      <div className='mb-10'>
        <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
          <li className='breadcrumb-item pe-3'>
            <Link to='/dashboard' className='pe-3'>
              {t('breadcrumbs.management')}
            </Link>
          </li>
        </ol>
        <h1 className='fw-light'>{t('contactList.name')}</h1>
        <p className='fw-light'>{t('contactList.tips')}</p>
      </div>

      <div className='bg-gray-300 p-8'>
        <div className='d-flex flex-column  flex-md-row justify-content-md-between  mb-8 '>
          <Form>
            <Form.Control
              className='w-100 mb-4 m-md-0 w-md-350px'
              type='text'
              placeholder='Search'
              defaultValue=''
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </Form>

          <div className='d-flex justify-content-end '>
            <CsvExport
              data={copyData}
              filename='Contacts'
              className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white text-primary me-4'
              title={t('contactList.exportBtn')}
            />
            <Dropdown>
              <Dropdown.Toggle className='btn btn-primary  p-4 '>
                {t('contactList.dropDown.title')}
              </Dropdown.Toggle>

              <Dropdown.Menu className='bg-secondary p-1'>
                <Dropdown.Item
                  className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'
                  onClick={() => setNewUserOption('addContact')}>
                  {t('contactList.dropDown.action1')}
                </Dropdown.Item>
                <Dropdown.Item
                  className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'
                  onClick={handleShow}>
                  {t('contactList.dropDown.action2')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Modal centered show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {t('contactList.dropDown.importModal.title')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {t('contactList.dropDown.importModal.text')}
                <input
                  className='form-control mt-2'
                  accept='.csv'
                  type='file'
                  id='csvFileInput'
                  onChange={handleOnChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <button className='btn ' onClick={handleClose}>
                  {t('contactList.dropDown.importModal.closeBtn')}
                </button>
                <button
                  className='btn btn-primary'
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}>
                  {t('contactList.dropDown.importModal.importBtn')}
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        {/* <p>Showing {copyData.length} contacts</p> */}

        <DisplayContacts
          copyData={copyData}
          pagesVisited={pagesVisited}
          contactsPerPage={contactsPerPage}
          contacts={contacts}
        />
        <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
      </div>
    </>
  );
};

export default ListView;
