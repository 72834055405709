import { faClose, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import { filterMockData } from '../model/mockData/filterMockData';
import {
  IMasterChargePointsData,
  IMasterSectionsData,
  IMasterStationData,
} from '../../../chargingManagment/models/interface';
import {
  masterChargePointsData,
  masterSectionsData,
  masterStationsData,
} from '../../../chargingManagment/models/mockData/energyManagementMockData';
import { useTranslation } from 'react-i18next';

const FilterTop = () => {
  const [showModule, setShowModule] = useState(false);
  const [selection, setShowSelection] = useState(false);
  const [moduleData, setModuleData] = useState('');
  const { t } = useTranslation();

  return (
    <div className='bg-gray-300 p-4 mb-8 d-flex flex-column  '>
      <div className='d-flex flex-column flex-md-row '>
        <div className='w-auto w-md-100 p-4 bg-white m-2 d-flex flex-column justify-content-between align-items-start'>
          <div>
            <h4 className='fw-normal mb-8'>{t('reports.filterTop.header')}</h4>
            <div className='mb-10'>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('reports.filterTop.module')}
              </h3>
              {/**here comes selected items */}
            </div>
            <div>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('reports.filterTop.selection')}
              </h3>
              {/**here comes selected items */}
            </div>
          </div>
          <div>
            <button
              onClick={() => setShowModule(true)}
              className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 fs-9  text-primary '>
              {t('reports.filterTop.editBtn')}
            </button>
          </div>
        </div>
        <div className='w-auto w-md-100  p-4 bg-white m-2 d-flex flex-column justify-content-between  align-items-start'>
          <div>
            <h4 className='fw-normal mb-8'>
              {t('reports.filterTop.selectData')}
            </h4>

            <div className=''>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('reports.filterTop.selection')}
              </h3>
              {/**here comes selected items */}
            </div>
          </div>
          <div>
            <button
              onClick={() => setShowSelection(true)}
              className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 fs-9  text-primary'>
              {t('reports.filterTop.editBtn')}
            </button>
          </div>
        </div>
        <div className='w-auto w-md-100  p-4 bg-white m-2 d-flex flex-column align-items-start'>
          <h4 className='fw-normal mb-8'>Select timeframe</h4>
          <div>
            <div className='d-flex flex-wrap flex-md-nowrap'>
              <button className=' m-1 m-md-0 me-md-1 btn btn-sm btn-outline-primary border border-1 border-primary bg-white bg-hover-primary text-hover-white'>
                1 {t('reports.filterTop.day')}
              </button>
              <button className='m-1 m-md-0 me-md-1  btn btn-sm btn-outline-primary border border-1 border-primary bg-white bg-hover-primary text-hover-white'>
                3 {t('reports.filterTop.days')}
              </button>
              <button className='m-1 m-md-0 me-md-1  btn btn-sm btn-outline-primary border border-1 border-primary bg-white bg-hover-primary text-hover-white'>
                1 {t('reports.filterTop.week')}
              </button>
              <button className=' m-1 m-md-0 me-md-1 btn btn-sm btn-outline-primary border border-1 border-primary bg-white bg-hover-primary text-hover-white'>
                1 {t('reports.filterTop.month')}
              </button>
              <Dropdown className='m-1'>
                <Dropdown.Toggle variant='link' bsPrefix='p-0'>
                  <div className='btn bg-gray-400 ms-4'>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='bg-secondary p-1'>
                  <Dropdown.Item
                    // onClick={() => handleViewPermission(data.id)}
                    className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                    {t('reports.filterTop.dropDown.action1')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    // onClick={() => handleEditPermission(data.id)}
                    className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                    {t('reports.filterTop.dropDown.action2')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className='d-flex flex-wrap mt-4'>
            <div className='me-4'>
              <h3 className='text-gray-700 fs-5 '>
                {t('reports.filterTop.dateFrom')}
              </h3>
              <Form>
                <Form.Control type='date' />
              </Form>
            </div>
            <div className='my-2 my-md-0'>
              <h3 className='text-gray-700 fs-5 '>
                {t('reports.filterTop.dateTo')}
              </h3>
              <Form>
                <Form.Control type='date' />
              </Form>
            </div>
          </div>
          <div className='mt-8 d-flex flex-wrap flex-md-nowrap'>
            <Form className='d-flex'>
              <Form.Check />
              <p className='ms-4'>{t('reports.filterTop.cumulativeData')}</p>
            </Form>
            <Form className='d-flex ms-md-2'>
              <Form.Check />
              <p className='ms-4'>{t('reports.filterTop.showLiveData')}</p>
            </Form>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end my-2'>
        <button className='btn btn-primary'>
          {t('reports.filterTop.applySelectionBtn')}
        </button>

        <Dropdown>
          <Dropdown.Toggle variant='link' bsPrefix='p-0'>
            <div className='btn bg-gray-400 ms-4'>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='bg-secondary p-1'>
            <Dropdown.Item
              // onClick={() => handleViewPermission(data.id)}
              className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
              {t('reports.filterTop.selectionDropDown.action1')}
            </Dropdown.Item>
            <Dropdown.Item
              // onClick={() => handleEditPermission(data.id)}
              className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
              {t('reports.filterTop.selectionDropDown.action2')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal
        size='xl'
        centered
        show={showModule}
        onHide={() => setShowModule(false)}>
        <div className='p-6  '>
          <div className='mb-8 d-flex justify-content-between'>
            <h3 className='fw-bold fs-2'>Select module and items</h3>
            <FontAwesomeIcon
              size='2x'
              className='text-gray-600'
              icon={faClose}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowModule(false)}
            />
          </div>

          <div className='bg-gray-300 p-4'>
            <h3 className='text-gray-700 fs-5 mb-4'>MODULE</h3>
            <Form>
              <Form.Select
                onChange={(e) => setModuleData(e.target.value)}
                className='w-250px'>
                <option value=''>Please select module</option>
                <option value='1'>Wallet</option>
                <option value='2'>Home Charging</option>
                <option value='3'>Work Charging</option>
                <option value='4'>Public Charging</option>
                <option value='5'>Charge Point Management</option>
                <option value='6'>Loyalty Management</option>
              </Form.Select>
            </Form>
          </div>

          {moduleData && (
            <div className='bg-gray-300 p-4 d-flex '>
              <div className='w-100 '>
                <h3 className='bg-gray-300 text-gray-700 mb-3'>LOCATION</h3>
                <div className=' bg-gray-500 h-200px  d-flex flex-column border border-2 border-gray-600'>
                  {masterChargePointsData.map(
                    (data: IMasterChargePointsData) => (
                      <div key={data.id} className='my-2 bg-hover-white p-2'>
                        <Form className='d-flex'>
                          <Form.Check className='me-2' />
                          {data.name}
                        </Form>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className='w-100'>
                <h3 className='bg-gray-300 text-gray-700 mb-3'>SECTION</h3>
                <div className='bg-gray-500 h-200px  d-flex flex-column border border-2 border-gray-600 '>
                  {masterSectionsData.map((data: IMasterSectionsData) => (
                    <div key={data.id} className='my-2 bg-hover-white p-2'>
                      <Form className='d-flex'>
                        <Form.Check className='me-2' />
                        {data.name}
                      </Form>
                    </div>
                  ))}
                </div>
              </div>
              <div className='w-100'>
                <h3 className='bg-gray-300 text-gray-700 mb-3'>STATION</h3>
                <div className='bg-gray-500 h-200px  d-flex flex-column border border-2 border-gray-600'>
                  {masterStationsData.map((data: IMasterStationData) => (
                    <div key={data.id} className='my-2 bg-hover-white p-2'>
                      <Form className='d-flex'>
                        <Form.Check className='me-2' />
                        {data.name}
                      </Form>
                    </div>
                  ))}
                </div>
              </div>
              <div className='w-100'>
                <h3 className='bg-gray-300 text-gray-700 mb-3'>PLUG</h3>
                <div className='bg-gray-500 h-200px  d-flex flex-column border border-2 border-gray-600 '>
                  {masterChargePointsData.map(
                    (data: IMasterChargePointsData) => (
                      <div key={data.id} className='my-2 bg-hover-white p-2'>
                        <Form className='d-flex'>
                          <Form.Check className='me-2' />
                          {data.pId}
                        </Form>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          <div className='p-4 my-8 bg-gray-300 d-flex'>
            <div className='me-12'>
              <h3 className='text-gray-700 fs-5 mb-4 '>MODULE</h3>
              User selected MODULE
            </div>
            <div>
              <h3 className='text-gray-700 fs-5 mb-4 '>SELECTION</h3>
              User selected SELECTION
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              onClick={() => setShowModule(false)}
              className='btn btn-outline-primary text-decoration-underline me-2'>
              Reset
            </button>
            <button
              onClick={() => setShowModule(false)}
              className='btn btn-primary'>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        size='xl'
        centered
        show={selection}
        onHide={() => setShowSelection(false)}>
        <div className='p-6  '>
          <div className='mb-8 d-flex justify-content-between'>
            <h3 className='fw-bold fs-2'>Select data to show</h3>
            <FontAwesomeIcon
              size='2x'
              className='text-gray-600'
              icon={faClose}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowSelection(false)}
            />
          </div>

          <div className='d-flex'>
            <div className='w-100 bg-gray-300 p-4 me-2 '>
              <h3 className='bg-gray-300 text-gray-700 mb-3'>LOCATION DATA</h3>
              <Form className='mb-4'>
                <Form.Control placeholder='Search' type='text' />
              </Form>
              <div className=' bg-gray-500 h-200px  d-flex flex-column '>
                {filterMockData[0].locationData.map((data: any) => (
                  <div key={data.id} className='my-2 bg-hover-white p-2'>
                    <Form className='d-flex'>
                      <Form.Check className='me-2' />
                      {data.name}
                    </Form>
                  </div>
                ))}
              </div>
            </div>
            <div className='w-100 bg-gray-300 p-4 me-2'>
              <h3 className='bg-gray-300 text-gray-700 mb-3'>
                TRANSACTION DATA
              </h3>
              <Form className='mb-4'>
                <Form.Control placeholder='Search' type='text' />
              </Form>
              <div className='bg-gray-500 h-200px  d-flex flex-column border '>
                {filterMockData[0].tansactionData.map((data: any) => (
                  <div key={data.id} className='my-2 bg-hover-white p-2'>
                    <Form className='d-flex'>
                      <Form.Check className='me-2' />
                      {data.name}
                    </Form>
                  </div>
                ))}
              </div>
            </div>
            <div className='w-100 bg-gray-300 p-4 me-2'>
              <h3 className='bg-gray-300 text-gray-700 mb-3'>SYSTEM DATA</h3>{' '}
              <Form className='mb-4'>
                <Form.Control placeholder='Search' type='text' />
              </Form>
              <div className='bg-gray-500 h-200px  d-flex flex-column border '>
                {filterMockData[0].systemData.map((data: any) => (
                  <div key={data.id} className='my-2 bg-hover-white p-2'>
                    <Form className='d-flex'>
                      <Form.Check className='me-2' />
                      {data.name}
                    </Form>
                  </div>
                ))}
              </div>
            </div>
            <div className='w-100 bg-gray-300 p-4 me-2'>
              <h3 className='bg-gray-300 text-gray-700 mb-3'>
                TRANSACTION DATA
              </h3>{' '}
              <Form className='mb-4'>
                <Form.Control placeholder='Search' type='text' />
              </Form>
              <div className='bg-gray-500 h-200px  d-flex flex-column border '>
                {filterMockData[0].systemData.map((data: any) => (
                  <div key={data.id} className='my-2 bg-hover-white p-2'>
                    <Form className='d-flex'>
                      <Form.Check className='me-2' />
                      {data.name}
                    </Form>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='p-4 my-8 bg-gray-300 d-flex'>
            <div>
              <h3 className='text-gray-700 fs-5 mb-4 '>SELECTION</h3>
              User selected SELECTION
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              onClick={() => setShowSelection(false)}
              className='btn btn-outline-primary text-decoration-underline me-2'>
              Reset
            </button>
            <button
              onClick={() => setShowSelection(false)}
              className='btn btn-primary'>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FilterTop;
