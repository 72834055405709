import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const DisplayGroups = ({ copyData, pagesVisited, contactsPerPage }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleViewAction = (id: number) => {
    navigate(`/managment/contactgroups/view/${id}`);
  };
  const handleEditAction = (id: number) => {
    navigate(`/managment/contactgroups/edit/${id}`);
  };

  return (
    <>
      <Table className='  table table-secondary  table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold border-0 table-borderless border px-1 bg-gray-300 '>
            <Td className='bg-gray-300  text-gray-700'>
              {t('contactGroups.displayGroupsTable.headers.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactGroups.displayGroupsTable.headers.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactGroups.displayGroupsTable.headers.header3')}
            </Td>
          </Tr>
        </Thead>
        <Tbody>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((contact: any) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  className='y text-start border-4 border-gray-300'
                  key={contact.id}>
                  <Td className='bg-gray-500 w-100 w-md-75'>{contact.name}</Td>
                  <Td className='bg-gray-500'>{contact.permission.length}</Td>
                  <Td className='bg-gray-500'>{contact.users.length}</Td>

                  <Td className='bg-gray-500 '>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t(
                          'contactGroups.displayGroupsTable.body.dropDown.title'
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item
                          onClick={() => handleViewAction(contact.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t(
                            'contactGroups.displayGroupsTable.body.dropDown.action1'
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleEditAction(contact.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t(
                            'contactGroups.displayGroupsTable.body.dropDown.action2'
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </>
  );
};

export default DisplayGroups;
