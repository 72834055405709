import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewSection from './createNewSection/CreateNewSection';

const SectionsView = () => {
  const [showModal, setShowModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stationsArray, setStationsArray] = useState([]);
  const { t } = useTranslation();

  return (
    <div className='w-100 p-6  bg-gray-300  '>
      <div className='d-flex justify-content-between'>
        <div>
          <h2 className='fw-bold'>
            {t('createNewLocation.stepperBody.sectionView.name')}
          </h2>
          <h3 className='fs-6 fw-normal'>
            {t('createNewLocation.stepperBody.sectionView.infoText')}
          </h3>
        </div>
        <div>
          <button
            onClick={() => setShowModal(true)}
            className='btn btn-primary'>
            {t('createNewLocation.stepperBody.sectionView.newSectionBtn')}
          </button>
        </div>
      </div>
      <div className='mt-10'>
        <h3 className='bg-gray-300 fs-7 text-gray-700'>
          {t('createNewLocation.stepperBody.sectionView.yourSections')}
        </h3>
        <div className='h-400px bg-gray-400 d-flex flex-column  '>
          {stationsArray
            ? stationsArray.map((station: any) =>
                station?.id === '' ? null : (
                  <div
                    key={station.id}
                    className='d-flex justify-content-between mx-2 mt-2 p-4 bg-light bg-hover-secondary  '>
                    <div>
                      <h3 className='fs-7 text-gray-700'>
                        {t('createNewLocation.stepperBody.sectionView.station')}
                      </h3>
                      <h3>{station.name}</h3>{' '}
                    </div>
                    <div>
                      <button className='btn btn-sm btn-outline-primary border border-1 border-primary'>
                        {t('createNewLocation.stepperBody.sectionView.editBtn')}
                      </button>
                    </div>
                  </div>
                )
              )
            : null}
        </div>
      </div>
      {showModal && (
        <CreateNewSection
          setShowModal={setShowModal}
          showModal={showModal}
          // setNewStation={setNewStation}
          // newStation={newStation}
        />
      )}
    </div>
  );
};

export default SectionsView;
