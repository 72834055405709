import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { removeLocalAuthToken } from '../../../setup/redux/auth-token';
import { api } from '../../../setup/redux/api';
import { bearerTokenSlice } from '../../../setup/redux/slice.bearerToken';

export function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(auth.actions.logout())
    removeLocalAuthToken();
  //@ts-ignore
    dispatch(bearerTokenSlice.actions.set(null));
    dispatch(api.util.resetApiState());
    document.location.reload();
  }, [dispatch]);

  return <Navigate to='/auth/login' />;
}
