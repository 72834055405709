import {
  faShareFromSquare,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import PaginateComponent from '../../../../../components/pagination/PaginateComponent';
import { masterUsersData } from '../../../models/mockData/mockData';
import styles from './CreateNewPermission.module.scss';

const CreateNewPermission = () => {
  const [moduleOption, setModuleOption] = useState('');
  const [show, setShow] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const [selectedCheck, setSelectedCheck] = useState<any[] | null>([]);
  const [searchInModal, setSearchInModal] = useState('');
  const [modalData, setModalData] = useState(masterUsersData);
  const [pageModalNumber, setPageModalNumber] = useState(0);

  const contactsPerPage = 10;
  const { t } = useTranslation();

  const pagesModalVisited = pageModalNumber * contactsPerPage;

  const pageCountModal = Math.ceil(modalData.length / contactsPerPage);

  const pageModalChange = ({ selected }: any) => {
    setPageModalNumber(selected);
  };

  const handleCheck = (object: any, checked: boolean) => {
    if (checked === true) {
      selectedCheck
        ? setSelectedCheck([...selectedCheck, object])
        : setSelectedCheck([object]);
    } else if (checked === false) {
      selectedCheck
        ? setSelectedCheck(selectedCheck?.filter((obj) => obj.id !== object.id))
        : setSelectedCheck(selectedCheck);
    }
  };
  const handleRemoveItem = (id: string) => {
    selectedCheck
      ? setSelectedCheck(selectedCheck.filter((obj) => obj.id !== id))
      : setSelectedCheck(null);
  };

  useEffect(() => {
    const filteredData = masterUsersData.filter((data: any) => {
      return (
        data.email
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase()) ||
        data.phoneNumber
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase())
      );
    });
    if (searchInModal === '') {
      setModalData(masterUsersData);
    } else {
      setModalData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInModal]);

  return (
    <>
      <div>
        <div className='mb-8'>
          <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
            <li className='breadcrumb-item pe-3'>
              <Link to='/dashboard' className='pe-3'>
                {t('breadcrumbs.management')}
              </Link>
            </li>
            <li className='breadcrumb-item pe-3'>
              <Link to='/managment/permissions' className='pe-3'>
                {t('breadcrumbs.permissions')}
              </Link>
            </li>
          </ol>
        </div>
        <h3 className='mb-8'>{t('permission.newPermission.title')}</h3>
        <div className='d-flex flex-column flex-md-row justify-content-between '>
          <div className='w-100 w-md-25 me-4 mb-4 mb-md-0'>
            <div className=' d-flex flex-column  '>
              <div className='bg-gray-300 p-4 mb-4'>
                <h3 className='fw-normal'>
                  {t('permission.newPermission.permissionName')}
                </h3>
                <Form>
                  <Form.Control
                    type='text'
                    placeholder={t('permission.newPermission.permissionName')}
                  />
                </Form>
              </div>
              <div className='bg-gray-300 p-4'>
                <h3 className='text-gray-700 fs-5 my-2'>
                  {t('permission.newPermission.permissionType')}
                </h3>
                <Form>
                  <Form.Select>
                    <option value='Admin'>
                      {t('permission.newPermission.typeSelect.option1')}
                    </option>
                    <option value='User'>
                      {t('permission.newPermission.typeSelect.option1')}
                    </option>
                  </Form.Select>
                </Form>
              </div>
            </div>
          </div>
          <div className='w-100 w-md-75'>
            <div className='bg-gray-300 p-4 mb-4'>
              <div className='d-flex flex-column justify-content-between '>
                <h3 className='fw-normal'>
                  {t('permission.newPermission.selectModule.title')}
                </h3>
                <p>{t('permission.newPermission.selectModule.infoText')}</p>
              </div>
              <div>
                <h3 className='fw-bold fs-6'>
                  {t('permission.newPermission.selectModule.moduleSelection')}
                </h3>
                <Form className='bg-gray-500 p-5'>
                  <Form.Select
                    className='w-100 w-md-50  '
                    onChange={(e) => setModuleOption(e.target.value)}>
                    <option value='Wallet'>
                      {t(
                        'permission.newPermission.selectModule.select.opiton1'
                      )}
                    </option>
                    <option value='Charge points'>
                      {t(
                        'permission.newPermission.selectModule.select.opiton2'
                      )}
                    </option>
                  </Form.Select>
                </Form>
              </div>
            </div>
            {moduleOption && (
              <div className='bg-gray-300 p-4 mb-4'>
                <div>
                  <h3 className='fw-bold fs-6'>Permission details</h3>
                  <h3>Please adjust your {moduleOption} option</h3>
                  <Form className='bg-gray-500 p-5'>
                    <Form.Select className='w-100 w-md-50  '>
                      <option value='wallet'>Option 1</option>
                      <option value='home'>Option 2</option>
                    </Form.Select>
                    <div className='d-flex w-50 justify-content-between my-2'>
                      <Form.Check />
                      <h3>Settings 1</h3>
                    </div>
                    <div className='d-flex w-50 justify-content-between my-2'>
                      <Form.Check />
                      <h3>Settings 2</h3>
                    </div>
                    <div className='d-flex w-50 justify-content-between my-2'>
                      <Form.Switch />
                      <h3>Settings 3</h3>
                    </div>
                    <div className='d-flex w-50 justify-content-between my-2'>
                      <Form.Switch />
                      <h3>Settings 4</h3>
                    </div>
                    <div className='d-flex w-50 justify-content-between my-2'>
                      <Form.Control type='color' />
                      <h3>Permission color</h3>
                    </div>
                  </Form>
                </div>
              </div>
            )}
            <div className='bg-gray-300 p-4'>
              <div className='d-flex flex-column flex-md-row justify-content-between '>
                <div className='d-flex flex-column justify-content-between w-100 w-md-50'>
                  <h3 className='text-gray-700 fs-5 my-2'>
                    {t(
                      'permission.newPermission.assignContactOrGroup.optional'
                    )}
                  </h3>
                  <h3 className='fw-normal'>
                    {t('permission.newPermission.assignContactOrGroup.title')}
                  </h3>
                  <p>
                    {t(
                      'permission.newPermission.assignContactOrGroup.infoText'
                    )}
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => setShow(true)}
                    className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white text-primary me-4  '>
                    {t(
                      'permission.newPermission.assignContactOrGroup.assignBtn'
                    )}
                  </button>
                </div>
              </div>
              <div>
                <h3 className='text-gray-700 fs-5 my-2'>
                  {t('permission.newPermission.assignContactOrGroup.selected')}
                </h3>
                <div className='bg-white p-4'>
                  <p>
                    {' '}
                    {t(
                      'permission.newPermission.assignContactOrGroup.selectFrom'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='bg-gray-300 p-4'>
              <div className='d-flex justify-content-between'></div>
            </div>
            <div className='d-flex  justify-content-end mt-8'>
              <button
                // onClick={handleCancelClick}
                className='btn text-primary text-decoration-underline me-2'>
                {t('permission.newPermission.cancelBtn')}
              </button>

              <button
                // onClick={handleCancelClick}
                type='submit'
                className='btn btn-primary'>
                {t('permission.newPermission.saveBtn')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={styles.rightSideModal}
        show={show}
        size='xl'
        onHide={() => setShow(false)}>
        <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100 '>
          <h2>{t('permission.newPermission.modal.assignContacts')}</h2>
          <button
            onClick={() => setShow(false)}
            className='btn btn-outline-primary border border-2 border-primary me-4 d-flex align-items-center'>
            {t('permission.newPermission.modal.closeBtn')}{' '}
            <FontAwesomeIcon className='ms-2' icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          {showHint && (
            <div
              style={{ background: '#FFF4CF' }}
              className=' d-flex flex-column p-8 fs-7 mb-8'>
              <h4 className='fw-bolder fs-7'>
                {t('permission.newPermission.modal.hint')}
              </h4>
              <h6 className='fw-normal fs-7'>
                {t('permission.newPermission.modal.hint2')}{' '}
                <span className='fw-bolder'>
                  {t('permission.newPermission.modal.boldHint')}
                </span>{' '}
                {t('permission.newPermission.modal.hint3')}
              </h6>

              <div className='d-flex justify-content-between'>
                <Link
                  className='mt-2 text-primary text-decoration-underline fs-9'
                  to='/managment/contactgroups'>
                  {t('permission.newPermission.modal.editContactsBtn')}
                  <FontAwesomeIcon className='ms-1' icon={faShareFromSquare} />
                </Link>

                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowHint(false)}
                  className='mt-2 text-primary text-decoration-underline fs-9'>
                  {t(
                    'permission.newPermission.modal.editContactdontShowBtnsBtn'
                  )}
                </p>
              </div>
            </div>
          )}
          <h3 className='text-gray-700 fs-5 mb-3'>
            {t('permission.newPermission.modal.selected')}
          </h3>
          <div className='text-center bg-gray-300 p-8 fs-7 '>
            {selectedCheck &&
              selectedCheck.map((objekt) => (
                <div
                  key={objekt.id}
                  className={clsx(
                    objekt.name ? 'bg-gray-200' : 'bg-gray-500',
                    'my-2  p-4 d-flex text-start justify-content-between '
                  )}>
                  <div>
                    <p className='text-gray-700 fw-bold'>
                      {objekt.email ? 'CONTACTS' : 'USER GROUP'}
                    </p>
                    <h4 className='fs-6 '>{objekt.email}</h4>

                    {/* {data.name && (
                          <p className='text-gray-700 fw-bold'>
                            {data.name.length} users in this group
                          </p>
                        )} */}
                    {objekt.phoneNumber && (
                      <p className='text-black fw-light'>
                        {objekt.phoneNumber}
                      </p>
                    )}
                  </div>
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveItem(objekt.id)}
                    icon={faTrash}
                  />
                </div>
              ))}
            {selectedCheck?.length === 0 && (
              <p>{t('permission.newPermission.modal.selectHint')}</p>
            )}
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('permission.newPermission.modal.searchCapital')}
          </h3>
          <div>
            <Form>
              <Form.Control
                onChange={(e) => setSearchInModal(e.target.value)}
                type='text'
                placeholder={t('permission.newPermission.modal.search')}
              />
            </Form>
          </div>
          <h3 className='text-gray-700 fs-5 my-10'>
            {t('permission.newPermission.modal.yourContactsAndGroups')}
          </h3>
          <div className=' bg-gray-300 p-8 fs-7'>
            {modalData
              .slice(pagesModalVisited, pagesModalVisited + contactsPerPage)
              .map((data) => {
                return (
                  <div
                    key={data.id}
                    className={clsx(
                      data.email ? 'bg-gray-500' : 'bg-gray-200',
                      'my-2  p-4 d-flex justify-content-start '
                    )}>
                    <Form>
                      <Form.Check
                        onChange={(e) => handleCheck(data, e.target.checked)}
                        className='me-2'
                      />
                    </Form>

                    <div>
                      <p className='text-gray-700 fw-bold'>
                        {data.email
                          ? t('permission.newPermission.modal.contact')
                          : t('permission.newPermission.modal.group')}
                      </p>
                      <h4 className='fs-6 '>{data.email}</h4>

                      {/* {data.name && (
                          <p className='text-gray-700 fw-bold'>
                            {data.name.length} users in this group
                          </p>
                        )} */}
                      {data.phoneNumber && (
                        <p className='text-black fw-light'>
                          {data.phoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            <div className='d-flex justify-content-end'>
              <PaginateComponent
                pageCount={pageCountModal}
                pageChange={pageModalChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0 '>
          <div>
            {/*todo Save selection */}
            <button
              onClick={() => setShow(false)}
              className='d-flex justify-content-end btn btn-primary '>
              {t('permission.newPermission.modal.saveBtn')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateNewPermission;
