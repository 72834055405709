import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IMasterUserData } from '../../../../models/interfaces';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useTranslation } from 'react-i18next';

interface IProps {
  copyData: IMasterUserData[];
  pagesVisited: number;
  contactsPerPage: number;
  contacts: any[];
}

const DisplayContacts = ({
  contacts,
  copyData,
  pagesVisited,
  contactsPerPage,
}: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditAction = (id: number, value: string) => {
    if (value === 'view') {
      navigate(`/managment/contactlist/edit/${id}`);
    }
    //todo add logic for removing if one of the action is delete
  };

  return (
    <>
      <Table className=' table table-secondary table-responsiv table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold  border-0 px-1 bg-gray-300  '>
            <Td className='bg-gray-300 text-gray-700 mw-50px '>
              {t('contactList.displayContacts.headers.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactList.displayContacts.headers.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactList.displayContacts.headers.header3')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('contactList.displayContacts.headers.header4')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'></Td>
          </Tr>
        </Thead>
        <Tbody>
          {contacts.length === 0
            ? null
            : contacts.map((obj: any, i: number) => (
                <div key={i} className='bg-gray-300'>
                  <p className='bg-danger'>!Stringify import visual only !</p>
                  <p>{JSON.stringify(obj)}</p>
                </div>
              ))}

          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((contact: any) => {
              return (
                <Tr
                  key={contact.id}
                  style={{ borderBottomStyle: 'solid' }}
                  className='text-start border-4 border-gray-300  '>
                  <Td className='bg-gray-500'>{contact.email}</Td>
                  <Td className='bg-gray-500'>{contact.phoneNumber}</Td>
                  <Td className='bg-gray-500'>{contact.note}</Td>
                  <Td className='bg-gray-500'>{contact.permission.length}</Td>
                  <Td className='bg-gray-500 '>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t('contactList.displayContacts.dropDown.title')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item
                          onClick={() => handleEditAction(contact.id, 'view')}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t('contactList.displayContacts.dropDown.action1')}
                        </Dropdown.Item>
                        <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t('contactList.displayContacts.dropDown.action2')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </>
  );
};

export default DisplayContacts;
