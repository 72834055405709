import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LocationsView from './lineTabs/locations/LocationsView';
import SectionsView from './lineTabs/sections/SectionsView';
import StationsView from './lineTabs/stations/StationsView';
import { useGetAllChargeStationQuery } from '../../../../setup/redux/apiChargePointGenerated';

const ChargingPointView = () => {
  const [navMenu, setNavMenu] = useState('locations');
  const { t } = useTranslation();

  const { data } = useGetAllChargeStationQuery({ pageable: {} });
  console.log(data);

  const handleClick = (value: string) => {
    setNavMenu(value);
  };

  return (
    <>
      <div>
        <div className='d-flex justify-content-between'>
          <div>
            <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
              <li className='breadcrumb-item pe-3'>
                <Link to='/dashboard' className='pe-3'>
                  {t('breadcrumbs.chargingManagement')}
                </Link>
              </li>
            </ol>

            <nav>
              <ul className='d-flex list-unstyled'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'locations'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('locations')}>
                  {t('chargePoints.locations.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'sections'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('sections')}>
                  {t('chargePoints.sections.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'stations'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('stations')}>
                  {t('chargePoints.stations.name')}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='bg-gray-300 p-4'>
          {navMenu === 'locations' && <LocationsView />}
          {navMenu === 'sections' && <SectionsView />}
          {navMenu === 'stations' && <StationsView />}
        </div>
      </div>
    </>
  );
};

export default ChargingPointView;
