import ReactDOM from 'react-dom';
// Redux
// https://github.com/rt2zz/redux-persist
import { Provider } from 'react-redux';
import * as _redux from './setup';
import store from './setup/redux/Store';
// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';

// Apps

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import { AppRoutes } from './app/routing/AppRoutes';

import i18n from './setup/i18n/i18n';
import { I18nextProvider } from 'react-i18next';
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

Chart.register(...registerables);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <AppRoutes />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
