import clsx from 'clsx';
import { useState } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import {
  IMasterGroupsData,
  IMasterUserData,
} from '../../../../../models/interfaces';
import {
  masterGroupsData,
  masterUsersData,
} from '../../../../../models/mockData/mockData';

interface IProps {
  initialValues: IMasterGroupsData;
  contactUsers: IMasterUserData[];
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
}

const GroupUsers = ({ initialValues, contactUsers, setSearchBy }: IProps) => {
  const [show, setShow] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const [pageNumber, setPageNumber] = useState(0);

  const { t } = useTranslation();
  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(contactUsers.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const navigate = useNavigate();

  const handleOpenModal = (data: IMasterUserData) => {
    setSelectedGroup(data);
    setShow(true);
  };

  const handleEditContact = (data: IMasterUserData) => {
    const activeContact = masterUsersData.filter(
      (contact) => contact.id === data.id
    );

    navigate(`/managment/contactlist/edit/${activeContact[0].id}`);
  };
  const viewSelectedGroup = (data: IMasterGroupsData) => {
    const activeGroup = masterGroupsData.filter(
      (group) => group.id === data.id
    );
    navigate(`/managment/contactgroups/view/${activeGroup[0].id}`);
    setShow(false);
  };
  const handleEditGroup = (data: IMasterGroupsData) => {
    navigate(`/managment/contactgroups/view/${data.id}`);
  };

  return (
    <>
      <div className='bg-gray-300 '>
        <div className='d-flex flex-wrap justify-content-between'>
          <Form>
            <Form.Control
              className='w-250px mb-4 mb-md-0 '
              type='text'
              placeholder={t(
                'contactGroups.groupView.assignedPermissions.search'
              )}
              defaultValue=''
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </Form>
          <div className='d-flex flex-column align-items-end'>
            <h2 className='text-gray-700 fs-3'>
              {initialValues.users.length}{' '}
              {initialValues.users.length < 2
                ? t('contactGroups.groupView.contact')
                : t('contactGroups.groupView.contacts')}
            </h2>
            {initialValues?.subGroups?.length !== 0 ? (
              <h2 className='text-gray-700 fs-3'>
                {t('contactGroups.groupView.and')}{' '}
                {initialValues.subGroups?.length}{' '}
                {initialValues.subGroups?.length === 1
                  ? t('contactGroups.groupView.group')
                  : t('contactGroups.groupView.groups')}
              </h2>
            ) : (
              ''
            )}
            {/**TODO  action for showing contacts */}
            {/* <p className='text-primary fs-9 text-decoration-underline'>
              Show all contacts
            </p> */}
          </div>
        </div>
        {initialValues.subGroups &&
          initialValues.subGroups.map((group) => (
            <div
              key={group.id}
              className={clsx(
                ' bg-gray-200 my-2  p-4 d-flex flex-column flex-md-row justify-content-between'
              )}>
              <div>
                <p className='text-gray-700 fw-bold'>
                  {t('contactGroups.groupView.users.userGrp')}
                </p>
                <h4>{group.name}</h4>
              </div>

              <Dropdown>
                <Dropdown.Toggle
                  variant='outlined-primary'
                  className=' bg-white p-4 '>
                  {t('contactGroups.groupView.dropDownGrp.title')}
                </Dropdown.Toggle>
                <Dropdown.Menu className='bg-secondary p-1'>
                  <Dropdown.Item
                    onClick={() => handleEditGroup(group)}
                    className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                    {t('contactGroups.groupView.dropDownGrp.action1')}
                  </Dropdown.Item>
                  <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                    {t('contactGroups.groupView.dropDownGrp.action2')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ))}
        {contactUsers
          .slice(pagesVisited, pagesVisited + contactsPerPage)
          .map((data) => {
            return (
              <div
                key={data.id}
                className={clsx(
                  data.email ? 'bg-gray-500' : 'bg-gray-200',
                  'my-2  p-4 d-flex flex-column flex-md-row justify-content-between'
                )}>
                <div>
                  <p className='text-gray-700 fw-bold'>
                    {data.email
                      ? t('contactGroups.groupView.users.contact')
                      : t('contactGroups.groupView.users.userGrp')}
                  </p>
                  <h4>{data.email}</h4>
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOpenModal(data)}
                    className={clsx(
                      'text-primary fs-9 text-decoration-underline w-50'
                    )}>
                    {data &&
                      `${t('contactGroups.groupView.users.partOf')} ${
                        data?.groups?.length
                      } ${t('contactGroups.groupView.users.groups')}`}
                  </p>
                </div>

                <Dropdown>
                  <Dropdown.Toggle
                    variant='outlined-primary'
                    className=' bg-white p-4 '>
                    {t('contactGroups.groupView.dropDownContacts.title')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='bg-secondary p-1'>
                    <Dropdown.Item
                      onClick={() => handleEditContact(data)}
                      className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                      {t('contactGroups.groupView.dropDownContacts.action1')}
                    </Dropdown.Item>
                    <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                      {t('contactGroups.groupView.dropDownContacts.action2')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          })}
        {selectedGroup && (
          <Modal size='xl' centered show={show} onHide={() => setShow(false)}>
            <Modal.Header className='border-0' closeButton>
              <Modal.Title>{selectedGroup?.email}</Modal.Title>
            </Modal.Header>
            {selectedGroup.groups &&
              selectedGroup.groups.map((group: any) => (
                <Modal.Body key={group.id} className='p-0 m-0 '>
                  <div className='d-flex justify-content-between mx-6 my-2 p-4 bg-gray-200'>
                    <div>
                      <p className='text-gray-700 fw-bold'>
                        {t('contactGroups.groupView.users.modal.userGrp')}
                      </p>
                      <h4>{group.name}</h4>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t(
                          'contactGroups.groupView.users.modal.dropDownGrp.title'
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item
                          onClick={() => viewSelectedGroup(group)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t(
                            'contactGroups.groupView.users.modal.dropDownGrp.action1'
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t(
                            'contactGroups.groupView.users.modal.dropDownGrp.action2'
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Modal.Body>
              ))}
            <Modal.Body />
          </Modal>
        )}
        <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
      </div>
    </>
  );
};

export default GroupUsers;
