import {
  faShareFromSquare,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import {
  IMasterGroupsData,
  IMasterUserData,
} from '../../../../../models/interfaces';
import { masterUsersData } from '../../../../../models/mockData/mockData';
import styles from './EditGroupUsers.module.scss';

interface IProps {
  initialValues: any;
  contactUsers: any[];

  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
}

const GroupUsers = ({ initialValues, contactUsers, setSearchBy }: IProps) => {
  const [show, setShow] = useState(false);
  const [searchInModal, setSearchInModal] = useState('');
  const [modalData, setModalData] = useState(masterUsersData);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageModalNumber, setPageModalNumber] = useState(0);
  const [showHint, setShowHint] = useState(true);
  const [selectedCheck, setSelectedCheck] = useState<any[] | null>([]);
  const navigate = useNavigate();

  const { t } = useTranslation();

  //! double paginatition //
  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;
  const pagesModalVisited = pageModalNumber * contactsPerPage;

  const pageCount = Math.ceil(contactUsers.length / contactsPerPage);
  const pageCountModal = Math.ceil(modalData.length / contactsPerPage);

  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const pageModalChange = ({ selected }: any) => {
    setPageModalNumber(selected);
  };
  //! double paginatition //

  const handleCheck = (object: any, checked: boolean) => {
    if (checked === true) {
      selectedCheck
        ? setSelectedCheck([...selectedCheck, object])
        : setSelectedCheck([object]);
    } else if (checked === false) {
      selectedCheck
        ? setSelectedCheck(selectedCheck?.filter((obj) => obj.id !== object.id))
        : setSelectedCheck(selectedCheck);
    }
  };

  const handleRemoveItem = (id: string) => {
    selectedCheck
      ? setSelectedCheck(selectedCheck.filter((obj) => obj.id !== id))
      : setSelectedCheck(null);
  };

  useEffect(() => {
    const filteredData = masterUsersData.filter((data: any) => {
      return (
        data.email
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase()) ||
        data.phoneNumber
          .toString()
          .toLowerCase()
          .includes(searchInModal.toLowerCase())
      );
    });
    if (searchInModal === '') {
      setModalData(masterUsersData);
    } else {
      setModalData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInModal]);
  const handleEditGroup = (data: IMasterGroupsData) => {
    navigate(`/managment/contactgroups/view/${data.id}`);
  };

  return (
    <>
      <div className='bg-gray-300 '>
        <div className='d-flex flex-wrap justify-content-between py-4'>
          <Form>
            <Form.Control
              className='w-250px mb-4 mb-md-0 '
              type='text'
              placeholder={t('contactGroups.groupEdit.users.search')}
              defaultValue=''
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </Form>
          <div className='d-flex flex-column align-items-end'>
            <button onClick={() => setShow(true)} className='btn btn-primary'>
              {t('contactGroups.groupEdit.users.addBtn')}
            </button>
          </div>
        </div>
        {initialValues.subGroups &&
          initialValues.subGroups.map((group: IMasterGroupsData) => (
            <div
              key={group.id}
              className={clsx(
                ' bg-gray-200 my-2  p-4 d-flex flex-column flex-md-row justify-content-between'
              )}>
              <div className='d-flex'>
                <Form>
                  <Form.Check className='me-2' />
                </Form>
                <div>
                  <p className='text-gray-700 fw-bold'>
                    {t('contactGroups.groupEdit.users.userGrp')}
                  </p>
                  <h4>{group.name}</h4>
                </div>
              </div>

              <Dropdown>
                <Dropdown.Toggle
                  variant='outlined-primary'
                  className=' bg-white p-4 '>
                  {t('contactGroups.groupEdit.users.dropDown.title')}
                </Dropdown.Toggle>
                <Dropdown.Menu className='bg-secondary p-1'>
                  <Dropdown.Item
                    onClick={() => handleEditGroup(group)}
                    className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                    {t('contactGroups.groupEdit.users.dropDown.action1')}
                  </Dropdown.Item>
                  <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                    {t('contactGroups.groupEdit.users.dropDown.action2')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ))}
        {contactUsers
          .slice(pagesVisited, pagesVisited + contactsPerPage)
          .map((data: IMasterUserData) => {
            return (
              <div
                key={data.id}
                className={clsx(
                  data.email ? 'bg-gray-500' : 'bg-gray-200',
                  'my-2  p-4 d-flex flex-column  flex-md-row justify-content-between'
                )}>
                <div className='d-flex align-items-start'>
                  <Form>
                    <Form.Check className='me-2' />
                  </Form>
                  <div>
                    <p className='text-gray-700 fw-bold'>
                      {data.email
                        ? t('contactGroups.groupEdit.users.contact')
                        : t('contactGroups.groupEdit.users.userGrp')}
                    </p>
                    <h4 className='fs-4 fs-md-2'>{data.email}</h4>
                  </div>
                </div>

                <Dropdown>
                  <Dropdown.Toggle
                    variant='outlined-primary'
                    className=' bg-white p-4 '>
                    {t('contactGroups.groupEdit.users.dropDown.title')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='bg-secondary p-1'>
                    <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                      {t('contactGroups.groupEdit.users.dropDown.action1')}
                    </Dropdown.Item>
                    <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                      {t('contactGroups.groupEdit.users.dropDown.action2')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          })}
        <Modal
          className={styles.rightSideModal}
          show={show}
          size='xl'
          onHide={() => setShow(false)}>
          <Modal.Header className='d-flex justify-content-between align-items-center p-4 border-0 w-100 '>
            <h2>{t('contactGroups.groupEdit.users.modal.userGrp')}</h2>
            <button
              onClick={() => setShow(false)}
              className='btn btn-outline-primary border border-2 border-primary me-4 d-flex align-items-center'>
              {t('contactGroups.groupEdit.users.modal.closeBtn')}{' '}
              <FontAwesomeIcon className='ms-2' icon={faXmark} />
            </button>
          </Modal.Header>
          <Modal.Body>
            {showHint && (
              <div
                style={{ background: '#FFF4CF' }}
                className=' d-flex flex-column p-8 fs-7 mb-8'>
                <h4 className='fw-normal fs-7'>
                  {t('contactGroups.groupEdit.users.modal.hint')}
                </h4>

                <div className='d-flex justify-content-between'>
                  <Link
                    className='mt-2 text-primary text-decoration-underline fs-9'
                    to='/managment/contactlist'>
                    {t('contactGroups.groupEdit.users.modal.editPermissionBtn')}
                    <FontAwesomeIcon
                      className='ms-1'
                      icon={faShareFromSquare}
                    />
                  </Link>

                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowHint(false)}
                    className='mt-2 text-primary text-decoration-underline fs-9'>
                    {t('contactGroups.groupEdit.users.modal.dontShowBtn')}
                  </p>
                </div>
              </div>
            )}
            <h3 className='text-gray-700 fs-5 mb-3'>
              {t('contactGroups.groupEdit.users.modal.selected')}
            </h3>
            <div className='text-center bg-gray-300 p-8 fs-7 '>
              {selectedCheck &&
                selectedCheck.map((objekt) => (
                  <div
                    key={objekt.id}
                    className={clsx(
                      objekt.name ? 'bg-gray-200' : 'bg-gray-500',
                      'my-2  p-4 d-flex text-start justify-content-between '
                    )}>
                    <div>
                      <p className='text-gray-700 fw-bold'>
                        {objekt.email
                          ? t('contactGroups.groupEdit.users.contact')
                          : t('contactGroups.groupEdit.users.userGrp')}
                      </p>
                      <h4 className='fs-6 w-50 '>{objekt.email}</h4>

                      {objekt.name && <h4 className='fs-6'>{objekt.name}</h4>}

                      {objekt.name && (
                        <p className='text-gray-700 fw-bold'>
                          {objekt.name.length}{' '}
                          {t('contactGroups.groupEdit.users.usersInGrp')}
                        </p>
                      )}
                    </div>
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRemoveItem(objekt.id)}
                      icon={faTrash}
                    />
                  </div>
                ))}
              {selectedCheck?.length === 0 && (
                <p>{t('contactGroups.groupEdit.users.selectItems')}</p>
              )}
            </div>
            <h3 className='text-gray-700 fs-5 my-10'>
              {t('contactGroups.groupEdit.users.searchCapital')}
            </h3>
            <div>
              <Form>
                <Form.Control
                  onChange={(e) => setSearchInModal(e.target.value)}
                  type='text'
                  placeholder={t('contactGroups.groupEdit.users.search')}
                />
              </Form>
            </div>
            <h3 className='text-gray-700 fs-5 my-10'>
              {t('contactGroups.groupEdit.users.yourContactsAndGroups')}
            </h3>
            <div className=' bg-gray-300 p-8 fs-7'>
              {initialValues.subGroups &&
                initialValues.subGroups.map((group: IMasterGroupsData) => (
                  <div
                    key={group.id}
                    className={clsx(
                      ' bg-gray-200 my-2  p-4 d-flex flex-column flex-md-row justify-content-between'
                    )}>
                    <div className='d-flex'>
                      <Form>
                        <Form.Check
                          className='me-2'
                          onChange={(e) => handleCheck(group, e.target.checked)}
                        />
                      </Form>
                      <div>
                        <p className='text-gray-700 fw-bold'>
                          {t('contactGroups.groupEdit.users.userGrp')}
                        </p>
                        <h4>{group.name}</h4>

                        <p className='text-gray-700 fw-bold'>
                          {group.name.length}{' '}
                          {t('contactGroups.groupEdit.users.usersInGrp')}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              {modalData
                .slice(pagesModalVisited, pagesModalVisited + contactsPerPage)
                .map((data) => {
                  return (
                    <div
                      key={data.id}
                      className={clsx(
                        'my-2 bg-gray-500  p-4 d-flex justify-content-start '
                      )}>
                      <Form>
                        <Form.Check
                          onChange={(e) => handleCheck(data, e.target.checked)}
                          className='me-2'
                        />
                      </Form>

                      <div>
                        <p className='text-gray-700 fw-bold'>
                          {t('contactGroups.groupEdit.users.contact')}
                        </p>
                        <h4 className='fs-7  '>{data.email}</h4>

                        {data.phoneNumber && (
                          <p className='text-black fw-light'>
                            {data.phoneNumber}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              <div className='d-flex justify-content-end'>
                <PaginateComponent
                  pageCount={pageCountModal}
                  pageChange={pageModalChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='border-0 '>
            <div>
              {/*todo Save selection */}
              <button
                onClick={() => setShow(false)}
                className='d-flex justify-content-end btn btn-primary '>
                {t('contactGroups.groupEdit.users.saveSelectionBtn')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
      </div>
    </>
  );
};

export default GroupUsers;
