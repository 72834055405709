import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Table, Tbody, Td, Thead, Tr } from 'react-super-responsive-table';
import CsvExport from '../../../../../../../components/csv/csvExport/CsvExport';
import PaginateComponent from '../../../../../../../components/pagination/PaginateComponent';
import { IMasterLogsData } from '../mockData/interfaces';
import { stationLogsData } from '../mockData/stationViewMockData';

const StationLogs = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [plugSelect, setPlugSelect] = useState('');
  const [typeSelect, setTypeSelect] = useState('');
  const [searchBy, setSearchBy] = useState(plugSelect || typeSelect);

  const [copyData, setCopyData] = useState<IMasterLogsData[]>(stationLogsData);

  const { t } = useTranslation();
  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filteredData = stationLogsData.filter((data: IMasterLogsData) => {
      return (
        data.type.toString().toLowerCase().includes(typeSelect.toLowerCase()) &&
        data.plug.toString().toLowerCase().includes(plugSelect.toLowerCase()) &&
        (data.plug.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
          data.name.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
          data.type.toString().toLowerCase().includes(searchBy.toLowerCase()))
      );
    });

    setCopyData(filteredData);
  }, [searchBy, plugSelect, typeSelect]);

  return (
    <div>
      <Form className='d-flex justify-content-between mb-10'>
        <div>
          <h3 className='text-gray-700'>
            {t('stationDetail.logs.type.title')}
          </h3>
          <Form.Control
            onChange={(e) => setSearchBy(e.target.value)}
            placeholder='Search'
          />
        </div>
        <div className='d-flex'>
          <div className='d-flex'>
            <div className='me-1'>
              <h3 className='text-gray-700'>
                {' '}
                {t('stationDetail.logs.type.title')}
              </h3>

              <Form.Select onChange={(e) => setTypeSelect(e.target.value)}>
                <option value=''>{t('stationDetail.logs.type.option1')}</option>
                <option value='transaction'>
                  {t('stationDetail.logs.type.option2')}
                </option>
                <option value='system'>
                  {t('stationDetail.logs.type.option3')}
                </option>
                <option value='edit'>
                  {t('stationDetail.logs.type.option4')}
                </option>
              </Form.Select>
            </div>
            <div className='me-1'>
              <h3 className='text-gray-700'>
                {' '}
                {t('stationDetail.logs.type.title')}
              </h3>

              <Form.Select onChange={(e) => setPlugSelect(e.target.value)}>
                <option value=''>{t('stationDetail.logs.plug.option1')}</option>
                <option value='plug A'>
                  {t('stationDetail.logs.plug.option2')}
                </option>
                <option value='charger'>
                  {t('stationDetail.logs.plug.option3')}
                </option>
                <option value='station'>
                  {t('stationDetail.logs.plug.option4')}
                </option>
              </Form.Select>
            </div>
            <div className='me-1'>
              <h3 className='text-gray-700'>
                {t('stationDetail.logs.range.title')}
              </h3>

              <Form.Select>
                <option value=''>
                  {t('stationDetail.logs.range.option1')}
                </option>
                <option value=''>
                  {t('stationDetail.logs.range.option2')}
                </option>
                <option value=''>
                  {t('stationDetail.logs.range.option3')}
                </option>

                <option value=''>
                  {t('stationDetail.logs.range.option4')}
                </option>
              </Form.Select>
            </div>
          </div>
          <div className='d-flex align-items-end ms-4'>
            <CsvExport
              data={copyData}
              filename='Station logs'
              className='btn btn-primary'
              title={t('stationDetail.logs.exportBtn')}
              icon={faAngleDown}
            />
          </div>
        </div>
      </Form>
      <Table className=' table table-secondary   table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold px-1 bg-gray-300 border-0 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.logs.tableHeaders.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.logs.tableHeaders.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.logs.tableHeaders.header3')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('stationDetail.logs.tableHeaders.header4')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'></Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: IMasterLogsData) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  key={data.id}
                  className=' text-start border-4 border-gray-300 roun'>
                  <Td className='bg-gray-500  fw-bolder align-baseline   '>
                    <p
                      className={clsx(
                        'text-white fs-8 p-2 rounded rounded-3 text-center',
                        data.type === 'TRANSACTION'
                          ? 'bg-gray-700'
                          : data.type === 'SYSTEM'
                          ? 'bg-dark'
                          : 'bg-info'
                      )}>
                      {data.type}
                    </p>
                  </Td>
                  <Td className='bg-gray-500 align-baseline   '>{data.name}</Td>
                  <Td className='bg-gray-500 align-baseline '>{data.plug}</Td>
                  <Td className='bg-gray-500 align-baseline '>
                    {data.startAt}
                  </Td>

                  <Td className='bg-gray-500 align-baseline '>
                    <button className='btn btn-outline-primary border border-1 border-primary bg-white bg-hover-primary text-hover-white'>
                      {t('stationDetail.logs.tableBody.detailBtn')}
                    </button>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>

      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </div>
  );
};

export default StationLogs;
