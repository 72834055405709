import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DetailsView = () => {
  //todo what category? translation
  const { t } = useTranslation();
  return (
    <div className='w-100 p-6  bg-gray-300   '>
      <div className='m-4 '>
        <h2 className='fw-bold'>
          {t('createNewLocation.stepperBody.detailsVew.name')}
        </h2>
        <h3 className='fs-6 fw-normal'>
          {t('createNewLocation.stepperBody.detailsVew.infoText')}
        </h3>
        <Form>
          <div className='mb-4 mt-8'>
            <h3 className='text-gray-700 fs-5 my-2'>
              {' '}
              {t('createNewLocation.stepperBody.detailsVew.form.nameCapital')}
            </h3>
            <Form.Control
              type='text'
              placeholder={t(
                'createNewLocation.stepperBody.detailsVew.form.name'
              )}
            />
          </div>
          <div className='d-flex justify-content-between my-4'>
            <div className='w-75 me-5'>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t(
                  'createNewLocation.stepperBody.detailsVew.form.addressCapital'
                )}
              </h3>
              <Form.Control
                type='text'
                placeholder={t(
                  'createNewLocation.stepperBody.detailsVew.form.address'
                )}
              />
            </div>
            <div className='w-25'>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('createNewLocation.stepperBody.detailsVew.form.zipCapital')}
              </h3>
              <Form.Control
                type='text'
                placeholder={t(
                  'createNewLocation.stepperBody.detailsVew.form.zip'
                )}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between my-4'>
            <div className='w-50 me-5'>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t(
                  'createNewLocation.stepperBody.detailsVew.form.selectCityBtn.name'
                )}
              </h3>
              <Form.Select>
                <option value=''>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.selectCityBtn.title'
                  )}
                </option>
                <option value=''>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.selectCityBtn.option1'
                  )}
                </option>
              </Form.Select>
            </div>
            <div className='w-50'>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t(
                  'createNewLocation.stepperBody.detailsVew.form.selectCountryBtn.name'
                )}
              </h3>
              <Form.Select>
                <option value=''>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.selectCountryBtn.title'
                  )}
                </option>
                <option value=''>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.selectCountryBtn.option1'
                  )}
                </option>
                <option value=''>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.selectCountryBtn.option2'
                  )}
                </option>
              </Form.Select>
            </div>
          </div>
          <div className='mt-8'>
            <h2 className='fw-bold'>
              {t('createNewLocation.stepperBody.detailsVew.form.category')}
            </h2>
            <h3 className='fs-6 fw-normal'>
              {t('createNewLocation.stepperBody.detailsVew.form.categoryInfo')}
            </h3>
            <div className='mt-8'>
              <div className='d-flex my-4'>
                <Form.Check type='radio' />
                <h3 className='fs-6 fw-normal ms-4'>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.categoryOption.option1'
                  )}
                </h3>
              </div>
              <div className='d-flex my-4'>
                <Form.Check type='radio' />
                <h3 className='fs-6 fw-normal ms-4'>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.categoryOption.option2'
                  )}
                </h3>
              </div>
              <div className='d-flex my-4'>
                <Form.Check type='radio' />
                <h3 className='fs-6 fw-normal ms-4'>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.categoryOption.option3'
                  )}
                </h3>
              </div>
              <div className='d-flex my-4'>
                <Form.Check type='radio' />
                <h3 className='fs-6 fw-normal ms-4'>
                  {t(
                    'createNewLocation.stepperBody.detailsVew.form.categoryOption.option4'
                  )}
                </h3>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DetailsView;
