import React from 'react';
import { mockData1, mockData2, mockData3 } from './mockDataCharts/chartsMock';
import { Line } from 'react-chartjs-2';

const ChartsView = () => {
  const state = {
    labels: mockData1.map((data) => data.month),
    datasets: [
      {
        label: 'Consumption Year 2022',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#73168f',
        borderColor: '#73168f',
        borderWidth: 3,
        data: mockData1.map((data) => data.consumption),
      },
      {
        label: 'Consumption Year 2021',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#d4c81b',
        borderColor: '#6ce946',
        borderWidth: 3,
        data: mockData2.map((data) => data.consumption),
      },
      {
        label: 'Consumption Year 2020',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#d4c81b',
        borderColor: '#ad1428',
        borderWidth: 3,
        data: mockData3.map((data) => data.consumption),
      },
    ],
  };
  const options = {
    responsive: true,

    animation: {
      duration: 5000,
    },
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: 'Consumption chart',
      },
    },
  };

  return (
    <div>
      <Line data={state} options={options} />
    </div>
  );
};

export default ChartsView;
