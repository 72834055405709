import clsx from 'clsx';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const PermissionsTable = ({ permissionsData, handlePermissionChange }: any) => {
  const { t } = useTranslation();
  return (
    <div>
      <Table className=' table table-secondary  table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold  border-0 px-1 bg-gray-300 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionTable.thead.header1'
              )}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {' '}
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionTable.thead.header2'
              )}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {' '}
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionTable.thead.header3'
              )}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {' '}
              {t(
                'contactList.editContactList.lineTabs.permissions.permissionTable.thead.header4'
              )}
            </Td>
            <Td className='bg-gray-300 text-gray-700  '></Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {permissionsData.map((data: any) => {
            return (
              <Tr
                style={{ borderBottomStyle: 'solid' }}
                key={data.id}
                className='y text-start border-4 border-gray-300'>
                <Td className='bg-gray-500 '>
                  <div
                    className={clsx(
                      data.module === 'Wallet'
                        ? 'bg-success rounded-2 px-4 py-2 d-flex justify-content-center w-50'
                        : 'bg-warning rounded-2 px-4 py-2 d-flex justify-content-center w-50'
                    )}>
                    {data.module}
                  </div>
                </Td>
                <Td className='bg-gray-500 border-0 '>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <p> {data.name}</p>
                      <p
                        style={{ background: '#FFA79A' }}
                        className={clsx(
                          data.susspended
                            ? 'ms-2 rounded-3 px-2 fs-10 text-white fw-lighter '
                            : ''
                        )}>
                        {data.susspended ? 'SUSPENDED' : ''}
                      </p>
                    </div>
                    <p
                      className={clsx(
                        data.inherited
                          ? 'text-primary fw-lighter text-decoration-underline fs-9 '
                          : ''
                      )}>
                      {data.inherited ? 'inherited from group' : ''}
                    </p>
                  </div>
                </Td>
                <Td className='bg-gray-500'>{data.status}</Td>
                <Td className='bg-gray-500'>{data.type}</Td>
                <Td className='bg-gray-500'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='outlined-primary'
                      className=' bg-white p-4 '>
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='bg-secondary p-1'>
                      <Dropdown.Item
                        onClick={() => handlePermissionChange(data.id)}
                        className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                        Suspend
                      </Dropdown.Item>
                      <Dropdown.Item className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                        Unassign
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};

export default PermissionsTable;
