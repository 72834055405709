import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';

import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';

import FinancePage from '../pages/finance/FinancePage';
import AllFinances from '../pages/finance/pages/AllFinances';
import Clients from '../pages/finance/pages/Clients';
import Partners from '../pages/finance/pages/Partners';
import ContactGroups from '../pages/managment/contactGroups/ContactGroups';
import ContactList from '../pages/managment/contactList/ContactList';
import NewContact from '../pages/managment/contactList/contactListView/newContact/NewContact';
import ImportContacts from '../pages/managment/contactList/contactListView/newContact/ImportContacts';
import EditExistingContact from '../pages/managment/contactList/contactListView/editExistingContact/EditExistingContact';
import EditExistingGroup from '../pages/managment/contactGroups/groupView/components/editExistingGroup/EditExistingGroup';
import ViewExistingGroup from '../pages/managment/contactGroups/groupView/components/viewExistingGroup/ViewExistingGroup';
import CreateContactGroup from '../pages/managment/contactGroups/groupView/components/createContactGroup/CreateContactGroup';
import ViewPermission from '../pages/managment/Permissions/permissionView/lineTabs/viewPermission/ViewPermission';
import EditPermission from '../pages/managment/Permissions/permissionView/lineTabs/editPermission/EditPermission';
import CreateNewPermission from '../pages/managment/Permissions/permissionView/createNewPermission/CreateNewPermission';
import PermissionView from '../pages/managment/Permissions/PermissionView';
import MarketplaceModules from '../pages/marketplaceModules/MarketplaceModules';
import ChargingPointView from '../pages/chargingManagment/chargingPoints/ChargingPointView';
import CreateNewLocation from '../pages/chargingManagment/chargingPoints/components/createNewLocation/CreateNewLocation';
import ReportsView from '../pages/managment/reports/ReportsView';
import CreateNewStation from '../pages/chargingManagment/chargingPoints/components/createNewStation/CreateNewStation';
import LocationDetailView from '../pages/chargingManagment/chargingPoints/components/viewLocationDetail/LocationDetailView';
import StationDetailView from '../pages/chargingManagment/chargingPoints/lineTabs/stations/viewStationDetail/StationDetailView';

const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* Finance */}
        <>
          <Route path='/finance' element={<FinancePage />} />
          <Route path='/finance/all' element={<AllFinances />} />
          <Route path='/finance/clients' element={<Clients />} />
          <Route path='/finance/partners' element={<Partners />} />
        </>
        {/*Managment*/}
        <>
          <Route path='managment/*' element={<Navigate to='/dashboard' />} />
          <Route path='/managment/contactlist' element={<ContactList />} />
          <Route path='/managment/contactgroups' element={<ContactGroups />} />
          <Route path='/managment/permissions' element={<PermissionView />} />
          {/*Contact list*/}
          <>
            <Route
              path='/managment/contactlist/createnewcontact'
              element={<NewContact />}
            />
            <Route
              path='/managment/contactlist/importnewcontact'
              element={<ImportContacts />}
            />
            <Route
              path='/managment/contactlist/edit/:id'
              element={<EditExistingContact />}
            />
          </>
          {/*Contact groups*/}
          <>
            <Route
              path='/managment/contactgroups/view/:id'
              element={<ViewExistingGroup />}
            />
            <Route
              path='/managment/contactgroups/edit/:id'
              element={<EditExistingGroup />}
            />
            <Route
              path='/managment/contactgroups/createcontactgroup'
              element={<CreateContactGroup />}
            />
          </>
          {/*Permissions*/}
          <>
            <Route
              path='/managment/permissions/view/:id'
              element={<ViewPermission />}
            />
            <Route
              path='/managment/permissions/edit/:id'
              element={<EditPermission />}
            />
            <Route
              path='/managment/permissions/createnewpermission'
              element={<CreateNewPermission />}
            />
          </>
        </>
        {/*Marketplace modules*/}
        <>
          <Route path='/marketplace' element={<MarketplaceModules />} />
        </>

        {/*Charging management*/}
        <>
          <Route
            path='chargingmanagement/*'
            element={<Navigate to='/dashboard' />}
          />
          <Route
            path='/chargingmanagement/chargepoints'
            element={<ChargingPointView />}
          />
          <Route
            path='/chargingmanagement/chargepoints/createnewlocation'
            element={<CreateNewLocation />}
          />
          <Route
            path='/chargingmanagement/chargepoints/createnewstation'
            element={<CreateNewStation />}
          />
          <Route path='/chargingmanagement/reports' element={<ReportsView />} />
          <Route
            path='/chargingmanagement/chargepoints/viewlocation/:id'
            element={<LocationDetailView />}
          />
          <Route
            path='/chargingmanagement/chargepoints/viewstation/:id'
            element={<StationDetailView />}
          />
        </>
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

// const SuspensedView: FC = ({ children }) => {
//   const baseColor = getCSSVariableValue('--bs-primary');
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   });
// return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
// };

export { PrivateRoutes };
