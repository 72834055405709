import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface INewContact {
  email: string;
  phoneNumber: string;
  note: string;
}

const NewContact = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialValues: INewContact = {
    email: '',
    phoneNumber: '',
    note: '',
  };
  const handleSubmit = (values: INewContact) => {
    // todo add data to array of contacts
  };
  const handleCancelClick = () => {
    // ! temporary solution
    navigate('/managment/contactlist');
  };

  return (
    <div className='mx-20'>
      <h1 className='mb-8'>{t('contactList.newContact.title')}</h1>
      <Formik<INewContact>
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {() => (
          <Form>
            <div className='bg-gray-300 p-8'>
              <h2>{t('contactList.newContact.personalInfo')}</h2>
              <div>
                <div className='d-flex flex-wrap justify-content-between my-6'>
                  <div>
                    <h3>{t('contactList.newContact.email')}</h3>
                    <Field
                      name='email'
                      className='p-2 w-250px w-lg-300px w-xl-400px mb-4 mb-lg-0'
                      type='email'
                      placeholder={t('contactList.newContact.emailPlaceholder')}
                    />
                  </div>
                  <div>
                    <h3>{t('contactList.newContact.phone')}</h3>
                    <Field
                      name='phoneNumber'
                      className='p-2 w-250px w-lg-300px w-xl-400px'
                      type='text'
                      placeholder={t('contactList.newContact.phonePlaceholder')}
                    />
                  </div>
                </div>
                <div>
                  <h2>{t('contactList.newContact.personalNote')}</h2>
                  <div className='my-6'>
                    <h3>{t('contactList.newContact.note')}</h3>
                    <Field
                      className='w-100 p-4'
                      as='textarea'
                      name='note'
                      rows={3}
                      placeholder={t('contactList.newContact.notePlaceholder')}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-end mt-8'>
                <button
                  onClick={handleCancelClick}
                  className='btn text-primary  text-decoration-underline me-2'>
                  {t('contactList.newContact.cancelBtn')}
                </button>
                <button type='submit' className='btn btn-primary'>
                  {t('contactList.newContact.saveBtn')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewContact;
