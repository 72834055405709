import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive } from '../../../helpers';
import { useLayout } from '../../core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import styles from './AsideMenuItem.module.scss';


type Props = {
  to: string;
  title: string;
  icon?: IconDefinition;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className={clsx('menu-item')}>
      <Link
        className={clsx(isActive ? styles.icon : '', 'menu-link without-sub', {
          active: isActive,
        })}
        to={to}>
        {hasBullet && (
          <span className='menu-bullet '>
            <span className='bullet bullet-dot '></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon '>
            <FontAwesomeIcon icon={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className={clsx('menu-title')}>{title}</span>
      </Link>

      {children}
    </div>
  );
};

export { AsideMenuItem };
