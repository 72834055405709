import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, LoginResponse } from './api';
import { loadLocalAuthToken, saveLocalAuthToken } from './auth-token';

const setReducer: CaseReducer<LoginResponse, PayloadAction<LoginResponse>> = (
  state,
  action: PayloadAction<LoginResponse>
) => {
  if (action.payload && action.payload.token) {
    saveLocalAuthToken(action.payload.token);
  }
  return action.payload;
};

const loadReducer: CaseReducer<LoginResponse> = (state, action) => {
  return { token: loadLocalAuthToken() };
};

const initialState: LoginResponse = { token: loadLocalAuthToken() };

export const bearerTokenSlice = createSlice({
  name: 'bearerToken',
  initialState,
  reducers: {
    set: setReducer,
    load: loadReducer,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.createAuthenticationToken.matchFulfilled,
      (state, action) => {
        state = action.payload;
        return setReducer(state, action);
      }
    );
  },
});
