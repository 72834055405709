import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMasterStationData } from '../../../../../models/interface';
interface IProps {
  selectedStation: IMasterStationData;
}

const StationCharge = ({ selectedStation }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex justify-content-between'>
      {selectedStation.plugs.map((plug) => (
        <div key={plug.id} className='bg-light w-50 m-4 p-4'>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='text-gray-700'>PLUG</h3>
              <h3>{plug.id === '1' ? 'A' : 'B'}</h3>
            </div>
            <div>
              <h3 className='text-gray-700'>PLUG</h3>
              <h3>BMBFN1</h3>
            </div>
          </div>
          <div className='d-flex bg-gray-300 my-4 p-4 '>
            {plug.avalaibility ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                size='2x'
                className='text-success'
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                size='2x'
                className='text-danger'
              />
            )}
            <div className='ms-4 w-100'>
              <h3 className='text-gray-700'>
                {plug.avalaibility
                  ? t('stationDetail.charge.plugAvalaible')
                  : t('stationDetail.charge.plugUnavalaible')}
              </h3>
              <p>
                {plug.avalaibility
                  ? t('stationDetail.charge.connectorAvalaible')
                  : t('stationDetail.charge.connectorUnavalaible')}
              </p>
              <button
                className={clsx(
                  'btn btn-primary btn-sm w-50',
                  plug.avalaibility ? '' : 'disabled'
                )}>
                {t('stationDetail.charge.startBtn')}
              </button>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>{t('stationDetail.charge.type')}</h3>
              <h3 className='fw-bolder'>9.5kč</h3>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>{t('stationDetail.charge.limit')}</h3>
              <h3 className='fw-bolder'>22kW</h3>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>{t('stationDetail.charge.type')}</h3>
              <h3 className='fw-bolder'>Plug -Type2</h3>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>{t('stationDetail.charge.type')}</h3>
              <h3 className='fw-bolder'>AC</h3>
            </div>
          </div>
          <div className='d-flex flex-column mt-8'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>
                {t('stationDetail.charge.priceParking')}
              </h3>
              <h3 className='fw-bolder'>0kč</h3>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>{t('stationDetail.charge.limit')}</h3>
              <h3 className='fw-bolder'>0kč</h3>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h3 className='fw-light'>{t('stationDetail.charge.type')}</h3>
              <h3 className='fw-bolder'>0 min</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StationCharge;
