import { useEffect, useState } from 'react';

import PermissionsTable from './components/PermissionsTable';
import SearchPermissions from './components/SearchPermissions';

interface IProps {
  initialValues: {
    id: string;
    email: string;
    phoneNumber: string;
    note: string;
    groups: {
      name: string;
      id: string;
    }[];
    permission: {
      module: string;
      name: string;
      status: string;
      type: string;
      id: string;
      susspended: boolean;
      inherited: boolean;
    }[];
  };
}

const AsignedPermisions = ({ initialValues }: IProps) => {
  const [searchBy, setSearchBy] = useState('');
  const [copyData, setCopyData] = useState<any[]>(initialValues.permission);
  const [permission, setPermission] = useState('');

  const handlePermissionChange = (id: string) => {
    setPermission(id);
  };

  useEffect(() => {
    const filteredData = initialValues.permission.filter((data: any) => {
      return (
        data.module.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.name.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.status.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.type.toString().toLowerCase().includes(searchBy.toLowerCase())
      );
    });

    if (searchBy === '') {
      setCopyData(initialValues.permission);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  useEffect(() => {
    let editedPermission = initialValues.permission.map((data) =>
      data.id === permission ? { ...data, susspended: true } : data
    );
    setCopyData(editedPermission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission]);

  return (
    <div className='bg-gray-300 p-8'>
      <SearchPermissions setSearchBy={setSearchBy} />
      <PermissionsTable
        permissionsData={copyData}
        handlePermissionChange={handlePermissionChange}
      />
    </div>
  );
};

export default AsignedPermisions;
