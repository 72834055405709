import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreateNewStationModal from './createNewStation/CreateNewStationModal';

interface INewStation {
  id: string;
  name: string;
  type?: string;
  note?: string;
}

const StationsView = () => {
  const [showModal, setShowModal] = useState(false);
  const [newStation, setNewStation] = useState<INewStation | null>({
    id: '',
    name: '',
    type: '',
    note: '',
  });
  const [stationsArray, setStationsArray] = useState<INewStation[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    newStation && setStationsArray([...stationsArray, newStation]);
    setNewStation(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStation]);

  return (
    <div className='w-100 p-6  bg-gray-300  '>
      <div className='d-flex justify-content-between'>
        <div>
          <h3 className='bg-gray-300 fs-7 text-gray-700'>
            {t('createNewLocation.stepperBody.stationView.optional')}
          </h3>
          <h2 className='fw-bold'>
            {t('createNewLocation.stepperBody.stationView.name')}
          </h2>
          <h3 className='fs-6 fw-normal'>
            {t('createNewLocation.stepperBody.stationView.infoText')}
          </h3>
        </div>
        <div>
          <button
            onClick={() => setShowModal(true)}
            className='btn btn-primary'>
            {t('createNewLocation.stepperBody.stationView.newStationBtn')}
          </button>
        </div>
      </div>
      <div className='mt-10'>
        <h3 className='bg-gray-300 fs-7 text-gray-700'>
          {t('createNewLocation.stepperBody.stationView.yourStations')}
        </h3>
        <div className='h-400px bg-gray-400 d-flex flex-column  '>
          {stationsArray
            ? stationsArray.map((station: any) =>
                station?.id === '' ? null : (
                  <div
                    key={station.id}
                    className='d-flex justify-content-between mx-2 mt-2 p-4 bg-light bg-hover-secondary  '>
                    <div>
                      <h3 className='fs-7 text-gray-700'>
                        {t(
                          'createNewLocation.stepperBody.stationView.nameCapital'
                        )}
                      </h3>
                      <h3>{station.name}</h3>{' '}
                    </div>
                    <div>
                      <button className='btn btn-sm btn-outline-primary border border-1 border-primary'>
                        {t('createNewLocation.stepperBody.stationView.editBtn')}
                      </button>
                    </div>
                  </div>
                )
              )
            : null}
        </div>
      </div>
      {showModal && (
        <CreateNewStationModal
          setShowModal={setShowModal}
          showModal={showModal}
          setNewStation={setNewStation}
          newStation={newStation}
        />
      )}
    </div>
  );
};

export default StationsView;
