import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { masterChargePointsData } from '../../../models/mockData/energyManagementMockData';
import LiveDataView from './lineTabs/liveData/LiveDataView';
import Overview from './lineTabs/overView/Overview';
import LocationPermissionView from './lineTabs/permissions/LocationPermissionView';

const LocationDetailView = () => {
  const [navMenu, setNavMenu] = useState('overview');
  const { id } = useParams();
  const { t } = useTranslation();
  const selectedLocation = masterChargePointsData.filter(
    (location) => location.id === id
  );
  const handleClick = (value: string) => {
    setNavMenu(value);
  };

  return (
    <>
      <div>
        <div className='d-flex justify-content-between'>
          <div>
            <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
              <li className='breadcrumb-item pe-3'>
                <Link to='/dashboard' className='pe-3'>
                  {t('breadcrumbs.chargingManagement')}
                </Link>
              </li>
              <li className='breadcrumb-item pe-3'>
                <Link to='/chargingmanagement/chargepoints' className='pe-3'>
                  {t('breadcrumbs.chargePoints')}
                </Link>
              </li>
            </ol>
            <h2 className='my-8'>{selectedLocation[0].name}</h2>
            <nav>
              <ul className='d-flex list-unstyled'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'overview'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('overview')}>
                  {t('locationView.overview.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'liveData'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('liveData')}>
                  {t('locationView.liveDataView.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'permissions'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('permissions')}>
                  {t('locationView.locationPermissionView.name')}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='bg-gray-300 p-4'>
          {navMenu === 'overview' && <Overview />}
          {navMenu === 'liveData' && <LiveDataView />}
          {navMenu === 'permissions' && <LocationPermissionView />}
        </div>
      </div>
    </>
  );
};

export default LocationDetailView;
