import { faStreetView } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Table, Tbody, Thead, Tr, Td } from 'react-super-responsive-table';
import PaginateComponent from '../../../../../components/pagination/PaginateComponent';
import { IMasterSectionsData } from '../../../models/interface';
import { masterSectionsData } from '../../../models/mockData/energyManagementMockData';

const SectionsView = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [searchBy, setSearchBy] = useState('');
  const [copyData, setCopyData] =
    useState<IMasterSectionsData[]>(masterSectionsData);

  const { t } = useTranslation();

  // const navigate = useNavigate();

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filteredData = masterSectionsData.filter(
      (data: IMasterSectionsData) => {
        return (
          data.name.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
          data.pId.toString().toLowerCase().includes(searchBy.toLowerCase())
        );
      }
    );
    if (searchBy === '') {
      setCopyData(masterSectionsData);
    } else {
      setCopyData(filteredData);
    }
  }, [searchBy]);

  return (
    <>
      <div className='d-flex flex-wrap justify-content-between py-4'>
        <Form>
          <Form.Control
            className='w-250px mb-4 mb-md-0 '
            type='text'
            placeholder='Search'
            defaultValue=''
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </Form>
        <div className='d-flex flex-column flex-md-row align-items-end'>
          <button
            // onClick={handleCreateNewPermission}
            className='btn border border-primary text-primary bg-hover-primary text-hover-white text-primary bg-white me-md-3 mb-3 mb-md-0 '>
            {t('chargePoints.sections.filterBtn')}
          </button>
        </div>
      </div>
      <Table className=' table table-secondary   table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold   px-1 bg-gray-300 border-0 '>
            <Td className='bg-gray-300 text-gray-700'>
              {t('chargePoints.sections.tableHeaders.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('chargePoints.sections.tableHeaders.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('chargePoints.sections.tableHeaders.header3')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('chargePoints.sections.tableHeaders.header4')}
            </Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: IMasterSectionsData) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  key={data.id}
                  className=' text-start border-4 border-gray-300 text-center'>
                  <Td className='w-100 w-md-50 bg-gray-500 fs-9 fw-bolder align-baseline   '>
                    <div className='text-start'>
                      <h4> {data.name}</h4>
                      {data.location?.map((location) => {
                        return (
                          <p
                            key={location.id}
                            className='text-primary text-decoration-underline fs-6'>
                            <FontAwesomeIcon
                              icon={faStreetView}
                              className='me-2'
                            />
                            {location?.name}
                          </p>
                        );
                      })}
                    </div>
                  </Td>

                  <Td className='bg-gray-500 align-baseline '>
                    {data.stations?.length}
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>{data.pId}</Td>
                  <Td className='bg-gray-500 align-baseline '>
                    {data.tags.map((tag) => {
                      return (
                        <p
                          key={tag.id}
                          className={clsx(
                            tag.avalaibility ? 'bg-success' : 'bg-danger',
                            'px-3 py-1 fs-8 text-white rounded rounded-pill'
                          )}>
                          {tag.avalaibility ? 'FREE' : 'OCCUPIED'}
                        </p>
                      );
                    })}
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t('chargePoints.sections.dropdownBtn.title')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item
                          // onClick={() => handleViewPermission(data.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t('chargePoints.sections.dropdownBtn.action1')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          // onClick={() => handleEditPermission(data.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t('chargePoints.sections.dropdownBtn.action2')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </>
  );
};

export default SectionsView;
