import { useState } from 'react';
import { useEffect } from 'react';

import ListView from './contactListView/listView/ListView';
import { masterUsersData } from '../models/mockData/mockData';
import { useNavigate } from 'react-router-dom';
import { IMasterUserData } from '../models/interfaces';

const ContactList = () => {
  const [searchBy, setSearchBy] = useState('');
  const [newUserOption, setNewUserOption] = useState('');
  const [copyData, setCopyData] = useState<IMasterUserData[]>(masterUsersData);

  const navigate = useNavigate();

  useEffect(() => {
    const filteredData = masterUsersData.filter((data: IMasterUserData) => {
      return (
        data.email.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.note.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.phoneNumber
          .toString()
          .toLowerCase()
          .includes(searchBy.toLowerCase()) ||
        data.permission
          .toString()
          .toLowerCase()
          .includes(searchBy.toLowerCase())
      );
    });

    if (searchBy === '') {
      setCopyData(masterUsersData);
    } else {
      setCopyData(filteredData);
    }
  }, [searchBy]);

  useEffect(() => {
    switch (newUserOption) {
      case 'addContact':
        return navigate('/managment/contactlist/createnewcontact');
      case 'importFromFile':
        return navigate('/managment/contactlist/importnewcontact');
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserOption]);

  return (
    <div>
      <ListView
        copyData={copyData}
        setSearchBy={setSearchBy}
        setNewUserOption={setNewUserOption}
      />
    </div>
  );
};

export default ContactList;
