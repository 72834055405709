import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IMasterPermissionsData } from '../../../models/interfaces';
import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PaginateComponent from '../../../../../components/pagination/PaginateComponent';
import { useTranslation } from 'react-i18next';

interface IProps {
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
  copyData: IMasterPermissionsData[];
}

const CreatedByMe = ({ setSearchBy, copyData }: IProps) => {
  const [pageNumber, setPageNumber] = useState(0);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const handleViewPermission = (id: string) => {
    navigate(`/managment/permissions/view/${id}`);
  };
  const handleEditPermission = (id: string) => {
    navigate(`/managment/permissions/edit/${id}`);
  };
  const handleCreateNewPermission = () => {
    navigate(`/managment/permissions/createnewpermission`);
  };

  return (
    <>
      <div className='d-flex flex-wrap justify-content-between py-4'>
        <Form>
          <Form.Control
            className='w-250px mb-4 mb-md-0 '
            type='text'
            placeholder={t('permission.createdByMe.search')}
            defaultValue=''
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </Form>
        <div className='d-flex flex-column align-items-end'>
          <button
            onClick={handleCreateNewPermission}
            className='btn btn-primary d-flex align-items-center'>
            <FontAwesomeIcon icon={faPlus} className='me-2' />{' '}
            {t('permission.createdByMe.newPermissionBtn')}
          </button>
        </div>
      </div>
      <Table className=' table table-secondary   table-responsive table-row-dashed  table-hover gy-4 gs-7'>
        <Thead>
          <Tr className='fw-bold   px-1 bg-gray-300 border-0 '>
            <Td className='bg-gray-300 text-gray-700'>
              {' '}
              {t('permission.createdByMe.table.headers.header1')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('permission.createdByMe.table.headers.header2')}
            </Td>
            <Td className='bg-gray-300 text-gray-700'>
              {t('permission.createdByMe.table.headers.header3')}
            </Td>
          </Tr>
        </Thead>
        <Tbody className='fw-bold'>
          {copyData
            .slice(pagesVisited, pagesVisited + contactsPerPage)
            .map((data: IMasterPermissionsData) => {
              return (
                <Tr
                  style={{ borderBottomStyle: 'solid' }}
                  key={data.id}
                  className=' text-start border-4 border-gray-300'>
                  <Td className='bg-gray-500 fs-9 fw-bolder align-baseline   '>
                    <div
                      className={clsx(
                        data.module === 'Wallet'
                          ? 'bg-success rounded-2 px-4 py-2 d-flex justify-content-center '
                          : 'bg-warning rounded-2 px-4 py-2 d-flex justify-content-center '
                      )}>
                      {data.module}
                    </div>
                  </Td>
                  <Td className='bg-gray-500 border-0 w-100 w-md-50 '>
                    <div className='d-flex align-items-center '>
                      <p> {data.name}</p>
                      <p
                        style={{ background: '#FFA79A' }}
                        className={clsx(
                          data.susspended
                            ? 'ms-2 rounded-3 px-2 fs-10 text-white fw-lighter '
                            : ''
                        )}>
                        {data.susspended ? 'SUSPENDED' : ''}
                      </p>
                    </div>
                  </Td>
                  <Td className='bg-gray-500 align-baseline '>{data.type}</Td>
                  <Td className='bg-gray-500 align-baseline '>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='outlined-primary'
                        className=' bg-white p-4 '>
                        {t('permission.createdByMe.table.body.dropDown.title')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='bg-secondary p-1'>
                        <Dropdown.Item
                          onClick={() => handleViewPermission(data.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 py-3 bg-hover-primary text-hover-white'>
                          {t(
                            'permission.createdByMe.table.body.dropDown.action1'
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleEditPermission(data.id)}
                          className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
                          {t(
                            'permission.createdByMe.table.body.dropDown.action2'
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>

      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </>
  );
};

export default CreatedByMe;
