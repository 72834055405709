import React from 'react';
import {
  faUsers,
  faUser,
  faUserGroup,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const DashboardWelcomeView = () => {
  const { t } = useTranslation();
  return (
    <div className='d-flex flex-column '>
      <h1 className='mb-8'>{t('dashboardWelcomeView.name')}</h1>
      <h2 className='fw-normal mb-8'>
        {t('dashboardWelcomeView.tips')}{' '}
        <span className='fw-bolder'>
          {' '}
          {t('dashboardWelcomeView.portalName')}
        </span>
      </h2>
      <div className='d-flex justify-content-center align-items-center text-center justify-content-md-start  flex-wrap  '>
        <div className='w-100 w-md-200px h-200px bg-gray-500 p-4 d-flex justify-content-center  flex-column me-md-8 mb-8'>
          <h3 className=''> {t('dashboardWelcomeView.profile.name')}</h3>
          <p>{t('dashboardWelcomeView.profile.profileText')}</p>
          {/* add link to edit profile */}
          <Link to={'/'}>
            <button className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 fs-9  text-primary  w-md-100'>
              <FontAwesomeIcon icon={faUser} className='me-2' />
              {t('dashboardWelcomeView.profile.titleBtn')}
            </button>
          </Link>
        </div>
        <div className='w-100 w-md-200px h-200px bg-gray-500 p-4 d-flex justify-content-center  flex-column me-md-8 mb-8'>
          <h3 className=''>{t('dashboardWelcomeView.contacts.name')}</h3>
          <p>{t('dashboardWelcomeView.contacts.profileText')}</p>
          <Link to={'/managment/contactlist'}>
            <button className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 fs-9 text-primary w-md-100'>
              <FontAwesomeIcon icon={faUserGroup} className='me-2' />
              {t('dashboardWelcomeView.contacts.titleBtn')}
            </button>
          </Link>
        </div>
        <div className='w-100 w-md-200px h-200px bg-gray-500 p-4 d-flex justify-content-center  flex-column me-md-8 mb-8'>
          <h3 className=''>{t('dashboardWelcomeView.groups.name')}</h3>
          <p>{t('dashboardWelcomeView.groups.profileText')}</p>
          <Link to={'/managment/contactgroups'}>
            <button className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white text-primary mx-auto py-2 fs-9  w-md-100 '>
              <FontAwesomeIcon icon={faUsers} className='me-2' />
              {t('dashboardWelcomeView.groups.titleBtn')}
            </button>
          </Link>
        </div>
        <div className='w-100 w-md-200px h-200px bg-gray-500 p-4 d-flex justify-content-center  flex-column me-md-8 mb-8'>
          <h3 className=''>{t('dashboardWelcomeView.permissions.name')}</h3>
          <p>{t('dashboardWelcomeView.permissions.profileText')}</p>
          <Link to={'/managment/permissions'}>
            <button className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 fs-9 text-primary w-md-100 '>
              <FontAwesomeIcon icon={faListCheck} className='me-2' />
              {t('dashboardWelcomeView.permissions.titleBtn')}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardWelcomeView;
