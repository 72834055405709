import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import EditGroupUsers from './lineTabs/EditGroupUsers';
import EditGroupPermissions from './lineTabs/EditGroupPermissions';
import { masterGroupsData } from '../../../../models/mockData/mockData';
import { useTranslation } from 'react-i18next';

const EditExistingGroup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const filteredData = masterGroupsData.filter((data) => data.id === id);
  const initialValues = filteredData[0];

  const [navMenu, setNavMenu] = useState('about');
  const [newGroupName, setNewGroupName] = useState(initialValues.name);
  const [searchBy, setSearchBy] = useState('');
  const [copyData, setCopyData] = useState(initialValues.users);

  const { t } = useTranslation();

  const handleNavigationValue = (value: string) => {
    setNavMenu(value);
  };
  // todo logic
  const handleReturnClick = () => {
    navigate('/managment/contactgroups/');
  };

  useEffect(() => {
    const filteredData = initialValues.users.filter((data: any) => {
      return data.email
        .toString()
        .toLowerCase()
        .includes(searchBy.toLowerCase());
    });

    if (searchBy === '') {
      setCopyData(initialValues.users);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  return (
    <>
      <div>
        <div className='mb-8'>
          <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
            <li className='breadcrumb-item pe-3'>
              <Link to='/dashboard' className='pe-3'>
                {t('breadcrumbs.management')}
              </Link>
            </li>
            <li className='breadcrumb-item pe-3'>
              <Link to='/managment/contactgroups' className='pe-3'>
                {t('breadcrumbs.contactGroups')}
              </Link>
            </li>
          </ol>
          <h2 className='fw-light'>{t('contactGroups.groupEdit.title')}</h2>
        </div>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <div className='bg-gray-300 mb-4 mb-md-0 d-flex flex-column h-100px w-md-25 p-4 justify-content-center'>
            <h2 className='fw-light'>{t('contactGroups.groupEdit.name')}</h2>
            <Form>
              <Form.Control
                type='text'
                className='fw-lighter'
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </Form>
          </div>
          <div className='w-100 w-md-75'>
            <div className='d-flex justify-content-between'>
              <div>
                <nav>
                  <ul className='d-flex list-unstyled'>
                    <li
                      style={{ cursor: 'pointer' }}
                      className={clsx(
                        'fs-3 text-hover-primary mx-2 pb-3',
                        navMenu === 'about'
                          ? 'border-bottom border-5 border-primary text-primary'
                          : ''
                      )}
                      onClick={() => handleNavigationValue('about')}>
                      {t('contactGroups.groupEdit.users.name')}
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      className={clsx(
                        'fs-3 text-hover-primary mx-2 pb-3',
                        navMenu === 'permision'
                          ? 'border-bottom border-5 border-primary text-primary'
                          : ''
                      )}
                      onClick={() => handleNavigationValue('permision')}>
                      {t('contactGroups.groupEdit.assignedPermissions.name')}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className='bg-gray-300 p-4'>
              <div className='d-flex justify-content-between'></div>
              {navMenu === 'about' && (
                <EditGroupUsers
                  initialValues={initialValues}
                  contactUsers={copyData}
                  setSearchBy={setSearchBy}
                />
              )}
              {navMenu === 'permision' && (
                <EditGroupPermissions initialValues={initialValues} />
              )}
            </div>
            <div className='d-flex justify-content-between mt-8'>
              <div>
                <button
                  onClick={() => handleReturnClick()}
                  className='btn btn-outline-primary border border-2 border-primary me-4 '>
                  {t('contactGroups.groupEdit.deteleBtn')}
                </button>
              </div>
              <div className='d-flex justify-content-end'>
                <button
                  onClick={() => handleReturnClick()}
                  className='btn text-primary text-decoration-underline me-2'>
                  {t('contactGroups.groupEdit.cancelBtn')}
                </button>
                {/** todo  */}
                <button
                  onClick={() => handleReturnClick()}
                  type='submit'
                  className='btn btn-primary'>
                  {t('contactGroups.groupEdit.saveBtn')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditExistingGroup;
