import { useEffect, useState } from 'react';

import FinanceView from './components/FinanceView';
import { testData } from './data/testData';

const Clients = () => {
  const [searchByName, setSearchByName] = useState('');
  const [getMonth, setGetMonth] = useState('');
  const [getYear, setGetYear] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchByOption, setSearchByOption] = useState('');
  const [copyData, setCopyData] = useState<any[]>([]);

  useEffect(() => {
    const filteredData = testData.filter((data) =>
      data.name.toLowerCase().includes(searchByName.toLowerCase())
    );
    if (searchByName === '') {
      setCopyData(testData);
    } else {
      setCopyData(filteredData);
    }
  }, [searchByName, getMonth, getYear]);

  return (
    <>
      <FinanceView
        testData={copyData}
        setGetYear={setGetYear}
        setSearchByName={setSearchByName}
        setGetMonth={setGetMonth}
        setSearchByOption={setSearchByOption}
      />
    </>
  );
};

export default Clients;
