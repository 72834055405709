import clsx from 'clsx';
import { useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import ViewDetailsPermission from './components/ViewDetailsPermission';
import ViewMembersPermission from './components/ViewMembersPermission';
import {
  masterPermissionsData,
  masterGroupsData,
  masterUsersData,
} from '../../../../models/mockData/mockData';
import { IMasterGroupsData } from '../../../../models/interfaces';
import { useTranslation } from 'react-i18next';

const ViewPermission = () => {
  const [navMenu, setNavMenu] = useState('about');
  const { id } = useParams();
  const navigate = useNavigate();
  const result = masterUsersData.filter((obj) => {
    const permission = obj.permission;
    const filteredPermissions = permission.filter((permission) => {
      return permission.id === id;
    });
    return filteredPermissions.length !== 0;
  });

  const filteredData = masterPermissionsData.filter((data) => data.id === id);
  const initialValues = filteredData[0];
  const { t } = useTranslation();

  const handleNavigationValue = (value: string) => {
    setNavMenu(value);
  };
  const handleEdit = () => {
    navigate(`/managment/permissions/edit/${id}`);
  };
  const activegroup: IMasterGroupsData[] = masterGroupsData
    ?.filter((obj) => obj.permission.length !== 0)
    .filter((obj) => obj.id === initialValues.id);

  return (
    <>
      <div>
        <div className='mb-8'>
          <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
            <li className='breadcrumb-item pe-3'>
              <Link to='/dashboard' className='pe-3'>
                {t('breadcrumbs.management')}
              </Link>
            </li>
            <li className='breadcrumb-item pe-3'>
              <Link to='/managment/permissions' className='pe-3'>
                {t('breadcrumbs.permissions')}
              </Link>
            </li>
          </ol>
          <div className='d-flex justify-content-between'>
            <h2 className='fw-light'>{initialValues.name}</h2>
            <button
              onClick={handleEdit}
              className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
              {t('permission.viewPermission.editBtn')}
            </button>
          </div>
        </div>
        <div className='d-flex flex-column flex-md-row justify-content-between '>
          <div className='w-100 w-md-25 me-4 '>
            <div className='bg-gray-300 d-flex flex-column  p-4  '>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('permission.viewPermission.module')}
              </h3>
              <h2>{initialValues.module}</h2>
            </div>
            <div className='bg-gray-300 d-flex flex-column  p-4   '>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('permission.viewPermission.module')}
              </h3>
              <h2>{initialValues.type}</h2>
            </div>
            <div className='bg-gray-300 d-flex flex-column  p-4   '>
              <h3 className='text-gray-700 fs-5 my-2'>
                {t('permission.viewPermission.asssignedTo')}
              </h3>
              {activegroup.map((obj) => {
                return (
                  <div key={obj.id} className='bg-gray-200 px-4 py-2 my-2'>
                    <h3 className='text-gray-700 fs-9 my-2'>
                      {t('permission.viewPermission.group')}
                    </h3>
                    <h2>{obj.name}</h2>
                    <h3 className='fw-light fs-9'>
                      {result.length}{' '}
                      {t('permission.viewPermission.usersInGrp')}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='w-100 w-md-75'>
            <div className='d-flex justify-content-between'>
              <div>
                <nav>
                  <ul className='d-flex list-unstyled'>
                    <li
                      style={{ cursor: 'pointer' }}
                      className={clsx(
                        'fs-3 text-hover-primary mx-2 pb-3',
                        navMenu === 'about'
                          ? 'border-bottom border-5 border-primary text-primary'
                          : ''
                      )}
                      onClick={() => handleNavigationValue('about')}>
                      {t('permission.viewPermission.details.title')}
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      className={clsx(
                        'fs-3 text-hover-primary mx-2 pb-3',
                        navMenu === 'permision'
                          ? 'border-bottom border-5 border-primary text-primary'
                          : ''
                      )}
                      onClick={() => handleNavigationValue('permision')}>
                      {t('permission.viewPermission.members.title')}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className='bg-gray-300 p-4'>
              <div className='d-flex justify-content-between'></div>
              {navMenu === 'about' && (
                <ViewDetailsPermission
                // initialValues={initialValues}
                // contactUsers={copyData}
                // setSearchBy={setSearchBy}
                />
              )}
              {navMenu === 'permision' && (
                <ViewMembersPermission
                // initialValues={initialValues}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPermission;
