import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import CreatedByMe from './permissionView/lineTabs/CreatedByMe';
import AppliedToMe from './permissionView/lineTabs/AppliedToMe';
import { masterPermissionsData } from '../models/mockData/mockData';
import { IMasterPermissionsData } from '../models/interfaces';
import { useTranslation } from 'react-i18next';

const PermissionView = () => {
  const [navMenu, setNavMenu] = useState('about');
  const [searchBy, setSearchBy] = useState('');
  const [copyData, setCopyData] = useState<IMasterPermissionsData[]>(
    masterPermissionsData
  );
  const { t } = useTranslation();
  const handleClick = (value: string) => {
    setNavMenu(value);
  };

  useEffect(() => {
    const filteredData = masterPermissionsData.filter((data: any) => {
      return (
        data.module.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.name.toString().toLowerCase().includes(searchBy.toLowerCase()) ||
        data.type.toString().toLowerCase().includes(searchBy.toLowerCase())
      );
    });
    if (searchBy === '') {
      setCopyData(masterPermissionsData);
    } else {
      setCopyData(filteredData);
    }
  }, [searchBy]);

  return (
    <>
      <div>
        <div className='d-flex justify-content-between'>
          <div>
            <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
              <li className='breadcrumb-item pe-3'>
                <Link to='/dashboard' className='pe-3'>
                  {t('breadcrumbs.management')}
                </Link>
              </li>
            </ol>

            <nav>
              <ul className='d-flex list-unstyled'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'about'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('about')}>
                  {t('permission.createdByMe.title')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'permision'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('permision')}>
                  {t('permission.appliedToMe.title')}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='bg-gray-300 p-4'>
          <div className='d-flex justify-content-between'></div>
          {navMenu === 'about' && (
            <CreatedByMe setSearchBy={setSearchBy} copyData={copyData} />
          )}
          {navMenu === 'permision' && (
            <AppliedToMe setSearchBy={setSearchBy} copyData={copyData} />
          )}
        </div>
      </div>
    </>
  );
};

export default PermissionView;
