import { Link, useParams, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import clsx from 'clsx';
import GroupUsers from './lineTabs/GroupUsers';
import GroupPermissions from './lineTabs/GroupPermissions';
import { masterGroupsData } from '../../../../models/mockData/mockData';
import { IMasterUserData } from '../../../../models/interfaces';
import { useTranslation } from 'react-i18next';

const ViewExistingGroup = () => {
  const [navMenu, setNavMenu] = useState('about');
  const [searchBy, setSearchBy] = useState('');
  const { id } = useParams();
  const { t } = useTranslation();
  const filteredData = masterGroupsData.filter((data) => data.id === id);
  const initialValues = filteredData[0];

  const [copyData, setCopyData] = useState<IMasterUserData[]>(
    initialValues.users
  );

  const navigate = useNavigate();

  const handleClick = (value: string) => {
    setNavMenu(value);
  };
  const handleEditAction = (id: string) => {
    navigate(`/managment/contactgroups/edit/${id}`);
  };

  useEffect(() => {
    const filteredData = initialValues.users.filter((data: any) => {
      return data.email
        .toString()
        .toLowerCase()
        .includes(searchBy.toLowerCase());
    });

    if (searchBy === '') {
      setCopyData(initialValues.users);
    } else {
      setCopyData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  return (
    <>
      <div>
        <div className='d-flex  justify-content-between'>
          <div>
            <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
              <li className='breadcrumb-item pe-3'>
                <Link to='/dashboard' className='pe-3'>
                  {t('breadcrumbs.management')}
                </Link>
              </li>
              <li className='breadcrumb-item pe-3'>
                <Link to='/managment/contactgroups' className='pe-3'>
                  {t('breadcrumbs.contactGroups')}
                </Link>
              </li>
            </ol>
            <h1 className='mb-8'>{initialValues.name}</h1>
            <nav>
              <ul className='d-flex list-unstyled flex-wrap'>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'about'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('about')}>
                  {t('contactGroups.groupView.users.name')}
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={clsx(
                    'fs-3 text-hover-primary mx-2 pb-3',
                    navMenu === 'permision'
                      ? 'border-bottom border-5 border-primary text-primary'
                      : ''
                  )}
                  onClick={() => handleClick('permision')}>
                  {t('contactGroups.groupView.assignedPermissions.name')}
                </li>
              </ul>
            </nav>
          </div>
          <div>
            {/**TODO Action for button  */}
            <button
              onClick={() => handleEditAction(initialValues.id)}
              className='border border-1 border-primary bg-white text-primary rounded-1 my-1 p-3 bg-hover-primary text-hover-white'>
              {t('contactGroups.groupView.editBtn')}
            </button>
          </div>
        </div>
        <div className='bg-gray-300 p-4'>
          <div className='d-flex justify-content-between'></div>
          {navMenu === 'about' && (
            <GroupUsers
              initialValues={initialValues}
              contactUsers={copyData}
              setSearchBy={setSearchBy}
            />
          )}
          {navMenu === 'permision' && (
            <GroupPermissions initialValues={initialValues} />
          )}
        </div>
      </div>
    </>
  );
};

export default ViewExistingGroup;
