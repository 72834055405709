import React from 'react';
import { useTranslation } from 'react-i18next';

const SummaryView = () => {
  const { t } = useTranslation();
  return (
    <div className='w-100 p-6  bg-gray-300 '>
      <div className='d-flex justify-content-between'>
        <div>
          <h3 className='bg-gray-300 fs-7 text-gray-700'>
            {t('createNewStation.stepperBody.summaryView.name')}
          </h3>
          <h2 className='fw-bold'>
            {t('createNewStation.stepperBody.summaryView.stations')}
          </h2>
          <h3 className='fs-6 fw-normal'>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo,
            nesciunt?
          </h3>
        </div>
      </div>
      <div className='mt-10'>
        <h3 className='bg-gray-300 fs-7 text-gray-700'>
          {t('createNewStation.stepperBody.summaryView.yourStations')}
        </h3>
        <div className='h-400px bg-gray-400 d-flex flex-column  '></div>
      </div>
    </div>
  );
};

export default SummaryView;
