import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DetailsView from './stepper/details/DetailsView';
import SectionsView from './stepper/sections/SectionsView';
import StationsView from './stepper/stations/StationsView';
import SummaryView from './stepper/summary/SummaryView';

const CreateNewLocation = () => {
  const [stepper, setStepper] = useState(0);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className='mb-8'>
          <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
            <li className='breadcrumb-item pe-3'>
              <Link to='/dashboard' className='pe-3'>
                {t('breadcrumbs.chargingManagement')}
              </Link>
            </li>
          </ol>
        </div>
        <h2 className='mb-8'> {t('createNewLocation.name')}</h2>
        <div className='d-flex flex-column flex-md-row justify-content-between '>
          <div className='d-flex flex-column  me-4 '>
            <div className='bg-gray-300 d-flex p-4    '>
              <h3
                className={clsx(
                  'bg-gray-600 text-white w-40px h-40px rounded  p-4  d-flex justify-content-center align-items-center me-3 '
                )}>
                1
              </h3>
              <div className='d-flex flex-column justify-content-center'>
                <h1 className='text-black fs-3 fw-bold m-0 '>
                  {' '}
                  {t('createNewLocation.stepperMenu.step1Title')}
                </h1>
                <p className='text-gray-600 m-0'>
                  {t('createNewLocation.stepperMenu.step1Description')}
                </p>
              </div>
            </div>
            <div className='bg-gray-300 d-flex p-4    '>
              <h3
                className={clsx(
                  stepper > 0
                    ? 'bg-gray-600 text-white'
                    : 'bg-white text-black',
                  'border border-2 border-gray-600 w-40px h-40px rounded  p-4  d-flex justify-content-center align-items-center me-3 '
                )}>
                2
              </h3>
              <div className='d-flex flex-column justify-content-center'>
                <h1 className='text-black fs-3 fw-bold m-0 '>
                  {t('createNewLocation.stepperMenu.step1Title')}
                </h1>
                <p className='text-gray-600 m-0'>
                  {t('createNewLocation.stepperMenu.step2Description')}
                </p>
              </div>
            </div>
            <div className='bg-gray-300 d-flex p-4    '>
              <h3
                className={clsx(
                  stepper > 1
                    ? 'bg-gray-600 text-white'
                    : 'bg-white text-black',
                  'border border-2 border-gray-600 w-40px h-40px rounded  p-4  d-flex justify-content-center align-items-center me-3 '
                )}>
                3
              </h3>
              <div className='d-flex flex-column justify-content-center'>
                <h1 className='text-black fs-3 fw-bold m-0 '>
                  {t('createNewLocation.stepperMenu.step3Title')}
                </h1>
                <p className='text-gray-600 m-0'>
                  {t('createNewLocation.stepperMenu.step3Description')}
                </p>
              </div>
            </div>
            <div className='bg-gray-300 d-flex p-4    '>
              <h3
                className={clsx(
                  stepper > 2
                    ? 'bg-gray-600 text-white'
                    : 'bg-white text-black',
                  'border border-2 border-gray-600 w-40px h-40px rounded  p-4  d-flex justify-content-center align-items-center me-3 '
                )}>
                4
              </h3>
              <div className='d-flex flex-column justify-content-center'>
                <h1 className='text-black fs-3 fw-bold m-0 '>
                  {t('createNewLocation.stepperMenu.step4Title')}
                </h1>
                <p className='text-gray-600 m-0'>
                  {t('createNewLocation.stepperMenu.step4Description')}
                </p>
              </div>
            </div>
          </div>

          <div className='w-100 w-md-75 d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              {stepper === 0 && <DetailsView />}
              {stepper === 1 && <StationsView />}
              {stepper === 2 && <SectionsView />}
              {stepper === 3 && <SummaryView />}
            </div>

            <div className='d-flex justify-content-end mt-8'>
              {stepper > 0 && (
                <button
                  onClick={() => setStepper(stepper - 1)}
                  className='btn btn-outline-primary text-decoration-underline me-2'>
                  {t('createNewLocation.backBtn')}
                </button>
              )}
              {stepper < 3 ? (
                <button
                  onClick={() => setStepper(stepper + 1)}
                  className='btn btn-primary'>
                  {t('createNewLocation.continueBtn')}
                </button>
              ) : (
                <button className='btn btn-primary'>
                  {' '}
                  {t('createNewLocation.submitBtn')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewLocation;
