import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './base-query';

export const defaultBaseURL = process.env.REACT_APP_API_URL;

export const api = createApi({
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    createAuthenticationToken: build.mutation<
      CreateAuthenticationTokenApiResponse,
      CreateAuthenticationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/login`,
        method: 'POST',
        body: queryArg.loginRequest,
      }),
    }),
    getProfile: build.query<GetProfileApiResponse, GetProfileApiArg>({
      query: () => ({ url: `/user/profile` }),
    }),
    find1: build.query<Find1ApiResponse, Find1ApiArg>({
      query: (queryArg) => ({
        url: `/charge-station`,
        params: { rfid_enabled: queryArg.rfidEnabled },
      }),
    }),
  }),
});

export type CreateAuthenticationTokenApiResponse =
  /** status 200 Success */ LoginResponse;
export type CreateAuthenticationTokenApiArg = {
  loginRequest: LoginRequest;
};
export type GetProfileApiResponse = /** status 200 OK */ UserProfileResponse;
export type GetProfileApiArg = void;

export type LoginResponse = {
  token?: string;
};
export type LoginRequest = {
  email: string;
  password: string;
};

export type Find1ApiResponse = /** status 200 OK */ ChargeStationItem[];
export type Find1ApiArg = {
  rfidEnabled?: boolean;
};

export type ChargeStationItem = {
  id: string;
  name?: string;
  totalConnectors?: number;
  availableConnectors?: number;
  priceFrom?: number;
  rfidEnabled?: boolean;
  plugTypeNames?: string[];
};
export type UserProfileResponse = {
  name?: string;
  countryId?: number;
  city?: string;
  street?: string;
  zipCode?: string;
  phone?: string;
  email?: string;
  invoiceEmail?: string;
  currencyCode?: string;
  locale?: string;
  owner?: boolean;
  complete?: boolean;
};

export const { useGetProfileQuery } = api;
