import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { RootState } from './Store';
import 'cross-fetch/polyfill';

interface FetchBaseQueryError {
  status: number;
  erorCode: number;
  message: string;
  success: false;
}

const defaultBaseURL = process.env.REACT_APP_API_URL;

export const customBaseQuery = fetchBaseQuery({
  baseUrl: defaultBaseURL,
  // fetchFn: (...args) => {
  //   console.log(args);
  //   return fetch(...args);
  // },
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).bearerToken;
    if (token && token.token) {
      headers.set('Authorization', `Bearer ${token.token}`);
    }
    return headers;
  },
}) as unknown as BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>;
