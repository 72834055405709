//!temporary till real data

export const modules = [
  {
    id: '1',
    name: 'My wallet',
    tags: [
      { name: 'Popular', id: '1' },
      { name: 'Public', id: '2' },
    ],
    description:
      'Manage wallets, credit balances and payment methods or explore history of your payments and...',
    buttons: ['Detail', 'Active'],
    link: false,
  },
  {
    id: '2',
    name: 'Public charging',
    tags: [
      { name: 'Popular', id: '1' },
      { name: 'Private', id: '3' },
    ],
    description:
      'Activate your access to all public chargers in your area or country, connect with your wallet and...',
    buttons: ['Learn more', 'Install'],
    link: false,
  },
  {
    id: '3',
    name: 'Home charging',
    tags: [{ name: 'Public', id: '2' }],
    description:
      'Easilily connect and sync your home charger, home infrastructure or view your statistics...',
    buttons: ['Learn more', 'Install'],
    link: false,
  },
  {
    id: '4',
    name: 'Charge point management',
    tags: [{ name: 'Private', id: '3' }],
    description:
      'Manage your locations, sections and stations, manage settings or energy managent system...',
    buttons: ['Detail', 'Active'],
    link: true,
  },
];
