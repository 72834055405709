import {
  faAngleDown,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChargedEnergyView from './lineTabs/chargedEnergy/ChargedEnergyView';
import EarningsView from './lineTabs/earnings/EarningsView';

const SummaryView = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show, setShow] = useState(false);
  const [navMenu, setNavMenu] = useState('chargedEnergy');
  const { t } = useTranslation();

  const handleClick = (value: string) => {
    setNavMenu(value);
  };
  return (
    <>
      <div className='d-flex flex-column   flex-md-row justify-content-md-between '>
        <div className='d-flex'>
          <div className='d-flex flex-column'>
            <button
              onClick={() => setShow(true)}
              className='btn border border-primary text-primary bg-hover-primary text-hover-white  bg-white mx-auto py-2 mb-2  text-primary'>
              {t('reports.summary.filterBtn')}
            </button>
            <button
              onClick={() => setShow(false)}
              className='btn btn-outline-primary text-decoration-underline'>
              {t('reports.summary.resetBtn')}
            </button>
          </div>
          <div className='ms-4'>
            <h3 className='text-gray-700 fs-5 '>
              {t('reports.summary.appliedFilters')}
            </h3>
            {/** HERE COMES CODE FOR APPLIED FILTERS */}
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-center '>
          <button className='btn btn-primary'>
            {t('reports.summary.exportBtn')}{' '}
            <FontAwesomeIcon icon={faAngleDown} className='ms-2' />
          </button>
          <button className='btn bg-gray-400 ms-4'>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        </div>
      </div>
      <nav className='mt-4 mt-md-0'>
        <ul className='d-flex list-unstyled'>
          <li
            style={{ cursor: 'pointer' }}
            className={clsx(
              'fs-3 text-hover-primary mx-2 pb-3',
              navMenu === 'chargedEnergy'
                ? 'border-bottom border-5 border-primary text-primary'
                : ''
            )}
            onClick={() => handleClick('chargedEnergy')}>
            {t('reports.summary.chargedEnergy.title')}
          </li>
          <li
            style={{ cursor: 'pointer' }}
            className={clsx(
              'fs-3 text-hover-primary mx-2 pb-3',
              navMenu === 'earnings'
                ? 'border-bottom border-5 border-primary text-primary'
                : ''
            )}
            onClick={() => handleClick('earnings')}>
            {t('reports.summary.earnings.title')}
          </li>
        </ul>
      </nav>

      <div className='bg-gray-500 p-4'>
        {navMenu === 'chargedEnergy' && <ChargedEnergyView />}
        {navMenu === 'earnings' && <EarningsView />}
      </div>
    </>
  );
};

export default SummaryView;
