import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PaginateComponent from '../../../../components/pagination/PaginateComponent';
import DisplayGroups from './components/DisplayGroups';

interface IProps {
  copyData: any[];
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
}

const GroupView = ({ copyData, setSearchBy }: IProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const contactsPerPage = 10;

  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(copyData.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const handleCreateNewGroup = () => {
    navigate('/managment/contactgroups/createcontactgroup');
  };
  return (
    <>
      <div className='mb-10'>
        <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
          <li className='breadcrumb-item pe-3'>
            <Link to='/' className='pe-3'>
              {t('breadcrumbs.management')}
            </Link>
          </li>
        </ol>
        <h1 className='fw-light'>{t('contactGroups.name')}</h1>
        <p className='fw-light'>{t('contactGroups.infoText')}</p>
      </div>

      <div className='bg-gray-300 p-8'>
        <div className='d-flex flex-column flex-md-row justify-content-between  '>
          <Form>
            <Form.Control
              className='w-250px mb-4 mb-md-0 '
              type='text'
              placeholder='Search'
              defaultValue=''
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </Form>
          <button
            onClick={handleCreateNewGroup}
            className='btn btn-primary w-50  w-md-auto '>
            <FontAwesomeIcon icon={faPlus} className='me-2' />{' '}
            {t('contactGroups.newGourpBtn')}
          </button>
        </div>
        {/* <p>Showing {copyData.length} contacts</p> */}

        <DisplayGroups
          copyData={copyData}
          pagesVisited={pagesVisited}
          contactsPerPage={contactsPerPage}
        />

        <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
      </div>
    </>
  );
};

export default GroupView;
