import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
interface IProps {
  data: any[];
  filename: string;
  title: string;
  className: string;
  icon?: IconDefinition;
}
const CsvExport = ({ data, filename, title, className, icon }: IProps) => {
  return (
    <CSVLink data={data} filename={filename} className={className}>
      {title} {icon ? <FontAwesomeIcon icon={icon} /> : null}
    </CSVLink>
  );
};

export default CsvExport;
