import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './PaginateComponent.module.scss';
interface IProps {
  pageCount: number;
  pageChange: ({ selected }: any) => void;
}

const PaginateComponent = ({ pageCount, pageChange }: IProps) => {
  return (
    <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      pageCount={pageCount}
      onPageChange={pageChange}
      containerClassName={styles.paginationBttns}
      previousLinkClassName={styles.previousBttn}
      nextLinkClassName={styles.nextBttn}
      disabledClassName={styles.paginationDisabled}
      activeClassName={styles.paginationActive}
    />
  );
};

export default PaginateComponent;
