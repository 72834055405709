import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PaginateComponent from '../../../../components/pagination/PaginateComponent';

interface IProps {
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
  modules: {
    id: string;
    name: string;
    buttons: any[];
    tags: {
      name: string;
      id: string;
    }[];
    description: string;
  }[];
}

const PublicModules = ({ setSearchBy, modules }: IProps) => {
  // todo prepared for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const contactsPerPage = 10;
  const pagesVisited = pageNumber * contactsPerPage;

  const pageCount = Math.ceil(modules.length / contactsPerPage);
  const pageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const result = modules.filter((obj) => {
    const tags = obj.tags;
    const filteredTags = tags.filter((tag) => {
      return tag.name === 'Public';
    });
    return filteredTags.length !== 0;
  });

  return (
    <>
      <div className='d-flex  flex-wrap justify-content-between py-4'>
        <Form className='w-50'>
          <Form.Control
            type='text'
            placeholder='Search'
            defaultValue=''
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </Form>
      </div>
      {result
        .slice(pagesVisited, pagesVisited + contactsPerPage)
        .map((module) => (
          <div
            key={module.id}
            className='d-flex flex-column flex-md-row justify-content-between p-4 m-4 bg-gray-500'>
            <div className='d-flex flex-column flex-md-row w-75 me-4'>
              <div>
                <h1>{module.name}</h1>
                <p>{module.description}</p>
                <div className='d-flex'>
                  {module.tags.map((tag) => (
                    <p
                      key={tag.id}
                      className='bg-gray-600 me-4 px-3 text-white rounded rounded-3'>
                      {tag.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className='w-50 w-md-25 d-flex flex-column justify-content-end align-items-center '>
              <button className='btn border border-primary text-primary bg-hover-primary text-hover-white text-primary w-100 w-md-50  bg-white  mb-4 mb-xl-2 fs-8'>
                {module.buttons[0]}
              </button>
              <button className='btn btn-primary w-100 w-md-50 fs-8 mb-4 mb-xl-0 w-50'>
                {module.buttons[1]}
              </button>
            </div>
          </div>
        ))}

      <PaginateComponent pageCount={pageCount} pageChange={pageChange} />
    </>
  );
};

export default PublicModules;
