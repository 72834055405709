import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC } from 'react';
import { useGetProfileQuery } from '../../../../setup/redux/api';
import { HeaderUserMenu } from '../../../partials';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
  const { data } = useGetProfileQuery();

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'>
          {/* <button className='btn rounded-pill btn-primary'>
            <FontAwesomeIcon
              icon={faAngleUp}
              className=' rotate'
              onClick={(e) => e.currentTarget.classList.toggle('active')}
            />
          </button> */}
          <button
            type='button'
            className='btn btn-primary d-flex flex-column align-items-center justify-content-center  rotate '
            onClick={(e) => e.currentTarget.classList.toggle('active')}>
            <p className=' d-flex bg-secondary justify-content-center align-items-center rounded-circle w-25px h-25px mt-2 text-primary'>
              {data?.email?.charAt(0).toLocaleUpperCase()}
            </p>

            <FontAwesomeIcon
              icon={faAngleDown}
              className='svg-icon-2 rotate-180 '
            />
          </button>
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' /> */}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  );
};

export { Topbar };
