import { faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMasterStationData } from '../../../../../../models/interface';
import { masterStationsData } from '../../../../../../models/mockData/energyManagementMockData';

interface IProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  // setNewStation: React.Dispatch<React.SetStateAction<any | null>>;
  // newStation: any | null;
}

const CreateNewSection = ({ setShowModal, showModal }: IProps) => {
  const [navMenu, setNavMenu] = useState('details');

  const { t } = useTranslation();

  const handleClick = (value: string) => {
    setNavMenu(value);
  };

  return (
    <Modal
      centered
      size='xl'
      show={showModal}
      onHide={() => setShowModal(false)}>
      <div className='m-4 p-4 h-75 overflow-scroll'>
        <div className='d-flex justify-content-between'>
          <h2 className='mb-10 fw-bold'>
            {t(
              'createNewLocation.stepperBody.sectionView.newSectionModal.title'
            )}
          </h2>
          <FontAwesomeIcon
            onClick={() => setShowModal(false)}
            style={{ cursor: 'pointer' }}
            icon={faClose}
            size='2x'
            className='text-gray-600'
          />
        </div>
        <nav>
          <ul className='d-flex list-unstyled'>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'details'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('details')}>
              {t(
                'createNewLocation.stepperBody.sectionView.newSectionModal.details.name'
              )}
            </li>
            <li
              style={{ cursor: 'pointer' }}
              className={clsx(
                'fs-3 text-hover-primary mx-2 pb-3',
                navMenu === 'stations'
                  ? 'border-bottom border-5 border-primary text-primary'
                  : ''
              )}
              onClick={() => handleClick('stations')}>
              {t(
                'createNewLocation.stepperBody.sectionView.newSectionModal.stations.name'
              )}
            </li>
          </ul>
        </nav>
        {navMenu === 'details' && (
          <>
            <div className='mb-4 p-6  bg-gray-300'>
              <h3 className='fw-normal mb-6'>
                {t(
                  'createNewLocation.stepperBody.sectionView.newSectionModal.details.name'
                )}
              </h3>
              <p>
                {t(
                  'createNewLocation.stepperBody.sectionView.newSectionModal.details.infoText'
                )}
              </p>
              <Form>
                <h3 className=' text-gray-700'>
                  {t(
                    'createNewLocation.stepperBody.sectionView.newSectionModal.details.generalDetails'
                  )}
                </h3>
                <div className='bg-light p-4'>
                  <div className='bg-white p-4 my-1 d-flex justify-content-between align-items-center'>
                    <h4>
                      {t(
                        'createNewLocation.stepperBody.sectionView.newSectionModal.details.sectionName'
                      )}
                    </h4>
                    <Form.Control
                      type='text'
                      placeholder={t(
                        'createNewLocation.stepperBody.sectionView.newSectionModal.details.sectionName'
                      )}
                      className='w-250px'
                    />
                  </div>
                  <div className='bg-white p-4 my-1 d-flex justify-content-between align-items-center'>
                    <h4>
                      {t(
                        'createNewLocation.stepperBody.sectionView.newSectionModal.details.type.title'
                      )}
                    </h4>
                    <Form.Select className='w-250px'>
                      <option value=''>
                        {t(
                          'createNewLocation.stepperBody.sectionView.newSectionModal.details.type.option1'
                        )}
                      </option>
                      <option value='1'>
                        {t(
                          'createNewLocation.stepperBody.sectionView.newSectionModal.details.type.option2'
                        )}
                      </option>
                      <option value='2'>
                        {t(
                          'createNewLocation.stepperBody.sectionView.newSectionModal.details.type.option3'
                        )}
                      </option>
                    </Form.Select>
                  </div>
                </div>
                <h3 className=' text-gray-700 my-4'>
                  {t(
                    'createNewLocation.stepperBody.sectionView.newSectionModal.details.geoDetails'
                  )}
                </h3>
                <div className='bg-light p-4 '>
                  <p>
                    {t(
                      'createNewLocation.stepperBody.sectionView.newSectionModal.details.geoInfo'
                    )}
                  </p>
                  <div className='bg-white p-4 my-1 d-flex justify-content-between align-items-center'>
                    here comes the map
                  </div>
                  <div className='d-flex justify-content-between my-4 '>
                    <div className='d-flex flex-column w-50 mx-2'>
                      <h3 className='text-gray-700'>
                        {t(
                          'createNewLocation.stepperBody.sectionView.newSectionModal.details.long'
                        )}
                      </h3>
                      <Form.Control type='text' placeholder='Longitude' />
                    </div>
                    <div className='d-flex flex-column w-50 mx-2'>
                      <h3 className='text-gray-700'>
                        {t(
                          'createNewLocation.stepperBody.sectionView.newSectionModal.details.lat'
                        )}
                      </h3>
                      <Form.Control type='text' placeholder='Latitude' />
                    </div>
                    <div className='d-flex flex-column '>
                      <h3 className='text-gray-700'>
                        {t(
                          'createNewLocation.stepperBody.sectionView.newSectionModal.details.radiusSelect.title'
                        )}
                      </h3>
                      <Form.Select className='w-200px mx-2'>
                        <option value=''>
                          {t(
                            'createNewLocation.stepperBody.sectionView.newSectionModal.details.radiusSelect.option1'
                          )}
                        </option>
                        <option value='1'>
                          {t(
                            'createNewLocation.stepperBody.sectionView.newSectionModal.details.radiusSelect.option2'
                          )}
                        </option>
                        <option value='2'>
                          {t(
                            'createNewLocation.stepperBody.sectionView.newSectionModal.details.radiusSelect.option3'
                          )}
                        </option>
                        <option value='2'>
                          {t(
                            'createNewLocation.stepperBody.sectionView.newSectionModal.details.radiusSelect.option4'
                          )}
                        </option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                onClick={() => setNavMenu('stations')}
                className='btn btn-primary'>
                {t(
                  'createNewLocation.stepperBody.sectionView.newSectionModal.continueBtn'
                )}
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </>
        )}
        {navMenu === 'stations' && (
          <div className='bg-light p-4'>
            <div className='mb-4 p-6  '>
              <h3 className='fw-normal mb-6'>Details</h3>
              <p>Add stations </p>
            </div>

            <h3 className=' text-gray-700 my-4'>SELECTED STATIONS</h3>
            <div className='bg-gray-300 p-4 h-200px'>
              <p>****TODO**** Select one or more products from list below </p>
            </div>
            <h3 className=' text-gray-700 my-4'>GEOLOGICAL DETAILS</h3>
            <div className='bg-gray-300 p-4 '>
              <Form>
                <div>
                  <h3 className=' text-gray-700 my-4'>SEARCH</h3>
                  <Form.Control
                    type='text'
                    placeholder='Search'
                    className='w-250px mb-4'
                  />
                  {masterStationsData.map((station: IMasterStationData) => (
                    <Form className='bg-light p-4 d-flex'>
                      <Form.Check className='me-2' />
                      <div>
                        <h3 className=' text-gray-700'>STATION</h3>
                        <h2>{station.name}</h2>
                      </div>
                    </Form>
                  ))}
                </div>
              </Form>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                onClick={() => setNavMenu('details')}
                className='btn btn-outline-primary text-decoration-underline me-2'>
                {t(
                  'createNewLocation.stepperBody.sectionView.newSectionModal.backBtn'
                )}
              </button>
              <button
                // onClick={handleCreateStation}
                className='btn btn-primary'>
                {t(
                  'createNewLocation.stepperBody.sectionView.newSectionModal.createBtn'
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateNewSection;
