import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  // auth: auth.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// export function* rootSaga() {
//   yield all([auth.saga()])
// }
