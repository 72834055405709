export const filterMockData = [
  {
    locationData: [
      {
        id: '1',
        name: 'Total earnings',
      },
      {
        id: '2',
        name: 'Total users',
      },
      {
        id: '3',
        name: 'Total charged energy',
      },
    ],
    tansactionData: [
      {
        id: '1',
        name: 'Total earnings',
      },
      {
        id: '2',
        name: 'Total users',
      },
      {
        id: '3',
        name: 'Total charged energy',
      },
    ],
    systemData: [
      {
        id: '1',
        name: 'Total earnings(Summary)',
      },
      {
        id: '2',
        name: 'Total users(Chart)',
      },
      {
        id: '3',
        name: 'Total charged energy(Live)',
      },
    ],
  },
];
