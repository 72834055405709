import clsx from 'clsx';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EditDetailsPermission from './components/EditDetailsPermission';
import EditMembersPermission from './components/EditMembersPermission';
import {
  masterGroupsData,
  masterPermissionsData,
  masterUsersData,
} from '../../../../models/mockData/mockData';
import { IMasterGroupsData } from '../../../../models/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const EditPermission = () => {
  const [show, setShow] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [navMenu, setNavMenu] = useState('about');
  const navigate = useNavigate();
  const { id } = useParams();

  const { t } = useTranslation();
  const handleCancelClick = () => {
    // ! temporary solution
    navigate('/managment/permissions');
  };
  const result = masterUsersData.filter((obj) => {
    const permission = obj.permission;
    const filteredPermissions = permission.filter((permission) => {
      return permission.id === id;
    });
    return filteredPermissions.length !== 0;
  });

  const filteredData = masterPermissionsData.filter((data) => data.id === id);
  const initialValues = filteredData[0];
  const [permissionName, setPermissionName] = useState(initialValues.name);

  const handleNavigationValue = (value: string) => {
    setNavMenu(value);
  };

  const activegroup: IMasterGroupsData[] = masterGroupsData
    ?.filter((obj) => obj.permission.length !== 0)
    .filter((obj) => obj.id === initialValues.id);
  const handleOpenErrorModal = (): void => {
    setShowModalWarning(false);
    setShowModalError(true);
  };
  return (
    <>
      <div>
        <div className='mb-8'>
          <ol className='breadcrumb text-muted fs-6 fw-bold mb-4'>
            <li className='breadcrumb-item pe-3'>
              <Link to='/dashboard' className='pe-3'>
                {t('breadcrumbs.management')}
              </Link>
            </li>
            <li className='breadcrumb-item pe-3'>
              <Link to='/managment/permissions' className='pe-3'>
                {t('breadcrumbs.permissions')}
              </Link>
            </li>
          </ol>
          <div className='d-flex justify-content-between'>
            <h2 className='fw-light'>
              {t('permission.editPermission.editPermissionName')} '
              {initialValues.name}'
            </h2>
            {/* <button
              onClick={() => setShow(true)}
              className='btn border border-primary text-primary bg-hover-primary text-hover-white text-primary  bg-white me-4 fs-8'>
              Edit permission
            </button> */}
          </div>
        </div>

        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <div className='d-flex flex-column  me-4'>
            <div className='bg-gray-300 d-flex flex-column  p-4 mb-4   '>
              <h3 className='fw-normal'>
                {t('permission.editPermission.permissionName')}
              </h3>
              <Form>
                <Form.Control
                  type='text'
                  defaultValue={permissionName}
                  onChange={(e) => setPermissionName(e.target.value)}
                />
              </Form>
            </div>
            <div>
              <div>
                <div className='bg-gray-300 d-flex flex-column  p-4  '>
                  <h3 className='text-gray-700 fs-5 my-2'>
                    {t('permission.editPermission.module')}
                  </h3>
                  <h2>{initialValues.module}</h2>
                </div>
                <div className='bg-gray-300 d-flex flex-column  p-4   '>
                  <h3 className='text-gray-700 fs-5 my-2'>
                    {t('permission.editPermission.module')}
                  </h3>
                  <h2>{initialValues.type}</h2>
                </div>
                <div className='bg-gray-300 d-flex flex-column  p-4   '>
                  <h3 className='text-gray-700 fs-5 my-2'>
                    {t('permission.editPermission.asssignedTo')}
                  </h3>
                  {activegroup.map((obj) => {
                    return (
                      <div key={obj.id} className='bg-gray-200 px-4 py-2 my-2'>
                        <h3 className='text-gray-700 fs-9 my-2'>
                          {t('permission.editPermission.group')}
                        </h3>
                        <h2>{obj.name}</h2>
                        <h3 className='fw-light fs-9'>
                          {result.length}{' '}
                          {t('permission.editPermission.usersInGrp')}
                        </h3>
                      </div>
                    );
                  })}
                </div>
                <div className='bg-gray-300 d-flex flex-column  p-4   '>
                  <button
                    onClick={() => setShowModalWarning(true)}
                    className='btn border border-primary text-primary bg-hover-primary text-hover-white text-primary  bg-white me-4 fs-8'>
                    {t('permission.editPermission.unassignBtn')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='w-100 w-md-75'>
            <div className='d-flex justify-content-between'>
              <div>
                <nav>
                  <ul className='d-flex list-unstyled'>
                    <li
                      style={{ cursor: 'pointer' }}
                      className={clsx(
                        'fs-3 text-hover-primary mx-2 pb-3',
                        navMenu === 'about'
                          ? 'border-bottom border-5 border-info text-primary'
                          : ''
                      )}
                      onClick={() => handleNavigationValue('about')}>
                      {t('permission.editPermission.details.title')}
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      className={clsx(
                        'fs-3 text-hover-primary mx-2 pb-3',
                        navMenu === 'permision'
                          ? 'border-bottom border-5 border-primary text-primary'
                          : ''
                      )}
                      onClick={() => handleNavigationValue('permision')}>
                      {t('permission.editPermission.members.title')}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className='bg-gray-300 p-4'>
              <div className='d-flex justify-content-between'></div>
              {navMenu === 'about' && <EditDetailsPermission />}
              {navMenu === 'permision' && (
                <EditMembersPermission
                  initialValues={initialValues}
                  setShow={setShow}
                  show={show}
                />
              )}
            </div>
            <div className='d-flex justify-content-between mt-8'>
              <div>
                <button
                  // todo Warning že nemúžeš vymazať permission s assignutym userom/groupov. Vyzva vymažte grupu first
                  onClick={handleCancelClick}
                  className='btn btn-outline-primary border border-2 border-primary me-4 '>
                  {t('permission.editPermission.deleteBtn')}
                </button>
              </div>
              <div className='d-flex justify-content-end'>
                <button
                  onClick={handleCancelClick}
                  className='btn text-primary text-decoration-underline me-2'>
                  {t('permission.editPermission.cancelBtn')}
                </button>

                <button
                  onClick={handleCancelClick}
                  type='submit'
                  className='btn btn-primary'>
                  {t('permission.editPermission.saveBtn')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalError}
        centered
        onHide={() => setShowModalError(false)}>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-between'>
            <div className='d-flex justify-content-between'>
              <h1>
                {t('permission.editPermission.unassignComfirmModal.title')}
              </h1>
              <FontAwesomeIcon
                icon={faXmark}
                className='text-gray-700'
                size='2x'
                style={{ cursor: 'pointer' }}
                onClick={() => setShowModalError(false)}
              />
            </div>
            <div style={{ background: '#FFF4CF' }} className='p-4 my-4'>
              <h3>
                {t('permission.editPermission.unassignComfirmModal.header')}
              </h3>
              <p>
                <span className='text-primary text-decoration-underline'>
                  {result.length}{' '}
                  {t('permission.editPermission.unassignComfirmModal.members')}
                </span>
                {t('permission.editPermission.unassignComfirmModal.infoText')}
              </p>

              <div className='d-flex '>
                <Link
                  className='mt-2 text-primary text-decoration-underline fs-6 me-6'
                  to='/managment/permissions'>
                  {t(
                    'permission.editPermission.unassignComfirmModal.permission'
                  )}
                  <FontAwesomeIcon className='ms-1' icon={faShareFromSquare} />
                </Link>
                <Link
                  className='mt-2 text-primary text-decoration-underline fs-6'
                  to='/managment/contactlist'>
                  {t(
                    'permission.editPermission.unassignComfirmModal.contactList'
                  )}
                  <FontAwesomeIcon className='ms-1' icon={faShareFromSquare} />
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalWarning}
        centered
        onHide={() => setShowModalWarning(false)}>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-between'>
            <h1>{t('permission.editPermission.unassignModal.title')}</h1>

            <div style={{ background: '#FFF4CF' }} className='p-4 my-4'>
              <h3>{t('permission.editPermission.unassignModal.header')}</h3>
              <p>
                <span className='text-primary text-decoration-underline'>
                  {result.length}{' '}
                  {t('permission.editPermission.unassignModal.members')}
                </span>
                {t('permission.editPermission.unassignModal.infoText')}
              </p>
            </div>
            <div className='d-flex justify-content-end my-4'>
              <button
                onClick={() => setShowModalWarning(false)}
                className='btn btn-primary me-2'>
                {t('permission.editPermission.cancelBtn')}
              </button>
              <button
                onClick={handleOpenErrorModal}
                className='btn btn-outline-primary border border-2 border-primary'>
                {t('permission.editPermission.unassignModal.unassignAll')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPermission;
